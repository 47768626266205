import React, { useEffect, useState } from 'react';

import './EquipmentQuickList.scss';
import { EquipmentEntity, equipmentControllerSearchEquipment } from '../../clients/VgarageApi';
import { useAccessToken } from '../../hooks/useAccessToken';
import { useFetch } from '../../hooks/useFetch';
import { MessageDialogModal } from '../Modal/MessageDialogModal';
import { EquipmentForm, onCloseEquipmentFormOptions } from '../forms/EquipmentForm';
import { FormModal } from '../Modal/FormModal';

export interface EquipmentQuickListItem {
  value: number;
  text: string;
}

export interface EquipmentQuickListProps {
  carId: number;
  defaultValues?: number[] | undefined;
  onChange: (value: number[]) => void;
}

export const EquipmentQuickList = ({
  carId,
  defaultValues = undefined,
  onChange,
}: EquipmentQuickListProps) => {
  console.log('df', defaultValues);

  const { accessToken } = useAccessToken();

  const [fetchEquipments, isFetchingEquipments, equipmentsError, equipments] = useFetch<
    EquipmentEntity[] | null
  >(async () => {
    if (accessToken) {
      const result = await equipmentControllerSearchEquipment({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const equipments = result.data;
      return equipments ? equipments.sort((a, b) => b.id - a.id) : null;
    }

    return null;
  });

  useEffect(() => {
    fetchEquipments();
  }, [accessToken]);

  const getEquipmentById = (equipmentId: number): EquipmentEntity | undefined => {
    const equipment = equipments?.filter((equipment) => equipment.id === equipmentId);

    if (equipment && equipment[0]) {
      return equipment[0];
    } else {
      return undefined;
    }
  };

  // ---

  const [equipmentIdsInList, setEquipmentIdsInList] = useState<number[]>(defaultValues || []);

  useEffect(() => {
    onChange(equipmentIdsInList);
  }, [equipmentIdsInList]);

  const deleteEquipmentFromList = (equipment: EquipmentEntity) => {
    const filteredEquipmentIds = equipmentIdsInList.filter((id) => id !== equipment.id);
    setEquipmentIdsInList(filteredEquipmentIds);
  };

  const addEquipmentToList = (equipment: EquipmentEntity) => {
    const filteredEquipmentIds = equipmentIdsInList.filter((id) => id !== equipment.id);
    setEquipmentIdsInList([...filteredEquipmentIds, equipment.id]);
  };

  // ---

  const [equipmentForUpdate, setEquipmentForUpdate] = useState<EquipmentEntity | null>(null);
  const [equipmentFormModalIsShowing, setEquipmentFormModalIsShowing] = useState(false);

  const onCloseEquipmentForm = (options?: onCloseEquipmentFormOptions) => {
    setEquipmentForUpdate(null);
    setEquipmentFormModalIsShowing(false);
    fetchEquipments();

    if (options?.equipment) {
      addEquipmentToList(options?.equipment);
    }

    if (options?.text) {
      setMessageDialogModalText(options.text);
      setMessageDialogModalIsShowing(true);
    }
  };

  // ---

  const [messageDialogModalIsShowing, setMessageDialogModalIsShowing] = useState(false);
  const [messageDialogModalText, setMessageDialogModalText] = useState('');

  return (
    <>
      <div className="list">
        {equipmentIdsInList?.map((id) => {
          const equipment = getEquipmentById(id);
          if (equipment)
            return (
              <div key={id} className="list-item">
                <div className="list-item-element">{equipment?.name}</div>

                <button
                  onClick={() => {
                    setEquipmentForUpdate(equipment);
                    setEquipmentFormModalIsShowing(true);
                  }}
                  className="list-button"
                  type="button"
                >
                  <svg
                    className="list-button-icon-edit"
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    id="Ebene_1"
                    x="0px"
                    y="0px"
                    width="64px"
                    height="64px"
                    viewBox="0 0 64 64"
                  >
                    <path d="M3.161 63.357c.471 0 .968-.115 1.479-.342l14.346-6.376c1.234-.549 2.887-1.684 3.843-2.64L62 14.829c.754-.754 1.17-1.759 1.17-2.829S62.754 9.925 62 9.172l-7.172-7.173C54.074 1.246 53.07.831 52 .831S49.926 1.246 49.172 2L9 42.171c-.968.967-2.09 2.651-2.612 3.917L.912 59.389c-.594 1.444-.174 2.42.129 2.873.466.696 1.239 1.095 2.12 1.095zM20 51.171s0 .001 0 0L12.828 44 46 10.828 53.172 18 20 51.171zM52 4.828L59.172 12 56 15.172 48.828 8 52 4.828zM10.088 47.611c.059-.142.138-.303.226-.469l6.213 6.213-10.776 4.788 4.337-10.532z"></path>
                  </svg>
                </button>

                <button
                  onClick={() => deleteEquipmentFromList(equipment)}
                  className="list-button"
                  type="button"
                >
                  <svg
                    className="list-button-icon-delete"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
                  </svg>
                </button>
              </div>
            );
        })}

        <button
          onClick={() => setEquipmentFormModalIsShowing(true)}
          className="list-button list-button--add"
          type="button"
        >
          + добавить
        </button>
      </div>

      <FormModal
        isShowing={equipmentFormModalIsShowing}
        onClose={() => {
          setEquipmentForUpdate(null);
          setEquipmentFormModalIsShowing(false);
        }}
      >
        <EquipmentForm
          defaultValues={{ carId }}
          equipment={equipmentForUpdate}
          onClose={onCloseEquipmentForm}
          isShowingCreateRemind={false}
          isShowingUpdateMileage={false}
          closeButtonText={
            <>
              Вернуться к
              <br />
              ТО/Сервису
            </>
          }
        />
      </FormModal>

      <MessageDialogModal
        isShowing={messageDialogModalIsShowing}
        text={messageDialogModalText}
        onClose={() => {
          setMessageDialogModalText('');
          setMessageDialogModalIsShowing(false);
          fetchEquipments();
        }}
      />
    </>
  );
};
