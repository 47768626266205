import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import React, { useState } from 'react';
import { UserResetPasswordGetHashSchema } from './schemas/UserResetPasswordGetHashSchema';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { ResetPasswordDto, userControllerResetPassword } from '../../clients/VgarageApi';

export const UserResetPasswordGetHashForm = () => {
  const [formHintMessage, setFormHintMessage] = useState('');
  const [formHintClass, setFormHintClass] = useState('');

  const formResolver = classValidatorResolver(UserResetPasswordGetHashSchema);
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<UserResetPasswordGetHashSchema>({
    resolver: formResolver,
  });

  const onValid: SubmitHandler<UserResetPasswordGetHashSchema> = async (data) => {
    console.log(data);

    setFormHintMessage('');

    const result = await userControllerResetPassword({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      body: data as ResetPasswordDto,
    });

    console.log(result);

    if (result.response.status === 201) {
      setFormHintClass('form__hint--green');
      setFormHintMessage('Ссылка для восстановления пароля отправлена на указанный email');
    } else {
      setFormHintClass('form__hint--red');
      setFormHintMessage(`Не удалось найти указанный email`);
    }
  };

  const onInvalid: SubmitErrorHandler<UserResetPasswordGetHashSchema> = (error) => {
    console.log(error);

    setFormHintClass('form__hint--red');
  };

  return (
    <form className="form" onSubmit={handleSubmit(onValid, onInvalid)}>
      <div className="form__item form__item--wide">
        <div className="form__item-label form__item-label--wide">
          <label>Электронная почта:</label>
        </div>
        <div className="form__item-element form__item-element--wide form__item-element--spark">
          <input {...register('email')} />
        </div>
      </div>

      <div className="form__item form__item--wide">
        <div className="form__item-label form__item-label--wide"></div>
        <div className="form__item-element form__item-element--wide">
          <button type="submit" className="form__button button button--fit button--primary">
            Восстановить пароль
          </button>
        </div>
      </div>

      <div className={`form__hint ${formHintClass}`}>
        {formHintMessage}
        {Object.entries(formErrors).map(([field, { message }]) => (
          <p key={field}>{message}</p>
        ))}
      </div>
    </form>
  );
};
