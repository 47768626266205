import {
  IsEmail,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPhoneNumber,
  Max,
  Min,
  MinLength,
  IsBoolean,
  Equals,
} from 'class-validator';

export class RegistrationFormSchema {
  @IsNotEmpty()
  @MinLength(1, { message: 'Необходимо указать имя для регистрации' })
  name: string;

  @IsEmail({}, { message: 'Необходимо указать email для регистрации' })
  @MinLength(6, { message: 'Необходимо указать email для регистрации' })
  email: string;

  @IsOptional()
  @IsPhoneNumber('RU', {
    message: 'Номер телефона должен быть в формате +79234445566',
  })
  phone: string | null;

  @IsNotEmpty()
  @MinLength(3, { message: 'Необходимо указать пароль для регистрации' })
  password: string;

  @IsOptional()
  @IsNumber()
  @Max(2147483647)
  @Min(0)
  cityId: number;

  @IsBoolean({
    message: 'Необходимо принять условия политики конфиденциальности для регистрации',
  })
  @Equals(true, {
    message: 'Необходимо принять условия политики конфиденциальности для регистрации',
  })
  agreeTerms: boolean;
}
