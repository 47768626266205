import React from 'react';
import { useEffect, useState } from 'react';
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { CarFormSchema } from './schemas/CarFormSchema';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useAccessToken } from '../../hooks/useAccessToken';
import { useFetch } from '../../hooks/useFetch';
import { Select, SelectOption } from '../Select/Select';
import { cleanObjectByValues } from '../../utils/cleanObject';
import { QuestionDialogModal, useQuestionDialog } from '../Modal/QuestionDialogModal';
import { useNavigate } from 'react-router-dom';
import {
  CarBodyTypeEntity,
  CarBrandEntity,
  CarDriveTypeEntity,
  CarEntity,
  CarFuelTypeEntity,
  CarGenerationEntity,
  CarModelEntity,
  CarTransmissionTypeEntity,
  CreateCarDto,
  UpdateCarDto,
  UserEntity,
  bodyTypeControllerReadBodyTypes,
  carControllerCreateCar,
  carControllerDeleteCar,
  carControllerUpdateCar,
  carTypeControllerReadBrands,
  carTypeControllerReadGenerationFromModel,
  carTypeControllerReadModelsFromBrand,
  driveTypeControllerReadDriveTypes,
  fuelTypeControllerReadFuelTypes,
  transmissionTypeControllerReadTransmissionTypes,
  userControllerGetCurrentUser,
  userControllerUpdateUser,
} from '../../clients/VgarageApi';

export interface onCloseCarFormOptions {
  car?: CarEntity;
  text?: string;
}

interface CarFormProps {
  car?: CarEntity | undefined;
  defaultValues?: Partial<CarFormSchema> | undefined;
  closeButtonText?: React.ReactNode | undefined;
  onClose: (options?: onCloseCarFormOptions) => void;
}

export const CarForm = ({ car, defaultValues, closeButtonText, onClose }: CarFormProps) => {
  const navigate = useNavigate();

  const { accessToken } = useAccessToken();

  const [fetchUser, isFetchingUser, userError, user] = useFetch<UserEntity | null>(async () => {
    const response = await userControllerGetCurrentUser({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data || null;
  });

  const [fetchBrands, isFetchingBrands, brandsError, brands] = useFetch<CarBrandEntity[] | null>(
    async () => {
      const response = await carTypeControllerReadBrands({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response.data || null;
    },
  );

  const [fetchModels, isFetchingModels, modelsError, models] = useFetch<CarModelEntity[] | null>(
    async (brandId: number) => {
      if (brandId) {
        const response = await carTypeControllerReadModelsFromBrand({
          baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          path: {
            id: brandId,
          },
        });

        const brand = response.data;
        const models = brand?.models;

        return models || null;
      } else {
        return null;
      }
    },
  );

  const [fetchGenerations, isFetchingGenerations, generationsError, generations] = useFetch<
    CarGenerationEntity[] | null
  >(async (modelId: number) => {
    if (modelId) {
      const response = await carTypeControllerReadGenerationFromModel({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        path: {
          id: modelId,
        },
      });

      const model = response.data;
      const generations = model?.generations;

      return generations || null;
    } else {
      return null;
    }
  });

  const [fetchFuelTypes, isFetchingFuelTypes, fuelTypesError, fuelTypes] = useFetch<
    CarFuelTypeEntity[] | undefined
  >(async () => {
    const response = await fuelTypeControllerReadFuelTypes({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  });

  const [
    fetchTransmissionTypes,
    isFetchingTransmissionTypes,
    transmissionTypesError,
    transmissionTypes,
  ] = useFetch<CarTransmissionTypeEntity[] | undefined>(async () => {
    const response = await transmissionTypeControllerReadTransmissionTypes({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  });

  const [fetchDriveTypes, isFetchingDriveTypes, driveTypesError, driveTypes] = useFetch<
    CarDriveTypeEntity[] | undefined
  >(async () => {
    const response = await driveTypeControllerReadDriveTypes({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  });

  const [fetchBodyTypes, isFetchingBodyTypes, bodyTypesError, bodyTypes] = useFetch<
    CarBodyTypeEntity[] | undefined
  >(async () => {
    const response = await bodyTypeControllerReadBodyTypes({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  });

  useEffect(() => {
    fetchUser();
    fetchBrands();
    fetchFuelTypes();
    fetchTransmissionTypes();
    fetchDriveTypes();
    fetchBodyTypes();
  }, [accessToken]);

  useEffect(() => {
    resetField('isMainCar', {
      defaultValue: user?.mainCarId === car?.id || false,
    });
  }, [car, user]);

  const [formHintMessage, setFormHintMessage] = useState('');
  const [formHintClass, setFormHintClass] = useState('');

  const formResolver = classValidatorResolver(CarFormSchema);

  const {
    setValue,
    getValues,
    watch,
    control,
    register,
    resetField,
    handleSubmit,
    formState: { errors: formErrors, isDirty: formIsDirty, dirtyFields },
  } = useForm<CarFormSchema>({
    resolver: formResolver,
    defaultValues: car
      ? {
          ...car,
          brandId: car.brandId || undefined,
          isMainCar: user?.mainCarId === car.id || false,
          ...(defaultValues || {}),
        }
      : defaultValues,
  });

  const onValid: SubmitHandler<CarFormSchema> = async (data) => {
    console.log(data);
    const cleanedData = cleanObjectByValues(data, [undefined]);

    if (car === undefined) {
      const result = await carControllerCreateCar({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: cleanedData as CreateCarDto,
      });

      console.log('Ответ от сервера:', result);

      if (result.response.status === 201) {
        setFormHintClass('form__hint--green');
        onClose({ car: result.data!, text: 'Автомобиль успешно добавлен' });
      } else {
        setFormHintClass('form__hint--red');
        setFormHintMessage((result.error as any).message.join(', '));
      }
    } else {
      const result = await carControllerUpdateCar({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        path: {
          id: car.id,
        },
        body: cleanedData as UpdateCarDto,
      });
      if (result.response.status === 200) {
        setFormHintClass('form__hint--green');
        onClose({ car: result.data!, text: 'Автомобиль успешно обновлен' });
      } else {
        setFormHintClass('form__hint--red');
        setFormHintMessage((result.error as any).message.join(', '));
      }
    }

    if (cleanedData.isMainCar) {
      const result = await userControllerUpdateUser({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        path: {
          id: user!.id,
        },
        body: {
          mainCarId: car!.id,
        },
      });
      console.log('Выбрано главное авто:', result);
    } else if (dirtyFields.isMainCar) {
      const result = await userControllerUpdateUser({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        path: {
          id: user!.id,
        },
        body: {
          mainCarId: null,
        },
      });
      console.log('Не выбрано главное авто:', result);
    }
  };

  const onInvalid: SubmitErrorHandler<CarFormSchema> = (error) => {
    console.log(error);

    setFormHintClass('form__hint--red');
  };

  const onSubmit = (event?: React.SyntheticEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    handleSubmit(onValid, onInvalid)();
  };

  const onRemove = async () => {
    const result = await carControllerDeleteCar({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      path: {
        id: car!.id,
      },
    });

    console.log('Ответ от сервера:', result);

    if (result.response.status === 204) {
      setFormHintClass('form__hint--green');
      onClose({
        text: 'Авто удалено',
      });
    } else {
      setFormHintClass('form__hint--red');
      setFormHintMessage((result.error as any).message.join(', '));
    }
  };

  const onClickClose = () => {
    {
      if (formIsDirty && Object.keys(dirtyFields).length) {
        showDialog({
          text: 'Изменения не сохранены',
          yesButtonText: (
            <>
              сохранить и<br />
              перейти
            </>
          ),
          noButtonText: (
            <>
              перейти без
              <br />
              сохранения
            </>
          ),
          onClickYes: () => {
            hideDialog();
            onSubmit();
          },
          onClickNo: () => {
            hideDialog();
            onClose();
          },
        });
      } else {
        onClose();
      }
    }
  };

  // ---

  const [brandSelectOptions, setBrandSelectOptions] = useState<null | SelectOption<number>[]>(null);
  const [modelSelectOptions, setModelSelectOptions] = useState<null | SelectOption<number>[]>(null);
  const [generationSelectOptions, setGenerationSelectOptions] = useState<
    null | SelectOption<number>[]
  >(null);

  useEffect(() => {
    const brandOptions = brands?.map((brand) => ({
      value: brand.id,
      text: brand.name,
    }));

    setBrandSelectOptions(brandOptions || null);
  }, [brands]);

  useEffect(() => {
    const modelOptions = models?.map((model) => ({
      value: model.id,
      text: model.name,
    }));

    setModelSelectOptions(modelOptions || null);
  }, [models]);

  useEffect(() => {
    const generationOptions = generations?.map((generation) => ({
      value: generation.id,
      text: generation.name,
    }));

    setGenerationSelectOptions(generationOptions || null);
  }, [generations]);

  const brandId = watch('brandId');
  const modelId = watch('modelId');

  useEffect(() => {
    resetField('modelId');
    setModelSelectOptions(null);
    fetchModels(brandId);

    setValue('generationId', null);
  }, [brandId]);

  useEffect(() => {
    resetField('generationId');
    setGenerationSelectOptions(null);
    fetchGenerations(modelId);
  }, [modelId]);

  // ---

  const [fuelTypeSelectOptions, setFuelTypeSelectOptions] = useState<null | SelectOption<number>[]>(
    null,
  );

  useEffect(() => {
    const selectOptions = fuelTypes?.map((item) => ({
      value: item.id,
      text: item.name,
    }));

    setFuelTypeSelectOptions(selectOptions || null);
  }, [fuelTypes]);

  const [transmissionTypeSelectOptions, setTransmissionTypeSelectOptions] = useState<
    null | SelectOption<number>[]
  >(null);

  useEffect(() => {
    const selectOptions = transmissionTypes?.map((item) => ({
      value: item.id,
      text: item.name,
    }));

    setTransmissionTypeSelectOptions(selectOptions || null);
  }, [transmissionTypes]);

  const [driveTypeSelectOptions, setDriveTypeSelectOptions] = useState<
    null | SelectOption<number>[]
  >(null);

  useEffect(() => {
    const selectOptions = driveTypes?.map((item) => ({
      value: item.id,
      text: item.name,
    }));

    setDriveTypeSelectOptions(selectOptions || null);
  }, [driveTypes]);

  const [bodyTypeSelectOptions, setBodyTypesSelectOptions] = useState<
    null | SelectOption<number>[]
  >(null);

  useEffect(() => {
    const selectOptions = bodyTypes?.map((item) => ({
      value: item.id,
      text: item.name,
    }));

    setBodyTypesSelectOptions(selectOptions || null);
  }, [bodyTypes]);

  // ---

  const {
    isShowing,
    text,
    yesButtonText,
    noButtonText,
    onClickYes,
    onClickNo,
    showDialog,
    hideDialog,
  } = useQuestionDialog();

  return (
    <>
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        {closeButtonText && (
          <div className="form__header">
            <span className="form__header-title">
              {car ? car.brand.name + ' ' + car.model.name : 'Добавить авто'}
            </span>

            <span className="form__header-button button-go-back" onClick={() => onClickClose()}>
              <svg
                className="button-go-back-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z"></path>
              </svg>
              <span>{closeButtonText}</span>
            </span>
          </div>
        )}

        <div className="form__item form__item--required">
          <label htmlFor="car-name">Марка авто</label>
          <div className="form__item-element">
            <Controller
              name="brandId"
              control={control}
              render={({ field }) => (
                <Select
                  options={brandSelectOptions}
                  defaultValue={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <span className="form__item-element-postfix "></span>
          </div>
        </div>

        <div className="form__item form__item--required">
          <label htmlFor="car-name">Модель авто</label>
          <div className="form__item-element">
            <Controller
              name="modelId"
              control={control}
              render={({ field }) => (
                <Select
                  options={modelSelectOptions}
                  defaultValue={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <span className="form__item-element-postfix "></span>
          </div>
        </div>

        <div className="form__item">
          <label htmlFor="car-name">Поколение</label>
          <div className="form__item-element">
            <Controller
              name="generationId"
              control={control}
              render={({ field }) => (
                <Select
                  options={generationSelectOptions}
                  defaultValue={field.value}
                  onChange={(value) => field.onChange(value || null)}
                />
              )}
            />
            <span className="form__item-element-postfix "></span>
          </div>
        </div>

        <div className="form__item form__item--required">
          <label htmlFor="car-mileage">Год выпуска</label>
          <div className="form__item-element">
            <input
              type="number"
              {...register('issueYear', {
                setValueAs: (value) => parseInt(value),
              })}
            />
            <span className="form__item-element-postfix "></span>
          </div>
        </div>

        <div className="form__item form__item--required">
          <label htmlFor="car-mileage">Пробег, км</label>
          <div className="form__item-element">
            <input
              type="number"
              {...register('mileage', { setValueAs: (value) => parseInt(value) })}
            />
            <span className="form__item-element-postfix "></span>
          </div>
        </div>

        <div className="form__item">
          <label htmlFor="car-number">VIN номер</label>
          <div className="form__item-element">
            <input
              {...register('vin', {
                setValueAs: (value) => value || null,
              })}
            />
            <div className="form__item-element">
              <span
                onClick={async () => await navigator.clipboard.writeText(getValues('vin') || '')}
                className="form__item-element-postfix form__item-postfix--interactive"
              >
                <svg
                  className="form__item-postfix-icon-copy"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M208 0L332.1 0c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9L448 336c0 26.5-21.5 48-48 48l-192 0c-26.5 0-48-21.5-48-48l0-288c0-26.5 21.5-48 48-48zM48 128l80 0 0 64-64 0 0 256 192 0 0-32 64 0 0 48c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 176c0-26.5 21.5-48 48-48z"></path>
                </svg>
              </span>
            </div>
          </div>
        </div>

        <div className="form__item">
          <label htmlFor="car-mileage">Гос.номер</label>
          <div className="form__item-element">
            <input
              {...register('gosNumber', {
                setValueAs: (value) => value || null,
              })}
            />
            <span
              onClick={async () =>
                await navigator.clipboard.writeText(getValues('gosNumber') || '')
              }
              className="form__item-element-postfix form__item-postfix--interactive"
            >
              <svg
                className="form__item-postfix-icon-copy"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M208 0L332.1 0c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9L448 336c0 26.5-21.5 48-48 48l-192 0c-26.5 0-48-21.5-48-48l0-288c0-26.5 21.5-48 48-48zM48 128l80 0 0 64-64 0 0 256 192 0 0-32 64 0 0 48c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 176c0-26.5 21.5-48 48-48z"></path>
              </svg>
            </span>
          </div>
        </div>

        <div className="form__item">
          <label htmlFor="car-mileage">Номер кузова</label>
          <div className="form__item-element">
            <input
              {...register('bodyNumber', {
                setValueAs: (value) => value || null,
              })}
            />
            <span className="form__item-element-postfix "></span>
          </div>
        </div>

        <div className="form__item">
          <label htmlFor="car-mileage">Объем двигателя (л/см3)</label>
          <div className="form__item-element">
            <input
              type="number"
              step="any"
              {...register('engineCapacity', {
                setValueAs: (value) => parseFloat(value) || null,
              })}
            />
            <span className="form__item-element-postfix "></span>
          </div>
        </div>

        <div className="form__item">
          <label htmlFor="car-mileage">Мощность двигателя (л.с./кВт)</label>
          <div className="form__item-element">
            <input
              type="number"
              step="any"
              {...register('enginePower', {
                setValueAs: (value) => parseFloat(value) || null,
              })}
            />
            <span className="form__item-element-postfix "></span>
          </div>
        </div>

        <div className="form__item">
          <label htmlFor="car-mileage">Номер и модель двигателя</label>
          <div className="form__item-element">
            <input
              {...register('engineModel', {
                setValueAs: (value) => value || null,
              })}
            />
            <span
              onClick={async () =>
                await navigator.clipboard.writeText(getValues('engineModel') || '')
              }
              className="form__item-element-postfix form__item-postfix--interactive"
            >
              <svg
                className="form__item-postfix-icon-copy"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M208 0L332.1 0c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9L448 336c0 26.5-21.5 48-48 48l-192 0c-26.5 0-48-21.5-48-48l0-288c0-26.5 21.5-48 48-48zM48 128l80 0 0 64-64 0 0 256 192 0 0-32 64 0 0 48c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 176c0-26.5 21.5-48 48-48z"></path>
              </svg>
            </span>
          </div>
        </div>

        <div className="form__item">
          <label htmlFor="car-name">Вид топлива</label>
          <div className="form__item-element">
            <Controller
              name="fuelTypeId"
              control={control}
              render={({ field }) => (
                <Select
                  options={fuelTypeSelectOptions}
                  defaultValue={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <span className="form__item-element-postfix"></span>
          </div>
        </div>

        <div className="form__item">
          <label htmlFor="car-name">КПП</label>
          <div className="form__item-element">
            <Controller
              name="transmissionTypeId"
              control={control}
              render={({ field }) => (
                <Select
                  options={transmissionTypeSelectOptions}
                  defaultValue={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <span className="form__item-element-postfix"></span>
          </div>
        </div>

        <div className="form__item">
          <label htmlFor="car-mileage">Модификация КПП</label>
          <div className="form__item-element">
            <input
              {...register('transmissionModify', {
                setValueAs: (value) => value || null,
              })}
            />
            <span className="form__item-element-postfix form__item-postfix--interactive"></span>
          </div>
        </div>

        <div className="form__item">
          <label htmlFor="car-name">Тип привода</label>
          <div className="form__item-element">
            <Controller
              name="driveTypeId"
              control={control}
              render={({ field }) => (
                <Select
                  options={driveTypeSelectOptions}
                  defaultValue={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <span className="form__item-element-postfix"></span>
          </div>
        </div>

        <div className="form__item">
          <label htmlFor="car-name">Кузов</label>
          <div className="form__item-element">
            <Controller
              name="bodyTypeId"
              control={control}
              render={({ field }) => (
                <Select
                  options={bodyTypeSelectOptions}
                  defaultValue={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <span className="form__item-element-postfix"></span>
          </div>
        </div>

        <div className="form__item">
          <label htmlFor="car-mileage">Страна изготовитель</label>
          <div className="form__item-element">
            <input
              {...register('manufacturerCountry', {
                setValueAs: (value) => value || null,
              })}
            />
            <span className="form__item-element-postfix "></span>
          </div>
        </div>

        <div className="form__item">
          <label htmlFor="notes">Заметки:</label>
          <div className="form__item-element">
            <textarea
              {...register('notes', {
                setValueAs: (value) => value || null,
              })}
            ></textarea>
            <span className="form__item-element-postfix "></span>
          </div>
        </div>

        <div className="form__item">
          <label className="checkbox checkbox--blue">
            <span className="checkbox__text">Сделать авто основным</span>

            <input type="checkbox" {...register('isMainCar')} />

            <span className="checkbox__box checkbox__box--blue"></span>
          </label>
        </div>

        <hr className="separator separator--empty" />

        <input className="button button--primary" type="submit" value="Сохранить" />

        <div className={`form__hint ${formHintClass}`}>
          {formHintMessage}
          {Object.entries(formErrors).map(([field, { message }]) => (
            <p key={field}>{message}</p>
          ))}
        </div>

        {car && (
          <button
            onClick={() => {
              showDialog({
                text: 'Удалить авто?',
                yesButtonText: 'ДА',
                noButtonText: 'НЕТ',
                onClickYes: () => {
                  onRemove();
                  navigate('/?messageText=Автомобиль%20удален');
                },
                onClickNo: () => hideDialog(),
              });
            }}
            className="button button--light"
            type="button"
          >
            <svg className="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
            </svg>
            удалить
          </button>
        )}
      </form>

      <QuestionDialogModal
        isShowing={isShowing}
        text={text}
        yesButtonText={yesButtonText}
        noButtonText={noButtonText}
        onClickYes={onClickYes}
        onClickNo={onClickNo}
      />
    </>
  );
};
