import { Layout } from '../components/Layout/Layout';

export const PrivacyPage = () => {
  return (
    <Layout>
      <main>
        <ul className="breadcrumbs">
          <li>Политика</li>
        </ul>
        <div className="car-info">
          <div className="car-info__title">
            <span className="car-info__title-text" style={{ wordWrap: 'normal' }}>
              ПОЛИТИКА
              <br />
              КОНФИДЕНЦИАЛЬНОСТИ
            </span>
          </div>
        </div>
      </main>
      <div style={{ margin: '0 auto' }} className="box car-info__content-item--wide">
        <div className="list__item list__item--adaptive">
          <div className="list__item-content">
            <div className="list__item-title list__item-title--white">
              <div className="list__item-title-text">
                Политика обработки и защиты персональных данных, конфиденциальности и использования
                файлов «cookie»
              </div>
            </div>
            <div style={{ lineHeight: '15px' }} className="list__item-text list__item-text--gray">
              <div>
                Политика обработки и защиты персональных данных, конфиденциальности и использования
                файлов «cookie»
                <br />
                <br />
                1. Общие положения, стороны, цель документа
                <br />
                <br />
                1.1. Настоящая Политика обработки и защиты персональных данных, конфиденциальности и
                использования файлов «cookie» (далее – «Политика») определяет порядок и условия
                обработки Индивидуальным предпринимателем Степановым Константином Игоревичем (ОГРНИП
                321547600158460, ИНН 541004011004), действующим по законодательству России (далее -
                «Оператор»), информации о физическом лице, которая может быть получена Оператором от
                этого физического лица либо от его законного представителя (далее – «Пользователь»,
                «Субъект персональных данных»), при возникновении следующих отношений с Субъектом
                персональных данных:
                <br />
                <br />
                а) при использовании функций сайта https://vgarage.online, включая все его домены,
                поддомены и страницы, их содержимое, а также интернет-сервисы и программное
                обеспечение, предлагаемые Оператором к использованию на этом сайте (далее вместе –
                «Сайт»);
                <br />
                <br />
                б) при осуществлении Оператором прав и обязанностей, установленных
                соглашениями/договорами, заключенными между Оператором и Пользователем;
                <br />
                <br />
                в) при обработке обращений, жалоб, запросов, сообщений, направляемых Оператором и
                Пользователем друг другу.
                <br />
                <br />
                1.2. Целью и назначением Политики является обеспечение надлежащего правового режима
                персональных данных. Политика не может содержать положения, ограничивающие права и
                свободы субъекта персональных данных, устанавливающие случаи обработки персональных
                данных несовершеннолетних, если иное не предусмотрено законодательством Российской
                Федерации, а также положения, допускающие в качестве условия заключения
                договора/выражения согласия бездействие субъекта персональных данных.
                <br />
                <br />
                2. Правовое основание обработки персональных данных
                <br />
                <br />
                2.1. Правовыми основаниями обработки персональных данных являются:
                <br />
                <br />
                а) согласие на обработку персональных данных, выраженное способом, установленным
                законом и настоящей Политикой;
                <br />
                <br />
                б) соглашения, заключаемые между Оператором и Пользователем;
                <br />
                <br />
                в) локальные нормативные акты Оператора в области персональных данных.
                <br />
                <br />
                2.2. Субъект персональных данных принимает решение о предоставлении его персональных
                данных и дает согласие на их обработку свободно, своей волей и в своем интересе.
                Бездействие субъекта персональных данных не может пониматься как согласие. Согласие
                на обработку персональных данных должно быть конкретным, предметным,
                информированным, сознательным и однозначным. Согласие с условиями Политики может
                быть выражено субъектом персональных данных через совершение любого из следующих
                действий:
                <br />
                <br />
                а) заключение с Оператором договора-оферты об оказании платных образовательных
                услуг; при условии, что Пользователю в каждом месте сбора персональных данных
                предоставлена возможность ознакомиться с полным текстом настоящей Политики;
                <br />
                <br />
                либо
                <br />
                <br />
                б) простановка символа в чек-боксе (в поле для ввода) на Сайте рядом с текстом вида:
                «Я даю согласие на обработку персональных данных на условиях Политики обработки и
                защиты персональных данных, конфиденциальности и использования файлов «cookie»
                (Согласие на обработку персональных данных – Приложение 1 к настоящей Политике), при
                условии, что Пользователю в каждом месте сбора персональных данных предоставлена
                возможность ознакомиться с полным текстом настоящей Политики.
                <br />
                <br />
                3. Правила обработки персональных данных
                <br />
                <br />
                3.1. Цель обработки персональных данных, категории и перечень обрабатываемых
                персональных данных, категории субъектов, персональные данные которых
                обрабатываются, способы и сроки обработки и хранения, порядок уничтожения
                персональных данных при достижении целей их обработки или при наступлении иных
                законных оснований
                <br />
                <br />
                3.1.1. Цель: аутентификация субъекта персональных данных для заключения договора
                оферты.
                <br />
                <br />
                Категории и перечень обрабатываемых данных: фамилия, имя, отчество, телефон,
                электронная почта, адрес регистрации.
                <br />
                <br />
                Категории субъектов, персональные данные которых обрабатываются: субъекты
                персональных данных - Пользователи Сайта, Интернет-платформы Геткурс, а также
                участники Telegram-каналов.
                <br />
                <br />
                Способы обработки: сбор, запись, систематизация, накопление, хранение, уточнение
                (обновление, изменение), извлечение, использование, обезличивание, передача (доступ,
                предоставление), блокирование, удаление, уничтожение персональных данных.
                <br />
                <br />
                Срок обработки и хранения: до получения от субъекта персональных данных требования о
                прекращении обработки/отзыва согласия либо 10 (десять) лет.
                <br />
                <br />
                Порядок уничтожения персональных данных при достижении цели их обработки или при
                наступлении иных законных оснований: лицо, ответственное за обработку персональных
                данных, производит стирание данных методом перезаписи (замена всех единиц хранения
                информации на «0») с составлением акта об уничтожении персональных данных.
                <br />
                <br />
                3.1.2. Цель: связь с Пользователем, направление Пользователю сообщений, уведомлений,
                запросов, ответов, документов, сообщений рекламного или информационного характера.
                <br />
                <br />
                Категории и перечень обрабатываемых данных: имя, фамилия, отчество, телефон,
                электронная почта, адрес регистрации.
                <br />
                <br />
                Категории субъектов, персональные данные которых обрабатываются: субъекты
                персональных данных - Пользователи Сайта, Интернет-платформы Геткурс, а также
                участники Telegram-каналов.
                <br />
                <br />
                Способы обработки: сбор, запись, систематизация, накопление, хранение, уточнение
                (обновление, изменение), извлечение, использование, обезличивание, блокирование,
                удаление, уничтожение персональных данных.
                <br />
                <br />
                Срок обработки и хранения: до получения от субъекта персональных данных требования о
                прекращении обработки/отзыва согласия либо 10 (десять) лет.
                <br />
                <br />
                Порядок уничтожения персональных данных при достижении цели их обработки или при
                наступлении иных законных оснований: лицо, ответственное за обработку персональных
                данных, производит стирание данных методом перезаписи (замена всех единиц хранения
                информации на «0») с составлением акта об уничтожении персональных данных.
                <br />
                <br />
                3.1.3. Цель: обработка обращений, жалоб, запросов, сообщений, направляемых
                Оператором и Пользователем друг другу.
                <br />
                <br />
                Категории и перечень обрабатываемых данных: фамилия, имя, отчество, телефон,
                электронная почта, адрес регистрации, текст сообщения (если текст сообщения содержит
                персональные данные).
                <br />
                <br />
                Категории субъектов, персональные данные которых обрабатываются: субъекты
                персональных данных - Пользователи Сайта, Интернет-платформы Геткурс, а также
                участники Telegram-каналов.
                <br />
                <br />
                Способы обработки: сбор, запись, систематизация, накопление, хранение, уточнение
                (обновление, изменение), извлечение, использование, обезличивание, передача (доступ,
                предоставление) блокирование, удаление, уничтожение персональных данных.
                <br />
                <br />
                Срок обработки и хранения: до получения от субъекта персональных данных требования о
                прекращении обработки/отзыва согласия либо 10 (десять) лет.
                <br />
                <br />
                Порядок уничтожения персональных данных при достижении цели их обработки или при
                наступлении иных законных оснований: лицо, ответственное за обработку персональных
                данных, производит стирание данных методом перезаписи (замена всех единиц хранения
                информации на «0») с составлением акта об уничтожении персональных данных.
                <br />
                <br />
                3.1.4. Цель: оставление Пользователем отзывов об услугах Оператора.
                <br />
                <br />
                Категории и перечень обрабатываемых данных: фамилия, имя, отчество, текст сообщения
                (если текст сообщения содержит персональные данные), данные аккаунтов социальных
                сетей Пользователя, данные об образе: фотографии, видеозаписи, иная техническая
                фиксация образов лица и тела.
                <br />
                <br />
                Категории субъектов, персональные данные которых обрабатываются: субъекты
                персональных данных - Пользователи Сайта, Интернет-платформы Геткурс, а также
                участники Telegram-каналов.
                <br />
                <br />
                Способы обработки: сбор, запись, систематизация, накопление, хранение, уточнение
                (обновление, изменение), извлечение, использование, обезличивание, передача (доступ,
                предоставление) блокирование, удаление, уничтожение персональных данных.
                <br />
                <br />
                Срок обработки и хранения: до получения от субъекта персональных данных требования о
                прекращении обработки/отзыва согласия либо 10 (десять) лет.
                <br />
                <br />
                Порядок уничтожения персональных данных при достижении цели их обработки или при
                наступлении иных законных оснований: лицо, ответственное за обработку персональных
                данных, производит стирание данных методом перезаписи (замена всех единиц хранения
                информации на «0») с составлением акта об уничтожении персональных данных.
                <br />
                <br />
                3.1.5. Обработка персональных данных будет ограничиваться достижением этих
                конкретных, заранее определенных и законных целей. Не допускается обработка
                персональных данных, несовместимая с целью обработки.
                <br />
                <br />
                3.1.6. О технологии куки (cookie):
                <br />
                <br />
                3.1.6.1. Куки (cookie): – это фрагмент данных, отправленный сервером Оператора и
                хранимый на устройстве Субъекта персональных данных. Содержимое такого файла может
                как относиться, так и не относиться к персональным данным, в зависимости от того,
                содержит ли такой файл персональные данные или содержит обезличенные технические
                данные.
                <br />
                <br />
                3.1.6.2. Субъект персональных данных вправе запретить своему оборудованию прием этих
                данных или ограничить прием этих данных. При отказе от получения таких данных или
                при ограничении приема данных некоторые функции Сайта могут работать некорректно.
                Субъект персональных данных обязуется сам настроить свое оборудование таким
                способом, чтобы оно обеспечивало адекватный его желаниям режим работы и уровень
                защиты данных куки (cookie), а Оператор не предоставляет технологических и правовых
                консультаций на темы подобного характера.
                <br />
                <br />
                3.2. Порядок и условия обработки персональных данных
                <br />
                <br />
                3.2.1. Обработка персональных данных – любое действие (операция) или совокупность
                действий (операций), совершаемых с использованием средств автоматизации или без
                использования таких средств с персональными данными, включая сбор, запись,
                систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
                использование, передачу (предоставление, доступ), обезличивание, блокирование,
                удаление, уничтожение персональных данных. В настоящей Политике устанавливаются цели
                обработки персональных данных, категории и перечень обрабатываемых персональных
                данных, категории субъектов, персональные данные которых обрабатываются, способы и
                сроки обработки и хранения, порядок уничтожения персональных данных при достижении
                целей их обработки или при наступлении иных законных оснований для каждой цели
                обработки.
                <br />
                <br />
                3.2.2. Оператор может обрабатывать данные указанными способами (операциями) как в
                информационных системах персональных данных, так и без использования средств
                автоматизации.
                <br />
                <br />
                3.2.3. Оператор будет обрабатывать персональные данные столько времени, сколько это
                необходимо для достижения конкретной цели обработки.
                <br />
                <br />
                3.2.4. В случае отзыва субъектом персональных данных согласия на обработку
                персональных данных или истечения срока действия согласия, направления субъектом
                персональных данных требования о прекращении обработки персональных данных, Оператор
                вправе заблокировать данные и обрабатывать их в архивном виде в течение 3 (трёх)
                лет.
                <br />
                <br />
                3.3. Меры по защите персональных данных
                <br />
                <br />
                3.3.1. Оператор принимает все необходимые меры для защиты персональных данных от
                несанкционированного, случайного или незаконного уничтожения, потери, изменения,
                недобросовестного использования, раскрытия или доступа, а также иных незаконных форм
                обработки.
                <br />
                <br />
                3.3.2. По умолчанию персональная информация обрабатывается автоматическим
                оборудованием без доступа к ней кого-либо. В случае если такой доступ понадобится,
                то Оператор предоставляет доступ к персональным данным только тем лицам, которым эта
                информация необходима для обеспечения Цели обработки. Для защиты и обеспечения
                конфиденциальности данных такие лица должны обязаться соблюдать внутренние правовые
                правила и процедуры, технические и организационные меры безопасности в отношении
                обработки персональной информации.
                <br />
                <br />
                3.3.3. Как при обработке персональных данных без использования средств
                автоматизации, так и при автоматизированной обработке обеспечивается достаточная
                безопасность места, где происходит обработка персональных данных.
                <br />
                <br />
                3.3.4. Раскрытие персональных данных может быть произведено лишь в соответствии с
                действующим законодательством Российской Федерации по требованию суда,
                правоохранительных органов, и в иных предусмотренных законодательством Российской
                Федерации случаях.
                <br />
                <br />
                3.3.5. Оператор не проверяет достоверность информации, предоставляемой Субъектом
                персональных данных, и исходит из того, что Субъект персональных данных в порядке
                принципа добросовестности и требований ст. 19 Гражданского кодекса РФ предоставляет
                достоверную и достаточную информацию, заботится о своевременности внесения изменений
                в ранее предоставленную информацию, актуализирует информацию.
                <br />
                <br />
                3.4. Передача персональных данных третьим лицам и распространение персональных
                данных
                <br />
                <br />
                3.4.1. Оператор вправе осуществить передачу (способом доступа и предоставления)
                персональных данных следующим третьим лицам:
                <br />
                <br />
                а) в отношении которых произведена уступка (перевод) прав или обязанностей, либо
                новация по соответствующему соглашению (например, при правопреемстве, при продаже
                или ином отчуждении бизнеса в целом или части бизнеса);
                <br />
                <br />
                б) любому регулирующему органу, правоохранительным органам, центральным или местным
                органам власти, другим официальным или государственным органам или судам, которым
                Оператор обязан по запросу предоставлять информацию в соответствии с применимым
                законодательством;
                <br />
                <br />
                в) лицам, осуществляющим обеспечение правовой защиты Оператора или третьих лиц при
                нарушении их прав либо угрозе нарушения их прав, включая нарушение законов или
                регулирующих документов;
                <br />
                <br />
                г) в случае если Субъект персональных данных сам выразил согласие на передачу
                персональных данных третьему лицу, либо передача персональных данных требуется для
                исполнения соглашения или договора, заключенного с Субъектом персональных данных.
                Сюда относятся в том числе случаи, когда Пользователь разрешил своему оборудованию
                прием, передачу и хранение файлов технологии куки (cookie), если такой файл содержит
                персональные данные.
                <br />
                <br />
                3.4.2. Оператор вправе осуществить передачу персональных данных в следующих случаях:
                <br />
                <br />
                а) Пользователь сам разрешил передачу персональных данных выбранной
                компании-поставщику автоуслуг при отправке онлайн заявки через функционал сервиса
                VGarage.
                <br />
                <br />
                3.5. Права и обязанности в отношении персональных данных.
                <br />
                <br />
                3.5.1. Субъект персональных данных обязан заботиться о достоверности предоставленной
                информации, о своевременности внесения изменений в предоставленную информацию, ее
                актуализации, в противном случае Оператор не несет ответственности за неисполнение
                обязательств, любые убытки, вред или потери.
                <br />
                <br />
                3.5.2. Для реализации права на дополнение, исправление, блокировку и удаление
                персональных данных, Субъект персональных данных вправе направить письменное
                обращение на адрес электронной почты Оператора, указанный в настоящей Политике.
                <br />
                <br />
                3.5.3. Основные права субъекта персональных данных:
                <br />
                <br />
                ● запрашивать информацию об осуществляемой обработке персональных данных;
                <br />
                <br />
                ● отзывать согласие на обработку персональных данных;
                <br />
                <br />
                ● требовать ограничений на обработку персональных данных;
                <br />
                <br />
                ● требовать прекратить обработку персональных данных, если это предусмотрено
                применимым законодательством и настоящей Политикой.
                <br />
                <br />
                В случаях, предусмотренных применимым законодательством, Субъект персональных данных
                обладает другими правами, не указанными выше.
                <br />
                <br />
                3.5.4. Субъект персональных данных обязуется воспользоваться реквизитами из
                настоящей Политики для направления запросов о реализации прав субъекта персональных
                данных или жалоб относительно некорректности информации или незаконности ее
                обработки. Такие запросы и жалобы рассматриваются в срок, не превышающий 10 (Десяти)
                рабочих дней с даты поступления Оператору.
                <br />
                <br />
                3.5.5. Субъект персональных данных вправе в любое время отозвать согласие на
                обработку персональных данных. Для отзыва согласия на обработку персональных данных
                Субъект персональных данных направляет Оператору Уведомление в письменной форме об
                отзыве согласия по адресу электронной почты, указанному в разделе реквизитов
                настоящей Политики.
                <br />
                <br />
                3.6. Правила хранения и обработки персональных данных граждан РФ, правила о
                трансграничной передаче персональных данных
                <br />
                <br />
                3.6.1. Оператор осуществляет обработку персональных данных с использованием баз
                данных, находящихся на территории Российской Федерации.
                <br />
                <br />
                3.6.2. Оператор до начала осуществления трансграничной передачи персональных данных
                обязан убедиться в том, что иностранным государством, на территорию которого
                предполагается осуществлять передачу персональных данных, обеспечивается надежная
                защита прав субъектов персональных данных и уведомить государственный орган,
                уполномоченный в сфере персональных данных, о намерении осуществлять трансграничную
                передачу персональных данных на территории иностранных государств, отвечающих
                указанным требованиям.
                <br />
                <br />
                3.6.3. Оператор до начала осуществления трансграничной передачи персональных данных
                на территорию иностранного государства, в котором не обеспечивается надежная защита
                прав субъектов персональных данных, обязан получить разрешение государственного
                органа, уполномоченного в сфере персональных данных, осуществлять трансграничную
                передачу персональных данных на территории таких иностранных государств.
                <br />
                <br />
                4. Изменение Политика обработки и защиты персональных данных, конфиденциальности и
                использования файлов «cookie». Применимое законодательство. Толкование.
                <br />
                <br />
                4.1. Оператор имеет право вносить изменения в настоящую Политику. При внесении
                изменений в актуальной редакции указывается дата последнего обновления. Новая
                редакция Политики вступает в силу с момента ее размещения, если иное не
                предусмотрено новой редакцией Политики. Утратившие силу редакции доступны в архиве
                по указанному в Политике адресу.
                <br />
                <br />
                4.2. Оператор информирует субъектов персональных данных, ранее выразивших свое
                согласие с Политикой, об изменении Политики, руководствуясь при выборе формы
                информирования тем, что согласие субъекта на обработку персональных данных должно
                быть конкретным, предметным, информированным, сознательным и однозначным.
                <br />
                <br />
                4.3. Местом выражения согласия и местом исполнения Политики всегда является место
                нахождения Оператора, а правом, применимым к отношениям Оператора и Субъекта
                персональных данных, всегда является право России, вне зависимости от того, где
                находится Субъект персональных данных или оборудование, используемое им. Все споры и
                разногласия разрешаются по месту нахождения Оператора, если законом не предусмотрено
                иное.
                <br />
                <br />
                4.4. Политика начинает регулировать отношения Субъекта персональных данных и
                Оператора с момента выражения Субъектом персональных данных согласия с ее условиями
                и действует бессрочно. Бессрочность действия Политики как документа никаким образом
                не означает бессрочность/отсутствие ограничений срока обработки персональных данных.
                Одностороннее прекращение действия Политики по воле одной из сторон не допускается.
                <br />
                <br />
                4.5. Правила толкования:
                <br />
                <br />
                4.5.1. Термины «соглашение» и «договор» равнозначны.
                <br />
                <br />
                4.5.2. Считается, что за словами «включают», «включает», «включая», «например», «к
                примеру», «в том числе», «такие как» всегда следует словосочетание «но не
                ограничиваясь», которое не ограничивает общего характера того, что предшествует этим
                словам.
                <br />
                <br />
                4.5.3. Считается, что слова «или»/ «либо» понимаются по умолчанию как перечисление,
                то есть аналогично «и», если из смысла текста прямо не следует, что слово
                «или»/»либо» обозначает именно выбор одного из вариантов.
                <br />
                <br />
                4.5.4. Считается, что значение Слова, использованного с Заглавной буквы, ничем не
                отличается от значения этого же слова, использованного со строчной буквы.
                <br />
                <br />
                5. Реквизиты Оператора
                <br />
                <br />
                ИП Степанов Константин Игоревич
                <br />
                <br />
                ИНН 541004011004
                <br />
                <br />
                ОГРНИП 321547600158460
                <br />
                <br />
                e-mail: stekoslife@gmail.com
                <br />
                <br />
                <br />
                <br />
                5.1. Информация для реализации прав субъекта персональных данных: Субъект
                персональных данных может реализовать все права, присущие субъекту персональных
                данных, а также получить разъяснения по вопросам, касающимся обработки персональных
                данных, обратившись к Оператору по адресу электронной почты Оператора.
                <br />
                <br />
                6. Сведения о документе
                <br />
                <br />
                6.1. Дата публикации настоящей редакции документа: 01.09.2024
                <br />
                <br />
                <br />
                <br />
                Приложение 1: Согласие на обработку персональных данных.
                <br />
                <br />
                <br />
                <br />
                Приложение 1 к Политике обработки и защиты персональных данных, конфиденциальности и
                использования файлов «cookie»
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                СОГЛАСИЕ
                <br />
                на обработку персональных данных
                <br />
                <br />
                <br />
                <br />
                Настоящим свободно, своей волей и в своем интересе даю согласие Индивидуальному
                предпринимателю Степанову Константину Игоревичу (ОГРНИП 321547600158460, ИНН
                541004011004), (далее – Оператор), на автоматизированную и неавтоматизированную
                обработку своих персональных данных в соответствии со следующим перечнем:
                <br />
                <br />
                фамилия, имя, отчество;
                <br />
                <br />
                номер телефона;
                <br />
                <br />
                адрес электронной почты;
                <br />
                <br />
                данные по автомобилю;
                <br />
                <br />
                источник захода на сайт и информация поискового или рекламного запроса;
                <br />
                <br />
                данные о пользовательском устройстве (среди которых разрешение, версия и другие
                атрибуты, характеризующие пользовательское устройство);
                <br />
                <br />
                пользовательские клики, просмотры страниц, заполнения полей, показы и просмотры
                баннеров и видео;
                <br />
                <br />
                данные, характеризующие аудиторные сегменты;
                <br />
                <br />
                параметры сессии;
                <br />
                <br />
                данные о времени посещения;
                <br />
                <br />
                идентификатор пользователя, хранимый в cookie;
                <br />
                <br />
                иные индивидуальные средства коммуникации, указанные при заполнении на сайте
                https://vgarage.online (далее – сайт) форм обратной связи на сайте, использовании
                сервисов регистрации на сайте;
                <br />
                <br />
                данные аккаунтов социальных сетей;
                <br />
                <br />
                данные об образе: фотографии, видеозаписи, иная техническая фиксация образов лица и
                тела.
                <br />
                <br />
                <br />
                <br />
                для целей:
                <br />
                <br />
                заключения договора оферты;
                <br />
                <br />
                связи с Пользователем, направления Пользователю сообщений, уведомлений, запросов,
                ответов, документов, сообщений рекламного или информационного характера;
                <br />
                <br />
                обработки обращений, жалоб, запросов, сообщений, направляемых Оператором и
                Пользователем друг другу;
                <br />
                <br />
                оставления Пользователем отзывов об услугах Оператора.
                <br />
                <br />
                Также даю свое согласие на предоставление Оператором моих персональных данных
                третьим лицам и на осуществление трансграничной передачи персональных данных для
                достижения заявленных целей обработки персональных данных.
                <br />
                <br />
                В целях обеспечения реализации требований законодательства в области обработки
                персональных данных Оператор может:
                <br />
                <br />
                осуществлять обработку персональных данных путем сбора, записи, систематизации,
                накопления, хранения, уточнения (обновления, изменения), извлечения, использования,
                обезличивания, передачи (доступа, предоставления), блокирования, удаления,
                уничтожения персональных данных.
                <br />
                <br />
                использовать автоматизированный способ обработки персональных данных с передачей по
                сети Интернет, трансграничную передачу персональных данных.
                <br />
                <br />
                Настоящее согласие вступает в силу с момента подтверждения и действует в течение 10
                лет, если иное не установлено действующим законодательством РФ.
                <br />
                <br />
                Отзыв согласия на обработку персональных данных осуществляется путем направления
                заявления в письменной форме на адрес Оператора: stekoslife@gmail.com
                <br />
                <br />Я подтверждаю, что мне известно о праве отозвать свое согласие посредством
                составления соответствующего письменного документа, который может быть направлен
                мной в адрес Оператора. В случае моего отзыва согласия на обработку персональных
                данных Оператор вправе продолжить обработку персональных данных без моего согласия
                при наличии оснований, указанных в пунктах 2-11 части 1 статьи 6, части 2 статьи 10
                и части 2 статьи 11 Федерального закона от 27.07.2006г. №152-ФЗ «О персональных
                данных».
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
