import React from 'react';

interface DatePickerProps {
  date: string;
  onChange: (date: string) => void;
}

export const DatePicker = ({ date, onChange }: DatePickerProps) => {
  return <div>DatePicker</div>;
};
