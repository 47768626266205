import React, { useEffect, useState } from 'react';
import { useAccessToken } from '../../hooks/useAccessToken';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { QuestionDialogModal, useQuestionDialog } from '../Modal/QuestionDialogModal';
import { NotificationFormSchema } from './schemas/NotificationFormSchema';
import {
  NotificationEntity,
  notificationControllerDeleteNotification,
  notificationControllerUpdateNotification,
} from '../../clients/VgarageApi';
import { useFetch } from '../../hooks/useFetch';

interface NotificationFormProps {
  notification: NotificationEntity;
  closeButtonText?: React.ReactNode | undefined;
  onClose: (text?: string) => void;
}

export const NotificationForm = ({
  notification,
  closeButtonText,
  onClose,
}: NotificationFormProps) => {
  const { accessToken } = useAccessToken();

  const [markNotificationAsReaded, isMarkingNotificationAsReaded] = useFetch(async () => {
    if (accessToken) {
      const result = await notificationControllerUpdateNotification({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        path: {
          id: notification.id,
        },
        body: {
          isRead: true,
        },
      });

      return result.data || null;
    } else {
      return null;
    }
  });

  useEffect(() => {
    markNotificationAsReaded();
  }, [accessToken]);

  // ---

  const [formHintMessage, setFormHintMessage] = useState('');
  const [formHintClass, setFormHintClass] = useState('');

  const formResolver = classValidatorResolver(NotificationFormSchema);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty: formIsDirty },
  } = useForm<NotificationFormSchema>({
    resolver: formResolver,
    defaultValues: notification,
  });

  const onValid: SubmitHandler<NotificationFormSchema> = async (data) => {
    console.log(data);

    const result = await notificationControllerUpdateNotification({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      path: {
        id: notification.id,
      },
      body: {
        isRead: false,
      },
    });

    console.log('Ответ от сервера:', result);

    onClose('Уведомление отмечено как непрочитанное');
  };

  const onInvalid: SubmitErrorHandler<NotificationFormSchema> = (error) => {
    console.log(error);

    setFormHintClass('form__hint--red');
  };

  const onSubmit = (event?: React.SyntheticEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    handleSubmit(onValid, onInvalid)();
  };

  const onRemove = async () => {
    const result = await notificationControllerDeleteNotification({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      path: {
        id: notification.id,
      },
    });

    console.log('Ответ от сервера:', result);

    if (result.response.status === 204) {
      setFormHintClass('form__hint--green');
      onClose('Уведомление удалено');
    } else {
      setFormHintClass('form__hint--red');
      setFormHintMessage((result.error as any).message.join(', '));
    }
  };

  // ---

  const {
    isShowing,
    text,
    yesButtonText,
    noButtonText,
    onClickYes,
    onClickNo,
    showDialog,
    hideDialog,
  } = useQuestionDialog();

  return (
    <>
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div className="form__header">
          <span className="form__header-title">{notification.title}</span>
          <span className="form__header-button button-go-back" onClick={() => onClose()}>
            <svg
              className="button-go-back-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z"></path>
            </svg>
            <span>{closeButtonText}</span>
          </span>
        </div>
        {notification.sentAt.split('T')[0]}
        <div className="form__item">
          <textarea name="notes" readOnly>
            {notification.text}
          </textarea>
        </div>
        <span style={{ textAlign: 'right' }}>Отправитель: Напоминание</span>
        <a
          href={`/reminds?id=${notification.remind?.id}`}
          target="_blank"
          className="button button--primary"
        >
          Перейти к напоминанию
        </a>
        <div className={`form__hint ${formHintClass}`}>
          {formHintMessage}
          {Object.entries(formErrors).map(([field, { message }]) => (
            <p key={field}>{message}</p>
          ))}
        </div>
        <button
          onClick={() => {
            showDialog({
              text: 'Удалить уведомление?',
              yesButtonText: 'ДА',
              noButtonText: 'НЕТ',
              onClickYes: () => onRemove(),
              onClickNo: () => hideDialog(),
            });
          }}
          className="button button--light"
          type="button"
        >
          <svg className="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
          </svg>
          удалить
        </button>
      </form>

      <QuestionDialogModal
        isShowing={isShowing}
        text={text}
        yesButtonText={yesButtonText}
        noButtonText={noButtonText}
        onClickYes={onClickYes}
        onClickNo={onClickNo}
      />
    </>
  );
};
