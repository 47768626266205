import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import './Modal.scss';

interface UseQuestionDialog {
  isShowing: boolean;
  text: string;
  yesButtonText: ReactNode;
  noButtonText: ReactNode;
  onClickYes: () => void;
  onClickNo: () => void;
  showDialog: (options: ShowDialogOptions) => void;
  hideDialog: () => void;
}

interface ShowDialogOptions {
  text: string;
  yesButtonText?: ReactNode;
  noButtonText?: ReactNode;
  onClickYes: () => void;
  onClickNo: () => void;
}

export const useQuestionDialog = (): UseQuestionDialog => {
  const [isShowing, setIsShowing] = useState(false);
  const [text, setText] = useState('');
  const [yesButtonText, setYesButtonText] = useState<ReactNode>(null);
  const [noButtonText, setNoButtonText] = useState<ReactNode>(null);
  const [onClickYes, setOnClickYes] = useState(() => () => {});
  const [onClickNo, setOnClickNo] = useState(() => () => {});

  const showDialog = useCallback((options: ShowDialogOptions) => {
    setText(options.text);
    setYesButtonText(options.yesButtonText || 'ДА');
    setNoButtonText(options.noButtonText || 'НЕТ');
    setOnClickYes(() => options.onClickYes);
    setOnClickNo(() => options.onClickNo);
    setIsShowing(true);
  }, []);

  const hideDialog = useCallback(() => {
    setIsShowing(false);
  }, []);

  return {
    isShowing,
    text,
    yesButtonText,
    noButtonText,
    onClickYes,
    onClickNo,
    showDialog,
    hideDialog,
  };
};

interface QuestionDialogModalProps {
  isShowing: boolean;
  text: string;
  yesButtonText?: React.ReactNode;
  noButtonText?: React.ReactNode;
  onClickYes: () => void;
  onClickNo: () => void;
}

export const QuestionDialogModal = ({
  isShowing,
  yesButtonText,
  noButtonText,
  onClickYes,
  onClickNo,
  text,
}: QuestionDialogModalProps) => {
  const [showingClass, setShowingClass] = useState('');

  useEffect(() => {
    setShowingClass(isShowing ? 'modal--showing' : '');
    document.body.style.overflow = isShowing ? 'hidden' : 'unset';
  }, [isShowing]);

  // ---

  if (!isShowing) return null;

  return createPortal(
    <div className={`modal ${showingClass}`}>
      <div className="modal__content">
        <div className="dialog box box--dialog">
          <span onClick={onClickNo} className="dialog__close">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
            </svg>
          </span>

          <div className="dialog__content">
            <div className="dialog__text">{text}</div>
            <div className="dialog__buttons">
              <button onClick={onClickYes} className="dialog__button dialog__button--with-icon">
                <svg
                  className="dialog__button-icon icon icon--20x20 icon--green"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"></path>
                </svg>
                {yesButtonText}
              </button>

              <button onClick={onClickNo} className="dialog__button dialog__button--with-icon">
                <svg
                  className="dialog__button-icon icon icon--20x20 icon--red"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
                </svg>
                {noButtonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body,
  );
};
