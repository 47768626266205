import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { publicRoutes } from '../../routes/publicRoutes';
import { privateRoutes } from '../../routes/privateRoutes';
import { ErrorPage } from '../../pages/ErrorPage';
import { useAccessToken } from '../../hooks/useAccessToken';

export const Router = () => {
  const { accessToken } = useAccessToken({ required: false });

  return (
    <Routes>
      {publicRoutes.map((route) => (
        <Route path={route.path} element={route.element} key={route.path} />
      ))}

      {accessToken
        ? privateRoutes.map((route) => (
            <Route path={route.path} element={route.element} key={route.path} />
          ))
        : privateRoutes.map((route) => (
            <Route path={route.path} element={<Navigate to="/login" />} key={route.path} />
          ))}

      <Route path="/error" element={<ErrorPage />} />
      <Route path="*" element={<Navigate to="/error" />} />
    </Routes>
  );
};
