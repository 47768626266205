import React, { useEffect, useState } from 'react';
import { Layout } from '../components/Layout/Layout';
import { useNavigate, useParams } from 'react-router-dom';

import '../styles/offer.scss';
import '../styles//navigation.scss';
import '../styles/icon.scss';
import '../styles/label.scss';
import '../styles/breadcrumbs.scss';
import { GuideCategoryEntity, guideCategoryControllerFindAll } from '../clients/VgarageApi';
import { useFetch } from '../hooks/useFetch';
import { useAccessToken } from '../hooks/useAccessToken';
import '../components/Guide/Guide.scss';
import { Spinner } from '../components/Spinner/Spinner';
import { GuideCategoriesList } from '../components/Guide/GuideCategoriesList';
import { renderIf } from '../utils/renderIf';

export const GuideListPage = () => {
  // const { accessToken } = useAccessToken();

  const navigate = useNavigate();

  const { categoryId: categoryId } = useParams();

  const [selectedCategory, setSelectedCategory] = useState<GuideCategoryEntity>();

  const [fetchGuideCategories, isFetchingGuideCategories, guieCategoriesError, guideCategories] =
    useFetch<GuideCategoryEntity[] | null>(async () => {
      const result = await guideCategoryControllerFindAll({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      });
      if (result.data && Array.isArray(result.data) && result.data.every((item) => 'id' in item)) {
        result.data = result.data.sort((a, b) => a.id - b.id);
        setSelectedCategory(result.data[0]);
        result.data.forEach((category) => {
          if (categoryId) {
            if (category.id == parseInt(categoryId)) {
              setSelectedCategory(category);
            }
          }
        });

        return result.data;
      } else {
        return [];
      }
    });

  useEffect(() => {
    fetchGuideCategories();
  }, [categoryId]);

  return (
    <Layout showMenu={false}>
      <div className="offer">
        <span className="logo"></span>
        <h1 className="offer__title offer__title--with-logo">
          Подробная инструкция
          <br />
          по сайту
        </h1>
      </div>
      <main className="main">
        {isFetchingGuideCategories && <Spinner />}

        <div className="car-info">
          <div className="guide--breadcrumbs">Выберите раздел</div>
          {renderIf(
            () => [
              <GuideCategoriesList selectCategory={parseInt(categoryId!)}></GuideCategoriesList>,
            ],
            categoryId !== undefined,
          )}

          {renderIf(() => [<GuideCategoriesList></GuideCategoriesList>], categoryId === undefined)}
          {guideCategories && (
            <div className="guide--navigation">
              {selectedCategory &&
                selectedCategory.guides.map((guide) => {
                  return (
                    <div
                      onClick={() => navigate(`/guide/${guide.id}`)}
                      className="guide--navigation__card navigation__card--blue"
                    >
                      <div className="guide--navigation__card__title">{guide.title}</div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </main>
    </Layout>
  );
};
