import React, { useEffect, useRef, useState } from 'react';
import './Select.scss';

export interface SelectOption<T extends string | number> {
  value: T;
  text: string;
  subtexts?: string[];
  childrens?: SelectOption<T>[];
}

export interface SelectProps<T extends string | number> {
  isMultiple?: boolean;
  options: null | SelectOption<T>[];
  defaultValue?: null | T | T[];
  onChange?: (value: null | T | T[]) => void;
  notAllowed?: boolean;
  onClickButton?: (() => void) | null;
  deselectOnDubleClick?: boolean;
}

export const Select = <T extends string | number>({
  isMultiple = false,
  options,
  defaultValue = null,
  onChange,
  notAllowed = false,
  onClickButton = null,
  deselectOnDubleClick = true,
}: SelectProps<T>) => {
  const [listIsShowing, setListIsShowing] = useState(false);

  const toggleListShow = () => {
    if (!notAllowed) {
      setListIsShowing(!listIsShowing);
    }
  };

  const [selectedValue, setSelectedValue] = useState<null | T | T[]>(defaultValue);

  const handleOptionClick = (value: T) => {
    let newSelectedValue: null | T | T[];

    if (!isMultiple) {
      if (deselectOnDubleClick && selectedValue === value) {
        newSelectedValue = selectedValue !== value ? value : null;
      } else {
        newSelectedValue = value;
      }
    } else {
      if (selectedValue !== null) {
        newSelectedValue = (selectedValue as T[])?.includes(value)
          ? (selectedValue as T[]).filter((val) => val !== value)
          : [...(selectedValue as T[]), value];
      } else {
        newSelectedValue = [value];
      }
    }

    setSelectedValue(newSelectedValue);
    setListIsShowing(isMultiple);
    setFilteredOptions(options);
  };

  const [prevSelectedValue, setPrevSelectedValue] = useState<null | T | T[]>(defaultValue);

  useEffect(() => {
    if (onChange) {
      if (JSON.stringify(selectedValue) !== JSON.stringify(prevSelectedValue)) {
        onChange(selectedValue);
        setPrevSelectedValue(selectedValue);
      }
    }
  }, [selectedValue, prevSelectedValue, onChange]);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClick(e: MouseEvent) {
      if (!selectRef.current?.contains(e.target as Node)) {
        setListIsShowing(false);
      }
    }
    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  });

  const getSelectedText = (): string => {
    if (isMultiple) {
      return (
        (selectedValue as string[])
          ?.map((value) => {
            const option = options?.find((option) => option.value === value);
            return option ? option.text : '';
          })
          .join('; ') || ''
      );
    } else {
      return selectedValue !== null
        ? options?.find((option) => option.value === selectedValue)?.text || ''
        : '';
    }
  };

  // ---

  const [filterText, setFilterText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    setSelectedValue(defaultValue);
    setFilterText('');
  }, [defaultValue]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
    if (options !== null) {
      setFilteredOptions(
        options.filter((element) =>
          element.text.toLowerCase().includes(event.target.value.toLowerCase()),
        ),
      );
    } else {
      setFilteredOptions(options);
    }
    setListIsShowing(true);
  };

  return (
    <div className="select" ref={selectRef}>
      <button
        onClick={toggleListShow}
        className={`select__current ${
          notAllowed ? 'select__current--not-allowed' : 'select__current--allowed'
        }`}
        type="button"
      >
        <input
          style={{
            border: '0',
            height: '90%',
            background: 'transparent',
            fontFamily: 'var(--font)',
            width: '100%',
            outline: 'none',
          }}
          className={`select__current-text ${
            notAllowed ? 'select__current--not-allowed' : 'select__current--allowed'
          }`}
          value={getSelectedText()}
          readOnly={true}
        />
        <svg
          className="select__icon icon icon--style-vars"
          style={{ '--fill': '#fff', '--width': '15px', '--height': '15px' } as React.CSSProperties}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M21.71,8.21l-9,9a1,1,0,0,1-1.42,0l-9-9A1,1,0,1,1,3.71,6.79L12,15.09l8.29-8.3a1,1,0,1,1,1.42,1.42Z"></path>
        </svg>
      </button>
      <div className={`select__list ${listIsShowing ? 'select__list--showed' : ''}`}>
        {onClickButton && (
          <div onClick={onClickButton} className="select__item select__item-element">
            <button type="button" className="button button--light-white">
              + Добавить запчасть/расходники
            </button>
          </div>
        )}

        <div className="select__item select__item-element">
          <input
            style={{
              border: '0',
              height: '90%',
              background: 'transparent',
              fontFamily: 'var(--font)',
              width: '100%',
              outline: 'none',
            }}
            className={`select__current-text ${
              notAllowed ? 'select__current--not-allowed' : 'select__current--allowed'
            }`}
            value={filterText}
            onChange={handleFilterChange}
            readOnly={false}
            placeholder="Введите текст для поиска..."
          />
        </div>
        {filteredOptions?.map((option) => (
          <div
            key={option.value}
            onClick={() => handleOptionClick(option.value)}
            className="select__item select__item-element"
          >
            <div className="select__item-text">{option.text}</div>
            {option.subtexts?.map((subtext, index) => (
              <div key={index} className="select__item-subtext">
                {subtext}
              </div>
            ))}
            <div className="select__item-checkbox checkbox checkbox__box--light">
              <input
                type="checkbox"
                checked={
                  isMultiple
                    ? (selectedValue as T[])?.includes(option.value)
                    : selectedValue === option.value
                }
                onChange={() => handleOptionClick(option.value)}
              />
              <span className="checkbox__box"></span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
