import React from 'react';

export const renderIf = (render: () => React.ReactNode[], condition: boolean): React.ReactNode => {
  if (condition) {
    return (
      <>
        {render().map((element, index) => (
          <React.Fragment key={index}>{element}</React.Fragment>
        ))}
      </>
    );
  }
  return null;
};
