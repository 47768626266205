import React, { useState } from 'react';
import { UserResetPasswordSchema } from './schemas/UserResetPasswordSchema';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { UpdatePasswordDto, userControllerUpdatePassword } from '../../clients/VgarageApi';

interface UserResetPasswordFormProps {
  passwordResetHash: string;
}

export const UserResetPasswordForm = ({ passwordResetHash }: UserResetPasswordFormProps) => {
  const [formHintMessage, setFormHintMessage] = useState('');
  const [formHintClass, setFormHintClass] = useState('');

  const formResolver = classValidatorResolver(UserResetPasswordSchema);
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<UserResetPasswordSchema>({
    resolver: formResolver,
    defaultValues: {
      hash: passwordResetHash,
    },
  });

  const onValid: SubmitHandler<UserResetPasswordSchema> = async (data) => {
    console.log(data);

    setFormHintMessage('');

    const result = await userControllerUpdatePassword({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      body: data as UpdatePasswordDto,
    });

    console.log(result);

    if (result.response.status === 201) {
      setFormHintClass('form__hint--green');
      setFormHintMessage('Новый пароль установлен, войдите в аккаунт чтобы продолжить работу');
    } else {
      setFormHintClass('form__hint--red');
      setFormHintMessage(`Не удалось установить новый пароль`);
    }
  };

  const onInvalid: SubmitErrorHandler<UserResetPasswordSchema> = (error) => {
    console.log(error);

    setFormHintClass('form__hint--red');
  };

  return (
    <form className="form" onSubmit={handleSubmit(onValid, onInvalid)}>
      <input {...register('hash')} hidden />

      <div className="form__item form__item--wide">
        <div className="form__item-label form__item-label--wide">
          <label>Новый пароль:</label>
        </div>
        <div className="form__item-element form__item-element--wide form__item-element--spark">
          <input {...register('password')} />
        </div>
      </div>

      <div className="form__item form__item--wide">
        <div className="form__item-label form__item-label--wide"></div>
        <div className="form__item-element form__item-element--wide">
          <button type="submit" className="form__button button button--fit button--primary">
            Установить новый пароль
          </button>
        </div>
      </div>

      <div className={`form__hint ${formHintClass}`}>
        {formHintMessage}
        {Object.entries(formErrors).map(([field, { message }]) => (
          <p key={field}>{message}</p>
        ))}
      </div>
    </form>
  );
};
