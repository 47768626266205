import {
  IsBoolean,
  IsDateString,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  Max,
  Min,
} from 'class-validator';

export class EquipmentFormSchema {
  @IsNotEmpty({ message: 'Выберите авто' })
  carId: number;

  @IsNotEmpty({ message: 'Укажите название' })
  name: string;

  @IsOptional()
  @IsNotEmpty({ message: 'Укажите параметры' })
  parameters: string | null;

  @IsOptional()
  @IsNotEmpty()
  @IsDateString({}, { message: 'Укажите дату в формате 2024-12-23' })
  date: string | null;

  @IsOptional()
  @IsNumber()
  @Max(2147483647)
  @Min(0)
  cost: number | null;

  @IsOptional()
  @IsNotEmpty({ message: 'Пробег не может быть пустым' })
  @Max(2147483647, { message: 'Пробег слишком большой' })
  @Min(0, { message: 'Пробег должен быть больше нуля' })
  mileage: number | null;

  @IsOptional()
  @IsBoolean()
  updateMileage: boolean | null;

  @IsOptional()
  @IsNotEmpty({ message: 'Пробег не может быть пустым' })
  article: string | null;

  @IsOptional()
  place: string | null;

  @IsOptional()
  @IsNotEmpty({ message: 'Заметки не могут быть пустыми' })
  notes: string | null;

  @IsOptional()
  @IsBoolean()
  createRemind: boolean | null;
}
