export enum RemindState {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  OVERDUE = 'OVERDUE',
  ERROR = 'ERROR',
}

export const RemindStateName = {
  [RemindState.PENDING]: 'Ожидает',
  [RemindState.COMPLETED]: 'Завершено',
  [RemindState.OVERDUE]: 'Просрочено',
  [RemindState.ERROR]: 'Ошибка',
} as const;

export function getRemindStateName(state: RemindState): string {
  return RemindStateName[state];
}

export const getRemindStateSelectOptions = () => {
  return Object.values(RemindState).map((value) => ({
    value: value as String,
    text: RemindStateName[value as RemindState],
  }));
};
