import { IsNotEmpty, IsString, MinLength } from 'class-validator';

export class UserResetPasswordSchema {
  @IsNotEmpty()
  @IsString()
  @MinLength(6)
  hash: string;

  @IsNotEmpty({ message: 'Укажите новый пароль' })
  @IsString()
  @MinLength(3, { message: 'Длина пароля должна быть 6 или более символов' })
  password: string;
}
