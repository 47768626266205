import { IsNotEmpty, IsNumber, IsOptional, Length, Max, Min } from 'class-validator';

export class CarFormSchema {
  @IsNotEmpty({ message: 'Пробег не может быть пустым' })
  @Max(2147483647, { message: 'Пробег слишком большой' })
  @Min(0, { message: 'Пробег должен быть больше нуля' })
  mileage: number;

  @IsNotEmpty({ message: 'Год выпуска не может быть пустым' })
  @Max(2147483647, { message: 'Год выпуска слишком большой' })
  @Min(0, { message: 'Год выпуска должен быть больше нуля' })
  issueYear: number;

  @IsNumber()
  @IsNotEmpty({ message: 'Марка автомобиля обязательна' })
  brandId: number;

  @IsNumber()
  @IsNotEmpty({ message: 'Модель автомобиля обязательна' })
  modelId: number | null;

  @IsOptional()
  @IsNumber()
  @IsNotEmpty({ message: 'Поколение автомобиля обязательно' })
  generationId: number | null;

  @IsOptional()
  @Length(17, 999, { message: 'VIN-номер должен содержать 17 символов' })
  vin: string | null;

  @IsOptional()
  @IsNotEmpty({ message: 'Номер кузова не может быть пустым' })
  bodyNumber: string | null;

  @IsOptional()
  @IsNotEmpty({ message: 'Госномер не может быть пустым' })
  gosNumber: string | null;

  @IsOptional()
  @IsNotEmpty({ message: 'Модель двигателя не может быть пустой' })
  engineModel: string | null;

  @IsOptional()
  @IsNumber()
  @Max(2147483647, { message: 'Объем двигателя слишком большой' })
  @Min(0, { message: 'Объем двигателя должен быть больше нуля' })
  engineCapacity: number | null;

  @IsOptional()
  @IsNumber()
  @Max(2147483647, { message: 'Мощность двигателя слишком большая' })
  @Min(0, { message: 'Мощность двигателя должен быть больше нуля' })
  enginePower: number | null;

  @IsOptional()
  @IsNumber()
  @Max(2147483647, { message: 'Тип топлива слишком большой' })
  @Min(0, { message: 'Тип топлива должен быть больше нуля' })
  fuelTypeId: number | null;

  @IsOptional()
  @IsNotEmpty({ message: 'Другой тип топлива не может быть пустым' })
  otherFuelType: string | null;

  @IsOptional()
  @IsNumber()
  @Max(2147483647, { message: 'Тип трансмиссии слишком большой' })
  @Min(0, { message: 'Тип трансмиссии должен быть больше нуля' })
  transmissionTypeId: number | null;

  @IsOptional()
  @IsNotEmpty({ message: 'Модификация трансмиссии не может быть пустой' })
  transmissionModify: string | null;

  @IsOptional()
  @IsNumber()
  @Max(2147483647, { message: 'Тип привода слишком большой' })
  @Min(0, { message: 'Тип привода должен быть больше нуля' })
  driveTypeId: number | null;

  @IsOptional()
  @IsNumber()
  @Max(2147483647, { message: 'Тип двигателя слишком большой' })
  @Min(0, { message: 'Тип двигателя должен быть больше нуля' })
  engineTypeId: number | null;

  @IsOptional()
  @IsNumber()
  @Max(2147483647, { message: 'Тип кузова слишком большой' })
  @Min(0, { message: 'Тип кузова должен быть больше нуля' })
  bodyTypeId: number | null;

  @IsOptional()
  @IsNotEmpty({ message: 'Страна производитель не может быть пустой' })
  manufacturerCountry: string | null;

  @IsOptional()
  @IsNotEmpty({ message: 'Примечание не может быт пустым' })
  notes: string | null;

  @IsOptional()
  isMainCar: boolean | null;
}
