import { Layout } from '../components/Layout/Layout';

export const ContactsPage = () => {
  return (
    <Layout>
      <main>
        <ul className="breadcrumbs">
          <li>Контакты</li>
        </ul>
        <div className="car-info">
          <div className="car-info__title">
            <span className="car-info__title-text">КОНТАКТЫ</span>
          </div>
        </div>
      </main>
      <div style={{ margin: '0 auto' }} className="box car-info__content-item--wide">
        <div className="list__item list__item--adaptive">
          <div className="list__item-content">
            <div className="list__item-title list__item-title--white">
              <div className="list__item-title-text">Контакты</div>
            </div>
            <div style={{ lineHeight: '35px' }} className="list__item-text list__item-text--gray">
              <div>
                e-mail:{' '}
                <a href="mailto://stekoslife@gmail.com" target="_blank">
                  stekoslife@gmail.com
                </a>
                <br />
                <a href="https://t.me/vgarage_online_testing" target="_blank">
                  t.me/vgarage_online_testing
                </a>
                <br />
                <a href="https://t.me/stekoslife" target="_blank">
                  t.me/stekoslife
                </a>
                <br />
                Офис: г.Новосибирск, ул. Мира 10
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="list__table-item-data-text-postfix"></div>
      </div>
    </Layout>
  );
};
