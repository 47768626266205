import React from 'react';
import { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Layout } from '../components/Layout/Layout';
import { useTabs } from '../hooks/useTabs';
import { useFetch } from '../hooks/useFetch';
import { Spinner } from '../components/Spinner/Spinner';
import { useAccessToken } from '../hooks/useAccessToken';
import { carControllerDeleteCar, carControllerReadCar } from '../clients/VgarageApi/sdk.gen';
import { CarEntity } from '../clients/VgarageApi';
import { EquipmentList } from '../components/EquipmentList/EquipmentList';
import { Modal } from '../components/Modal/Modal';
import { CarList } from '../components/CarList/CarList';
import { CarForm } from '../components/forms/CarForm';
import { MessageDialogModal } from '../components/Modal/MessageDialogModal';

import '../styles/breadcrumbs.scss';
import '../styles/box.scss';
import '../styles/checkbox.scss';
import '../styles/form.scss';
import '../styles/tabs.scss';
import '../styles/list.scss';
import '../styles/button.scss';
import '../styles/navigation.scss';
import '../styles/separator.scss';
import '../styles/car-info.scss';
import '../styles/icon.scss';
import { QuestionDialogModal, useQuestionDialog } from '../components/Modal/QuestionDialogModal';

export const CarPage = () => {
  const navigate = useNavigate();

  const { accessToken } = useAccessToken();

  const params = useParams();
  const carId = params.id ? parseInt(params.id) : null;

  const [searchParams] = useSearchParams();
  const messageText = searchParams.get('messageText') || null;

  const [fetchCar, isFetchingCar, carError, car] = useFetch<CarEntity | null>(async () => {
    if (carId) {
      const result = await carControllerReadCar({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        path: {
          id: carId,
        },
      });

      return result.data!;
    }

    return null;
  });

  const onRemove = async () => {
    const result = await carControllerDeleteCar({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      path: {
        id: car!.id,
      },
    });
  };

  const {
    isShowing,
    text,
    yesButtonText,
    noButtonText,
    onClickYes,
    onClickNo,
    showDialog,
    hideDialog,
  } = useQuestionDialog();

  useEffect(() => {
    fetchCar();
  }, [accessToken, carId]);

  // ---

  const [showAllTabs, carPartsIsShowing, toggleTabs] = useTabs();

  const [messageDialogModalIsShowing, setMessageDialogModalIsShowing] = useState(
    messageText !== null,
  );
  const [messageDialogModalText, setMessageDialogModalText] = useState(messageText || '');

  const [modalCarListIsShowing, setModalCarListIsShowing] = useState(false);

  // ---

  const isShowingCubes = false;

  return (
    <>
      <Layout>
        <main className="main">
          <ul className="breadcrumbs">
            <li onClick={() => setModalCarListIsShowing(true)} className="link">
              Мои авто
            </li>
            <li>Параметры автомобиля</li>
          </ul>

          {isFetchingCar && <Spinner />}

          {car && (
            <div className="car-info">
              <div className="car-info__title">
                <span className="car-info__title-text">
                  {car.brand.name} {car.model.name}
                </span>
                <button
                  onClick={() => {
                    showDialog({
                      text: 'Удалить авто?',
                      yesButtonText: 'ДА',
                      noButtonText: 'НЕТ',
                      onClickYes: () => {
                        onRemove();
                        navigate('/?messageText=Автомобиль%20удален');
                      },
                      onClickNo: () => hideDialog(),
                    });
                  }}
                  className="car-info__title-close"
                >
                  <svg
                    className="car-info__title-close-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
                  </svg>
                </button>
              </div>

              <div className="car-info__menu">
                <button className="car-info__menu-button car-info__menu-button--parameters car-info__menu-button--active">
                  Параметры автомобиля
                </button>
                <button
                  onClick={() => navigate(`/my-auto/${car.id}/maintenances`)}
                  className="car-info__menu-button car-info__menu-button--maintenances"
                >
                  ТО / Сервис
                </button>
                <button
                  onClick={() => navigate(`/my-auto/${car.id}/documents`)}
                  className="car-info__menu-button car-info__menu-button--documents"
                >
                  Документы
                </button>
                <button
                  onClick={() => navigate(`/my-auto/${car.id}/reminds`)}
                  className="car-info__menu-button car-info__menu-button--reminders"
                >
                  Напоминания
                </button>
              </div>

              <div className="car-info__content">
                {(showAllTabs || !carPartsIsShowing) && (
                  <div className="car-info__content-item car-parameters box">
                    <ul className="tabs">
                      <li className="tabs-item tabs-item--active">Основные</li>
                      <li onClick={toggleTabs} className="tabs-item tabs-item--hide-desktop">
                        Расходники / Запчасти
                      </li>
                    </ul>

                    {car && (
                      <CarForm
                        car={car}
                        onClose={(options) => {
                          if (options && options.text) {
                            setMessageDialogModalText(options.text);
                            setMessageDialogModalIsShowing(true);
                          }
                        }}
                      />
                    )}
                  </div>
                )}

                {(showAllTabs || carPartsIsShowing) && (
                  <div className="car-info__content-item car-parts box">
                    <ul className="tabs">
                      <li onClick={toggleTabs} className="tabs-item tabs-item--hide-desktop">
                        Основные
                      </li>
                      <li className="tabs-item tabs-item--active">Расходники / Запчасти</li>
                    </ul>

                    <EquipmentList carId={carId} />
                  </div>
                )}
              </div>
            </div>
          )}

          {isShowingCubes && (
            <>
              <hr className="separator" />

              <div className="navigation navigation--centered-w">
                <div className="navigation__card navigation__card--red">
                  <svg
                    className="navigation__card-icon icon icon--style-vars"
                    style={
                      {
                        '--fill': 'rgba(255, 84, 71, 1)',
                        '--width': '45px',
                        '--height': '45px',
                        '--width2': '55px',
                        '--height2': '55px',
                      } as React.CSSProperties
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                  >
                    <path
                      d="M9.095,33.551c0.105,0.113,0.234,0.121,0.36,0.079c-0.253,0.672,1.308,1.154,1.881,1.076
                  c2.408-0.084,3.499-3.285,1.696-4.874C10.429,27.426,6.597,30.897,9.095,33.551z M10.103,33.227
                  c-0.062-0.019-0.146-0.009-0.238,0.023c0.05-0.106,0.07-0.213,0.033-0.294c-0.62-1.392,0.725-2.58,1.764-1.665
                  C12.642,32.21,11.372,33.749,10.103,33.227z M2.228,13.489c3.176-0.69,6.37-0.658,9.545,0.027c0.318,0.051,0.701,0.847,0,1
                  c-3.175,0.692-6.371,0.658-9.545-0.027C1.657,14.343,1.889,13.562,2.228,13.489z M30.733,36.446
                  c-1.58,0.386-3.163,0.477-4.742,0.478c-1.576-0.031-3.147-0.128-4.725-0.522c-0.312-0.051-0.585-0.874,0-1
                  c3.153-0.677,6.319-0.639,9.467,0.045C31.046,35.498,31.329,36.301,30.733,36.446z M0.508,17.513c2.52-0.662,4.76-0.779,7.279-0.087
                  c0.565,0.177,0.271,0.925,0.001,1c-2.515,0.682-5.056,0.772-7.575,0.079C-0.035,18.459-0.103,17.673,0.508,17.513z M4.85,35.545
                  c0.44,0.23,0.171,0.92-0.001,1c-1.564,0.689-3.159,0.611-4.713-0.074c-0.148-0.038-0.2-0.829,0.192-1
                  C1.894,34.787,3.296,34.86,4.85,35.545z M45.984,37.765c-0.906,0.575-1.855,1.065-2.813,1.595c0.64-2.161,2.391-8.069,2.391-8.069
                  c0.169-0.52-0.243-1.084-0.792-1.076l-8.922-0.025l7.475-5.424c0.764-0.584,0.375-1.532-0.378-1.907c0,0-6.828-4.927-6.828-4.927
                  c3.374,0.014,9.015,0.037,9.015,0.037c0.562,0.035,1.006-0.55,0.81-1.082l-2.9-8.403l2.878,1.817
                  c2.049,1.324,2.987,0.936,0.555-0.832l-4.279-3.037c-0.473-0.36-1.184,0.16-0.97,0.725l2.759,9.097
                  c-3.001-0.071-10.862-0.257-10.862-0.257c-0.115-0.007-0.222,0.01-0.321,0.042c-2.328-2.46-5.485-3.305-8.772-2.975
                  c-3.272-0.015-6.896-0.575-9.928,0.956c-3.063,1.773-4.886,5.034-7.615,7.183c-2.615-0.292-5.112,1.91-5.114,4.515
                  c0.071,0.62-0.039,1.563,0.052,2.309c-1.226-0.124-1.42,0.847-1.412,1.92c0.006,0.753,0.055,1.556,0.055,2.067
                  c-0.016,0.537,0.423,0.987,0.964,0.964c1.355-0.053,2.709-0.164,4.064-0.37c0.705,6.873,11.068,7.176,11.561,0.07
                  c4.831,0.57,9.668,0.471,14.497-0.09c0.741,7.038,10.526,7.154,11.455,0.254c0.266,0.125,0.534,0.185,0.804,0.177
                  c-0.73,2.873-1.91,7.511-1.91,7.511c-0.192,0.545,0.492,1.058,0.961,0.703c1.452-1.151,4.776-2.54,5.524-4.162
                  C47.978,36.733,47.097,37.081,45.984,37.765z M1.086,29.02c0-0.033,0-0.062,0-0.078C1.086,28.955,1.086,28.98,1.086,29.02
                  C1.086,29.02,1.086,29.02,1.086,29.02z M38.247,31.994c-0.44,1.366-2.027,1.357-2.509,0.046L38.247,31.994z M34.117,29.07
                  c0.44-0.528,0.999-0.955,1.654-1.219C35.172,28.292,34.609,28.708,34.117,29.07z M13.66,16.695c2.317-2.145,5.792-1.695,8.719-1.71
                  c-0.239,2.109-0.362,4.219-0.384,6.33c-4.481,0.017-8.961,0.136-13.441,0.348C10.38,20.123,11.94,18.273,13.66,16.695z
                   M2.013,31.093c0.005-0.376,0.01-0.782,0.014-1.137c0.419-0.018,0.837-0.043,1.256-0.073c0.052,0.436,0.114,0.872,0.186,1.307
                  C2.983,31.148,2.498,31.117,2.013,31.093z M2.384,28.073c0.082-0.474,0.102-1.05,0.122-1.527c0.506,0.13,1.011,0.225,1.517,0.292
                  c-0.012,0.438-0.011,0.876,0.003,1.313C3.483,28.107,2.932,28.094,2.384,28.073z M5.105,31.384
                  c-0.172-0.025-0.344-0.053-0.516-0.074c0.072-0.462,0.133-0.924,0.182-1.386c0.238,0.044,0.497,0.044,0.7-0.085
                  C5.283,30.333,5.16,30.852,5.105,31.384z M13.735,35.12c-1.585,1.333-4.066,1.125-5.489-0.342c-3.565-3.67,0.92-9.057,5.348-6.375
                  c0.252,0.14,0.505,0.229,0.706,0.192c-0.042,0.195,0.03,0.459,0.187,0.722C15.704,31.244,15.375,33.881,13.735,35.12z
                   M16.593,31.533c-0.216-1.068-0.865-3.019-2.033-3.187c0.035-0.204-0.077-0.497-0.374-0.749c-2.348-2.189-6.783-1.516-8.23,1.253
                  c0.025-3.36,0.641-4.395-3.311-3.452c0.286-1.271,1.374-2.285,2.625-2.53c5.577,0.324,11.155,0.476,16.734,0.471
                  c0.049,2.584,0.238,5.168,0.531,7.75C20.553,31.15,18.575,31.27,16.593,31.533z M26.851,14.935c1.708,0.126,3.393,1.064,4.686,2.19
                  c1.983,1.675,4.167,3.147,6.326,4.57c-4.634-0.24-9.269-0.364-13.904-0.377c-0.07-2.109-0.196-4.216-0.463-6.328
                  C24.639,14.988,25.798,14.964,26.851,14.935z M31.164,31.393c-2.391-0.295-4.788-0.365-7.178-0.344
                  c-0.117,0.002-0.233,0.007-0.35,0.01c0.323-2.574,0.396-5.157,0.372-7.732c5.168-0.039,10.335-0.207,15.502-0.503
                  c0.615,0.45,1.146,0.839,1.547,1.132c-0.879,0.648-2.172,1.601-3.497,2.577C34.605,25.844,31.401,28.323,31.164,31.393z
                   M34.146,34.758c-0.733-0.775-1.063-1.721-1.087-2.669l1.222-0.022c-0.097,3.985,5.674,3.875,5.399-0.099l1.848-0.034
                  C41.43,35.499,36.497,37.536,34.146,34.758z"
                    ></path>
                  </svg>
                  <div className="navigation__card__title">При ДТП</div>
                  <div className="navigation__card__text">
                    куда звонить, что
                    <br /> делать + протокол
                  </div>
                </div>

                <div className="navigation__card navigation__card--green">
                  <svg
                    className="navigation__card-icon icon icon--style-vars"
                    style={
                      {
                        '--fill': 'rgba(15, 247, 81, 1)',
                        '--width': '45px',
                        '--height': '45px',
                        '--width2': '55px',
                        '--height2': '55px',
                      } as React.CSSProperties
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    viewBox="0 0 500 500"
                  >
                    <path d="M134.4 317.3c-11.8 0-21.4 9.6-21.4 21.4 0 11.8 9.6 21.4 21.4 21.4s21.4-9.6 21.4-21.4C155.8 326.9 146.2 317.3 134.4 317.3zM134.4 343.4c-2.6 0-4.6-2.1-4.6-4.6 0-2.6 2.1-4.6 4.6-4.6 2.6 0 4.6 2.1 4.6 4.6C139 341.3 137 343.4 134.4 343.4zM342.7 316.5c-12.2 0-22.2 10-22.2 22.2 0 12.3 10 22.2 22.2 22.2 12.3 0 22.2-10 22.2-22.2C365 326.5 355 316.5 342.7 316.5zM342.7 344.2c-3 0-5.5-2.4-5.5-5.5 0-3 2.4-5.5 5.5-5.5 3 0 5.5 2.4 5.5 5.5C348.2 341.7 345.7 344.2 342.7 344.2z"></path>
                    <path
                      d="M412.3,144.9c-4.7-8.1-13.4-13.1-22.8-13.1h-67.2c-14.5,0-26.3,11.8-26.3,26.3v63.8c0,6.5-5.3,11.9-11.9,11.9h-137
              c-7,0-13.7,2-19.6,5.6l-84.2,45.3l-0.5,0.3c-5.6,3.6-8.9,9.7-8.8,16.4l0.3,25.2c0.1,10.4,8.7,18.9,19.1,18.9h25.4
              c3.3,27.8,27,49.4,55.7,49.4c28.1,0,51.4-20.8,55.5-47.7h97.3c4.1,27,27.4,47.7,55.5,47.7c29.7,0,54.1-23.2,56-52.4h39.1
              c15.6,0,28.3-12.7,28.3-28.3v-68.8c0-5-1.3-9.9-3.8-14.2L412.3,144.9z M134.4,378.1c-21.7,0-39.4-17.7-39.4-39.4
              c0-21.7,17.7-39.4,39.4-39.4s39.4,17.7,39.4,39.4C173.8,360.4,156.1,378.1,134.4,378.1z M342.7,378.1c-21.7,0-39.4-17.7-39.4-39.4
              c0-21.7,17.7-39.4,39.4-39.4s39.4,17.7,39.4,39.4C382.1,360.4,364.4,378.1,342.7,378.1z M449.4,314.1c0,6.4-5.2,11.5-11.5,11.5
              h-40.6c-5.9-24.7-28.1-43-54.6-43c-28.1,0-51.4,20.8-55.5,47.7h-97.3c-4.1-27-27.4-47.7-55.5-47.7c-27.5,0-50.5,19.9-55.2,46.1
              H53.3c-1.3,0-2.4-1.1-2.4-2.4l-0.3-25.2c0-0.8,0.3-1.5,0.9-1.9l84.1-45.2l0.5-0.3c3.3-2.1,7-3.2,10.9-3.2h137
              c15.8,0,28.6-12.8,28.6-28.6v-63.8c0-5.3,4.3-9.6,9.6-9.6h67.2c3.4,0,6.6,1.8,8.3,4.8l50,86.2c1,1.8,1.6,3.8,1.6,5.8V314.1z"
                    ></path>
                    <path d="M390.2 165.5l-.4-.6c-3.1-4.8-8.3-7.6-13.7-7.6 0 0-.1 0-.1 0l-33.7-.2c-8.2.1-14.8 7.1-14.9 15.6l-.8 58.9c0 8.7 6.7 15.7 15 15.7h62.8c8.2 0 14.9-7 14.9-15.7v-7.6c0-3.1-.8-6-2.4-8.6L390.2 165.5zM343.5 230.5l.7-56.8 31.5.2 26.8 49.9.1 6.6H343.5zM51.8 105.6c-4-1.1-8.2-.2-11.6 2.3-3.4 2.6-5.3 6.5-5.3 10.7v14.9c0 5.4 3.2 10.3 8.2 12.4l214.3 78.7c1.6.7 3.3 1 5.1 1h14.4c7.4 0 13.5-6.1 13.5-13.5v-39.8c0-6.1-4.1-11.4-9.9-13L51.8 105.6zM273.6 208.8H263L51.6 131.2v-8.5l222 52.1V208.8z"></path>
                  </svg>
                  <div className="navigation__card__title">Помощь на дороге</div>
                  <div className="navigation__card__text">
                    вызвать эвакуатор,
                    <br /> шиномонтаж и др
                  </div>
                </div>

                <div className="navigation-break"></div>

                <div className="navigation__card navigation__card--blue navigation__card--big">
                  <div className="navigation__card-logo">
                    <svg
                      className="navigation__card-icon navigation__card-icon--static icon icon--style-vars"
                      style={
                        {
                          '--fill': 'rgba(30, 136, 229, 1)',
                          '--width': '45px',
                          '--height': '45px',
                          '--width2': '55px',
                          '--height2': '55px',
                        } as React.CSSProperties
                      }
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 80 80"
                    >
                      <path d="M75 32.548c0-3.916-3.186-7.101-7.1-7.101H50.061c1.311-4.285 2.868-12.455-2.189-17.435-1.526-1.502-3.468-2.352-5.469-2.394-2.016-.048-3.773.675-5.082 2.074a4.136 4.136 0 0 0-1.108 2.838v.711c0 7.31-3.331 14.08-8.91 18.107a21.165 21.165 0 0 1-5.381 2.812 3.366 3.366 0 0 0-3.353-3.188H8.371A3.375 3.375 0 0 0 5 32.342v38.67a3.375 3.375 0 0 0 3.371 3.371h10.2a3.375 3.375 0 0 0 3.37-3.37V69.64c5.945 1.004 12.078 1.509 18.369 1.509 7.033 0 14.262-.628 21.636-1.884a6.902 6.902 0 0 0 6.71-6.891c0-1.155-.289-2.243-.793-3.2 2.877-.88 4.977-3.56 4.977-6.721 0-1.48-.462-2.85-1.245-3.984A6.885 6.885 0 0 0 75 42.537c0-1.942-.81-3.694-2.107-4.948A7.078 7.078 0 0 0 75 32.548zM17.941 70.383H9V32.972h8.941v37.411zM68.112 39.65c1.592 0 2.888 1.295 2.888 2.888s-1.296 2.887-2.888 2.887H56.277a2 2 0 0 0 0 4h9.535a3.031 3.031 0 0 1 3.028 3.029 3.031 3.031 0 0 1-3.028 3.027H58.865a2 2 0 0 0 0 4h2.898a2.896 2.896 0 0 1 2.893 2.894 2.897 2.897 0 0 1-2.893 2.893c-.114 0-.227.01-.338.029-13.827 2.371-27.101 2.46-39.484.28V36.361a25.032 25.032 0 0 0 7.705-3.77c6.617-4.777 10.568-12.758 10.568-21.35v-.711a.18.18 0 0 1 .03-.105c.525-.562 1.212-.83 2.077-.808.976.02 1.95.463 2.745 1.246 4.918 4.842.49 15.397.33 15.769-.1.222-.156.465-.168.72 0 .026-.007.052-.008.077l-.001.018c0 .068.013.133.02.2.006.06.007.12.018.177.013.07.037.136.057.203.018.058.031.117.054.172.022.056.053.106.08.159.033.064.063.13.103.19.023.035.052.065.077.098.053.072.107.143.17.207.018.018.04.033.058.05.076.072.154.14.242.2.01.007.02.01.03.017.092.06.185.118.289.163.276.122.564.17.847.164H67.9c1.71 0 3.101 1.39 3.101 3.1s-1.39 3.102-3.1 3.102H54.982a2 2 0 0 0 0 4h13.129z"></path>
                    </svg>
                    <div className="navigation__card__title">
                      Полезные
                      <br /> сервисы
                    </div>
                  </div>
                  <div className="navigation__card__text">
                    проверить авто на угон,
                    <br /> проверить авто на залог,
                    <br /> получить отчет по VIN,
                    <br />
                    проверить КБМ,
                    <br /> расшифровка значков панели и др.
                  </div>
                </div>
              </div>
            </>
          )}
        </main>
      </Layout>

      <MessageDialogModal
        isShowing={messageDialogModalIsShowing}
        text={messageDialogModalText}
        onClose={() => {
          setMessageDialogModalText('');
          setMessageDialogModalIsShowing(false);
        }}
      />

      <Modal isShowing={modalCarListIsShowing} onClose={() => setModalCarListIsShowing(false)}>
        <CarList onClose={() => setModalCarListIsShowing(false)} />
      </Modal>

      <QuestionDialogModal
        isShowing={isShowing}
        text={text}
        yesButtonText={yesButtonText}
        noButtonText={noButtonText}
        onClickYes={onClickYes}
        onClickNo={onClickNo}
      />
    </>
  );
};
