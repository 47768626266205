import './Footer.scss';

export const Footer = () => {
  const now = Date.now();
  const date = new Date(now);
  const currentYear = date.getFullYear();
  return (
    <footer className="footer">
      <ul className="footer__menu">
        <li>
          <a href="/guide-categories">Гайд по сайту</a>
        </li>
        <li>
          <a href="/ideas">Ваши идеи</a>
        </li>
        <li>
          <a href="/privacy">Политика конфиденциальности</a>
        </li>
        <li>
          <a href="/contacts">Контакты</a>
        </li>
      </ul>
      <div className="footer__copyright">
        <span className="footer__image"></span>
        <span className="footer__text">
          © {currentYear}. VGarage - все услуги и сервисы для авто в одном месте
        </span>
      </div>
    </footer>
  );
};
