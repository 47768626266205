import { IsNotEmpty } from 'class-validator';

export enum NotificationState {
  NOT_READ = 'NOT_READ',
  READ = 'READ',
}

export class NotificationFormSchema {
  @IsNotEmpty()
  title: string;

  @IsNotEmpty()
  text: string;

  @IsNotEmpty()
  sentAt: string;
}
