import React, { useEffect, useState } from 'react';
import { Layout } from '../components/Layout/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from '../components/Spinner/Spinner';
import { useAccessToken } from '../hooks/useAccessToken';
import { useFetch } from '../hooks/useFetch';
import { CarEntity, carControllerDeleteCar, carControllerReadCar } from '../clients/VgarageApi';
import { MaintenanceList } from '../components/MaintenanceList/MaintenanceList';
import { Modal } from '../components/Modal/Modal';
import { CarList } from '../components/CarList/CarList';
import { QuestionDialogModal, useQuestionDialog } from '../components/Modal/QuestionDialogModal';

export const CarMaintenanceListPage = () => {
  const navigate = useNavigate();

  const { accessToken } = useAccessToken();

  const { id: carId } = useParams();

  const [fetchCar, isFetchingCar, carError, car] = useFetch<CarEntity | null>(async () => {
    if (carId) {
      const result = await carControllerReadCar({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        path: {
          id: parseInt(carId),
        },
      });

      return result.data!;
    }

    return null;
  });

  const onRemove = async () => {
    const result = await carControllerDeleteCar({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      path: {
        id: car!.id,
      },
    });
  };

  const {
    isShowing,
    text,
    yesButtonText,
    noButtonText,
    onClickYes,
    onClickNo,
    showDialog,
    hideDialog,
  } = useQuestionDialog();

  useEffect(() => {
    fetchCar();
  }, [accessToken, carId]);

  // ---

  const [modalCarListIsShowing, setModalCarListIsShowing] = useState(false);

  return (
    <>
      <Layout>
        <main className="main">
          <ul className="breadcrumbs">
            <li onClick={() => setModalCarListIsShowing(true)} className="link">
              Мои авто
            </li>
            <li>ТО / Сервис</li>
          </ul>

          {isFetchingCar && <Spinner />}

          {car && (
            <div className="car-info">
              <div className="car-info__title">
                <span className="car-info__title-text">
                  {car.brand.name} {car.model.name}
                </span>
                <button
                  onClick={() => {
                    showDialog({
                      text: 'Удалить авто?',
                      yesButtonText: 'ДА',
                      noButtonText: 'НЕТ',
                      onClickYes: () => {
                        onRemove();
                        navigate('/?messageText=Автомобиль%20удален');
                      },
                      onClickNo: () => hideDialog(),
                    });
                  }}
                  className="car-info__title-close"
                >
                  <svg
                    className="car-info__title-close-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
                  </svg>
                </button>
              </div>

              <div className="car-info__menu">
                <button
                  onClick={() => navigate(`/my-auto/${car.id}`)}
                  className="car-info__menu-button car-info__menu-button--parameters"
                >
                  Параметры автомобиля
                </button>
                <button className="car-info__menu-button car-info__menu-button--maintenances car-info__menu-button--active">
                  ТО / Сервис
                </button>
                <button
                  onClick={() => navigate(`/my-auto/${car.id}/documents`)}
                  className="car-info__menu-button car-info__menu-button--documents"
                >
                  Документы
                </button>
                <button
                  onClick={() => navigate(`/my-auto/${car.id}/reminds`)}
                  className="car-info__menu-button car-info__menu-button--reminders"
                >
                  Напоминания
                </button>
              </div>

              <div className="car-info__content">
                <div className="car-info__content-item car-info__content-item--wide car-maintenance-list list box">
                  <MaintenanceList carId={car.id} />
                </div>
              </div>
            </div>
          )}
        </main>
      </Layout>

      <Modal isShowing={modalCarListIsShowing} onClose={() => setModalCarListIsShowing(false)}>
        <CarList onClose={() => setModalCarListIsShowing(false)} />
      </Modal>

      <QuestionDialogModal
        isShowing={isShowing}
        text={text}
        yesButtonText={yesButtonText}
        noButtonText={noButtonText}
        onClickYes={onClickYes}
        onClickNo={onClickNo}
      />
    </>
  );
};
