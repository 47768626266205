import { IsEmail, IsNotEmpty, MinLength } from 'class-validator';

export class LoginFormSchema {
  @IsEmail()
  @MinLength(6, { message: 'Укажите email для входа в аккаунт' })
  email: string;

  @IsNotEmpty()
  @MinLength(3, { message: 'Укажите пароль для входа в аккаунт' })
  password: string;
}
