import { IsArray, IsBoolean, IsNotEmpty, IsNumber, IsOptional, Max, Min } from 'class-validator';

export class PtsFormSchema {
  @IsNotEmpty({ message: 'Выберите авто' })
  @IsNumber({}, { message: 'Выберите авто' })
  @Max(2147483647)
  @Min(0, { message: 'Выберите авто' })
  carId: number;

  @IsOptional()
  @IsNotEmpty()
  number: string | null;

  @IsOptional()
  @IsNotEmpty()
  notes: string | null;

  @IsOptional()
  @IsArray()
  fileUrls: string[];

  @IsOptional()
  @IsBoolean()
  createRemind: boolean | null;
}
