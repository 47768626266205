import React from 'react';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';

import './Layout.scss';

interface LayoutProps {
  showMenu?: boolean;
  children: React.ReactNode;
}

export const Layout = ({ showMenu = true, children }: LayoutProps) => {
  return (
    <div className="layout">
      <div className={`layout__header ${!showMenu ? 'layout__header--big' : ''}`}>
        <Header showMenu={showMenu} />
      </div>

      <div className="layout__main">{children}</div>

      <div className="layout__footer">
        <Footer />
      </div>
    </div>
  );
};
