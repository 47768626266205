import React, { useEffect, useState } from 'react';
import { Layout } from '../components/Layout/Layout';
import { useFetch } from '../hooks/useFetch';
import { useAccessToken } from '../hooks/useAccessToken';
import { UserFormSchema } from '../components/forms/schemas/UserFormSchema';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { cleanObjectByKeys } from '../utils/cleanObject';
import {
  UpdateUserDto,
  UserEntity,
  userControllerDelete,
  userControllerGetCurrentUser,
  userControllerUpdateUser,
} from '../clients/VgarageApi';

import '../styles/user-profile.scss';
import { QuestionDialogModal, useQuestionDialog } from '../components/Modal/QuestionDialogModal';
import { useNavigate } from 'react-router-dom';

export const UserProfilePage = () => {
  const navigate = useNavigate();
  const { setAccessToken, accessToken } = useAccessToken();
  const [fetchUser, isFetchingUser, userError, user] = useFetch<UserEntity | null>(async () => {
    const result = await userControllerGetCurrentUser({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    console.log(result.data);

    return result.data || null;
  });

  useEffect(() => {
    fetchUser();
  }, [accessToken]);

  // ---

  const [formHintMessage, setFormHintMessage] = useState('');
  const [formHintClass, setFormHintClass] = useState('');

  const formResolver = classValidatorResolver(UserFormSchema);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<UserFormSchema>({
    resolver: formResolver,
    defaultValues: user ? cleanObjectByKeys(user, ['id', 'city', 'state']) : undefined,
  });

  const onValid: SubmitHandler<UserFormSchema> = async (data) => {
    console.log(data);

    setFormHintMessage('');

    const result = await userControllerUpdateUser({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      path: {
        id: user!.id,
      },
      body: data as UpdateUserDto,
    });

    console.log(result);

    if (result.response.status === 200) {
      setFormHintClass('form__hint--green');
      setFormHintMessage('Данные успешно обновлены');
    } else {
      setFormHintClass('form__hint--red');
      setFormHintMessage(`Ошибка при обновлении данных`);
    }
  };

  useEffect(() => {
    reset(
      user
        ? ({
            ...cleanObjectByKeys(user, ['id', 'city', 'state']),
            cityId: user.city?.id || undefined,
            password: '',
          } as UserFormSchema)
        : undefined,
    );
  }, [user]);

  const onInvalid: SubmitErrorHandler<UserFormSchema> = (error) => {
    console.log(error);

    setFormHintClass('form__hint--red');
  };

  // ---

  const handleLogout = () => {
    setAccessToken(null);
  };

  // ---

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const {
    isShowing,
    text,
    yesButtonText,
    noButtonText,
    onClickYes,
    onClickNo,
    showDialog,
    hideDialog,
  } = useQuestionDialog();

  const onDeleteAccount = async () => {
    const result = await userControllerDelete({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      path: {
        id: user!.id,
      },
    });
  };

  return (
    <Layout>
      <div className="car-info">
        <div className="car-info__title">
          <span className="car-info__title-text">Мой профиль</span>
        </div>
      </div>
      <hr className="separator separator--empty" />
      <div className="main__user-profile user-profile box">
        <div className="user-profile__header">
          <span className="user-profile__rating"></span>
          <span className="user-profile__id">{user?.id}</span>
        </div>
        <form onSubmit={handleSubmit(onValid, onInvalid)} className="user-profile__form form">
          <div className="form__item form__item--wide">
            <div className="form__item-label form__item-label--wide">
              <label>Имя:</label>
            </div>
            <div className="form__item-element form__item-element--wide">
              <input type="text" {...register('name')} />
            </div>
          </div>

          <div className="form__item form__item--wide">
            <div className="form__item-label form__item-label--wide">
              <label>Электронная почта:</label>
            </div>
            <div
              style={{ flexDirection: 'column' }}
              className="form__item-element form__item-element--wide"
            >
              <input type="email" {...register('email')} />
              <div
                className={`form__hint ${
                  user?.emailState === 'NOT_VALIDATE' ? 'form__hint--red' : 'form__hint--green'
                }`}
                style={{ fontSize: '12px', textAlign: 'left', margin: '3px 0 0 10px' }}
              >
                {user?.emailState === 'NOT_VALIDATE'
                  ? 'Email не подтвержден, перейдите по ссылке в письме для подтверждения'
                  : 'Email подтвержден'}
              </div>
            </div>
          </div>

          <div className="form__item form__item--wide">
            <div className="form__item-label form__item-label--wide">
              <label htmlFor="car-name">Номер телефона:</label>
            </div>
            <div className="form__item-element form__item-element--wide">
              <input
                type="text"
                {...register('phone', {
                  setValueAs: (value) => value || null,
                })}
              />
            </div>
          </div>

          <div className="form__item form__item--wide">
            <div className="form__item-label form__item-label--wide">
              <label htmlFor="car-name">Новый пароль:</label>
            </div>
            <div className="form__item-element form__item-element--wide">
              <input
                type={passwordShown ? 'text' : 'password'}
                {...register('password', {
                  setValueAs: (value) => value || undefined,
                })}
              />
              <button type="button" onClick={togglePasswordVisiblity} className="form__item-button">
                <svg
                  className="form__item-button-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  width="512"
                  height="512"
                  viewBox="0 0 512 512"
                >
                  <g id="XMLID_625_">
                    <g id="XMLID_567_">
                      <path
                        id="XMLID_105_"
                        d="M256,406c-30.6,0-62.2-7.6-94-22.5c-24.9-11.7-50-27.9-74.6-48.2C45.9,301,20.3,267,19.2,265.6
                                    c-4.3-5.7-4.3-13.5,0-19.2c1.1-1.4,26.7-35.4,68.2-69.7c24.6-20.3,49.7-36.5,74.6-48.2c31.8-14.9,63.4-22.5,94-22.5
                                    s62.2,7.6,94,22.5c24.9,11.7,50,27.9,74.6,48.2c41.5,34.3,67.2,68.3,68.2,69.7c4.3,5.7,4.3,13.5,0,19.2
                                    c-1.1,1.4-26.7,35.4-68.2,69.7c-24.6,20.3-49.7,36.5-74.6,48.2C318.2,398.4,286.6,406,256,406z M52.6,256
                                    c25.1,29.7,108,118,203.4,118c95.6,0,178.3-88.3,203.4-118c-25.1-29.7-108-118-203.4-118C160.4,138,77.7,226.3,52.6,256z"
                      ></path>
                    </g>
                    <g id="XMLID_568_">
                      <path
                        id="XMLID_102_"
                        d="M256,328c-39.7,0-72-32.3-72-72s32.3-72,72-72s72,32.3,72,72S295.7,328,256,328z M256,216
                                    c-22.1,0-40,17.9-40,40s17.9,40,40,40s40-17.9,40-40S278.1,216,256,216z"
                      ></path>
                    </g>
                  </g>
                </svg>
              </button>
            </div>
          </div>

          <div className="form__item form__item--wide">
            <div className="form__item-label form__item-label--wide"></div>
            <div className="form__item-element form__item-element--wide">
              <button type="submit" className="form__button button button--fit button--primary">
                Сохранить
              </button>
            </div>
          </div>

          <div style={{ marginTop: '30px' }} className="form__item form__item--wide">
            <div className="form__item-label form__item-label--wide"></div>
            <div className="form__item-element form__item-element--wide">
              <button
                onClick={handleLogout}
                type="button"
                className="button button--fit-all button--light-red"
              >
                Выйти
              </button>
              <button
                style={{ paddingLeft: 100 }}
                onClick={() => {
                  showDialog({
                    text: 'Удалить аккаунт?',
                    yesButtonText: 'ДА',
                    noButtonText: 'НЕТ',
                    onClickYes: () => {
                      onDeleteAccount();
                      handleLogout();
                    },
                    onClickNo: () => hideDialog(),
                  });
                }}
                type="button"
                className="button button--fit-all button--light-red"
              >
                Удалить аккаунт
              </button>
            </div>
          </div>

          <div className={`form__hint ${formHintClass}`}>
            {formHintMessage}
            {Object.entries(formErrors).map(([field, { message }]) => (
              <p key={field}>{message}</p>
            ))}
          </div>
        </form>
      </div>

      <QuestionDialogModal
        isShowing={isShowing}
        text={text}
        yesButtonText={yesButtonText}
        noButtonText={noButtonText}
        onClickYes={onClickYes}
        onClickNo={onClickNo}
      />
    </Layout>
  );
};
