import React, { useEffect, useRef, useState } from 'react';

import './FileLoader.scss';
import { fileControllerUpload } from '../../clients/VgarageApi';
import { useAccessToken } from '../../hooks/useAccessToken';

export interface FileLoaderProps {
  images?: string[] | null;
  onChange: (fileUrls: string[]) => void;
}

export const FileLoader = ({ images = null, onChange }: FileLoaderProps) => {
  console.log(images);

  const { accessToken } = useAccessToken();

  const [file, setFile] = useState<File | null>(null);
  const [uploadedFileUrls, setUploadedFileUrls] = useState<string[] | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);

  useEffect(() => {
    if (images?.length !== uploadedFileUrls?.length) setUploadedFileUrls(images);
  }, [images]);

  const handleUpload = async () => {
    if (!file) {
      setUploadError('Выберите файл для загрузки.');
      return;
    }

    try {
      const result = await fileControllerUpload({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: {
          file,
        },
      });

      console.log(result);

      if (result.response.status !== 201) {
        setUploadError(`Ошибка загрузки: ${JSON.stringify(result.data)}`);
        return;
      }

      setUploadedFileUrls([...(uploadedFileUrls || []), result.data![0]]);
      setFile(null);
    } catch (error) {
      setUploadError(`Ошибка загрузки. Попробуйте позже. ${JSON.stringify(error)}`);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setFile(file ?? null);
    setUploadError(null);
  };

  useEffect(() => {
    if (file) {
      handleUpload();
    }
  }, [file, handleUpload]);

  useEffect(() => {
    if (uploadedFileUrls !== null) onChange(uploadedFileUrls);
  }, [uploadedFileUrls, onChange]);

  return (
    <div className="file-loader">
      <div className="file-loader__list">
        {uploadedFileUrls?.map((fileUrl, idx) => (
          <div key={idx} className="file-loader__file">
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              <img src={fileUrl} alt="" />
            </a>
          </div>
        ))}

        <label className="file-loader__file">
          <input type="file" onChange={handleFileChange} accept="image/*" hidden />
          <svg
            className="file-loader-button-add-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <g data-name="Layer 2">
              <g data-name="plus-circle">
                <rect width="24" height="24" opacity="0"></rect>

                <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"></path>

                <path d="M15 11h-2V9a1 1 0 0 0-2 0v2H9a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2z"></path>
              </g>
            </g>
          </svg>
        </label>
      </div>

      <div className="file-loader__hint">(jpeg, jpg, png, pdf не более 10 Мб)</div>

      <span className="file-loader__hint--warning">{uploadError}</span>
    </div>
  );
};
