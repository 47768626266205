import { useCallback, useState } from 'react';

type UseFetchType<T> = [(params?: any) => Promise<void>, boolean, string | null, T | null];

export const useFetch = <T>(callback: (params?: any) => Promise<T>): UseFetchType<T> => {
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<T | null>(null);

  const fetch = useCallback(
    async (params?: any) => {
      try {
        setIsFetching(true);
        setError(null);
        setData(null);
        const result = await callback(params);
        setData(result);
      } catch (e: unknown) {
        setError((e as Error).message);
      } finally {
        setIsFetching(false);
      }
    },
    [callback],
  );

  return [fetch, isFetching, error, data];
};
