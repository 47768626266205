import React from 'react';
import { RegistrationPage } from '../pages/RegistrationPage';
import { LoginPage } from '../pages/LoginPage';
import { DemoPage } from '../pages/DemoPage';
import { CarPage } from '../pages/CarPage';
import { RouteType } from '../types/RouteType';
import { CarMaintenanceListPage } from '../pages/CarMaintenanceListPage';
import { CarDocumentListPage } from '../pages/CarDocumentListPage';
import { CarRemindListPage } from '../pages/CarRemindListPage';
import { MainPage } from '../pages/MainPage';
import { UserProfilePage } from '../pages/UserProfilePage';
import { UserResetPasswordPage } from '../pages/UserResetPasswordPage';
import { RemindListPage } from '../pages/RemindListPage';
import { MaintenanceListPage } from '../pages/MaintenanceListPage';
import { EquipmentListPage } from '../pages/EquipmentListPage';
import { DocumentListPage } from '../pages/DocumentListPage';
import { ContactsPage } from '../pages/ContactsPage';
import { PrivacyPage } from '../pages/PrivacyPage';
import { IdeasPage } from '../pages/IdeasPage';
import { GuidePage } from '../pages/GuidePage';
import { GuideListPage } from '../pages/GuideListPage';
import { NotificationListPage } from '../pages/NotificationListPage';
import { ConsumablesListPage } from '../pages/ConsumablesListPage';

export const publicRoutes: RouteType[] = [
  { path: '/', element: <MainPage /> },

  { path: '/my-auto/:id', element: <CarPage /> },
  { path: '/my-auto/:id/maintenances', element: <CarMaintenanceListPage /> },
  { path: '/my-auto/:id/documents', element: <CarDocumentListPage /> },
  { path: '/my-auto/:id/reminds', element: <CarRemindListPage /> },

  { path: '/equipments', element: <EquipmentListPage /> },
  { path: '/maintenances', element: <MaintenanceListPage /> },
  { path: '/consumables', element: <ConsumablesListPage /> },
  { path: '/documents', element: <DocumentListPage /> },
  { path: '/reminds', element: <RemindListPage /> },
  { path: '/notifications', element: <NotificationListPage /> },

  { path: '/registration', element: <RegistrationPage /> },
  { path: '/login', element: <LoginPage /> },
  { path: '/user-profile', element: <UserProfilePage /> },
  { path: '/user-reset-password', element: <UserResetPasswordPage /> },
  { path: '/user-reset-password/:hash', element: <UserResetPasswordPage /> },

  { path: '/contacts', element: <ContactsPage /> },
  { path: '/privacy', element: <PrivacyPage /> },
  { path: '/ideas', element: <IdeasPage /> },

  { path: '/guide-categories/:categoryId?', element: <GuideListPage /> },
  { path: '/guide/:id', element: <GuidePage /> },

  { path: '/demo', element: <DemoPage /> },
];
