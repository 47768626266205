import React, { useState } from 'react';
import { MessageDialogModal } from '../Modal/MessageDialogModal';

import '../Modal/Modal.scss';
import '../../styles/box.scss';
import './Hint.scss';

interface HintProps {
  title?: React.ReactNode;
  text: React.ReactNode;
}

export const Hint = ({ title = '', text }: HintProps) => {
  const [messageDialogModalIsShowing, setMessageDialogModalIsShowing] = useState(false);

  return (
    <>
      <svg
        onClick={() => setMessageDialogModalIsShowing(true)}
        className="hint__icon icon icon--style-vars"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
      >
        <path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2Zm0,26A12,12,0,1,1,28,16,12,12,0,0,1,16,28Zm5-15a4.59,4.59,0,0,1-1.51,3.49C18.6,17.33,17,18.85,17,21a1,1,0,0,1-2,0c0-3,2.08-5,3.08-5.93A2.6,2.6,0,0,0,19,13a3,3,0,0,0-5.58-1.54,1,1,0,1,1-1.71-1A5,5,0,0,1,21,13ZM16.71,23.29A1.05,1.05,0,0,1,17,24a1,1,0,0,1-.08.38,1.15,1.15,0,0,1-.21.33,1,1,0,0,1-1.42,0,1.15,1.15,0,0,1-.21-.33A1,1,0,0,1,15,24a1.05,1.05,0,0,1,.29-.71A1,1,0,0,1,16.2,23l.18.06a.76.76,0,0,1,.18.09A1.58,1.58,0,0,1,16.71,23.29Z"></path>
      </svg>

      <MessageDialogModal
        isShowing={messageDialogModalIsShowing}
        onClose={() => setMessageDialogModalIsShowing(false)}
        text={text}
        showIcon={false}
      />
    </>
  );
};
