import { useState } from 'react';
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { IsNotEmpty, IsNumber } from 'class-validator';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Select, SelectOption } from '../components/Select/Select';
import { DatePicker } from '../components/DatePicker/DatePicker';

import '../styles/separator.scss';
import '../styles/main.scss';
import '../styles/container.scss';

class DemoFormSchema {
  @IsNotEmpty({ message: 'Название автомобиля не может быть пустым' })
  name: string;

  @IsNumber()
  @IsNotEmpty({ message: 'Модель автомобиля обязательна' })
  brand: number;
}

export const DemoPage = () => {
  const cities: SelectOption<number>[] = [
    {
      value: 1,
      text: 'Москва, Крылова, 38, литера Б, корпус 12',
      subtexts: ['часы работы: 10.00 - 19.00', 'телефон: +7 913 913 9130'],
      childrens: [
        {
          value: 11,
          text: 'Москва, Крылова, 38, литера Б, корпус 12',
          subtexts: ['часы работы: 10.00 - 19.00', 'телефон: +7 913 913 9130'],
        },
        {
          value: 12,
          text: 'Москва, Крылова, 38, литера Б, корпус 12',
          subtexts: ['часы работы: 10.00 - 19.00', 'телефон: +7 913 913 9130'],
          childrens: [
            {
              value: 121,
              text: 'Москва, Крылова, 38, литера Б, корпус 12',
              subtexts: ['часы работы: 10.00 - 19.00', 'телефон: +7 913 913 9130'],
            },
            {
              value: 122,
              text: 'Москва, Крылова, 38, литера Б, корпус 12',
              subtexts: ['часы работы: 10.00 - 19.00', 'телефон: +7 913 913 9130'],
            },
          ],
        },
        {
          value: 13,
          text: 'Москва, Крылова, 38, литера Б, корпус 12',
          subtexts: ['часы работы: 10.00 - 19.00', 'телефон: +7 913 913 9130'],
        },
      ],
    },
    {
      value: 2,
      text: 'Санкт-Петербург, Крылова, 38, литера Б, корпус 12',
      subtexts: ['часы работы: 10.00 - 19.00', 'телефон: +7 913 913 9130'],
    },
    {
      value: 3,
      text: 'Краснодар, Крылова, 38, литера Б, корпус 12',
      subtexts: ['часы работы: 10.00 - 19.00', 'телефон: +7 913 913 9130'],
    },
    {
      value: 4,
      text: 'Екатеринбург, Крылова, 38, литера Б, корпус 12',
      subtexts: ['часы работы: 10.00 - 19.00', 'телефон: +7 913 913 9130'],
    },
    {
      value: 5,
      text: 'Новосибирск, Крылова, 38, литера Б, корпус 12',
      subtexts: ['часы работы: 10.00 - 19.00', 'телефон: +7 913 913 9130'],
    },
    {
      value: 6,
      text: 'Красноярск, Крылова, 38, литера Б, корпус 12',
      subtexts: ['часы работы: 10.00 - 19.00', 'телефон: +7 913 913 9130'],
    },
    {
      value: 7,
      text: 'Хабаровск, Крылова, 38, литера Б, корпус 12',
      subtexts: ['часы работы: 10.00 - 19.00', 'телефон: +7 913 913 9130'],
    },
    {
      value: 8,
      text: 'Владивосток, Крылова, 38, литера Б, корпус 12',
      subtexts: ['часы работы: 10.00 - 19.00', 'телефон: +7 913 913 9130'],
    },
  ];

  const [defaultValue, setDefaultValue] = useState([1, 5]);

  const [singleSelectValue, setSingleSelectValue] = useState<null | number | number[]>(
    defaultValue[1],
  );
  const [multipleSelectValue, setMultipleSelectValue] = useState<null | number | number[]>(
    defaultValue,
  );

  // ---

  const [formHintMessage, setFormHintMessage] = useState('');
  const [formHintClass, setFormHintClass] = useState('');

  const formResolver = classValidatorResolver(DemoFormSchema);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<DemoFormSchema>({
    resolver: formResolver,
  });

  const onValid: SubmitHandler<DemoFormSchema> = async (data) => {
    console.log(data);
  };

  const onInvalid: SubmitErrorHandler<DemoFormSchema> = (error) => {
    console.log(error);
  };

  // ---

  const [listValue, setListValue] = useState<number[]>([]);

  // ---

  const [date, setDate] = useState<string>('2024-12-12');

  return (
    <div className="container container--xl container--centered">
      <main className="main main--padded main--gaps-static">
        <div className="main__title">Selects</div>
        <div className="main__selects">
          Single: {singleSelectValue}
          <Select options={cities} defaultValue={defaultValue[1]} onChange={setSingleSelectValue} />
          Multiple: {(multipleSelectValue as number[]).join(', ')}
          <Select
            options={cities}
            defaultValue={defaultValue}
            isMultiple={true}
            onChange={setMultipleSelectValue}
          />
          <label>
            Default value:
            <input
              type="text"
              placeholder="number"
              // value={defaultValue.join(",")}
              onChange={(e) => setDefaultValue([parseInt(e.target.value)])}
            />
          </label>
        </div>

        <div className="main__title">Selects with Hook Form</div>
        <div className="main__selects">
          <form
            className="form"
            onSubmit={handleSubmit(onValid, onInvalid)}
            style={{ width: '100%' }}
          >
            <div className="form__item form__item--required">
              <label htmlFor="car-name">Name</label>
              <div className="form__item-element">
                <input {...register('name')} />
              </div>
            </div>

            <div className="form__item form__item--required">
              <label htmlFor="car-name">Brand</label>
              <div className="form__item-element">
                <Controller
                  name="brand"
                  control={control}
                  render={({ field }) => <Select options={cities} onChange={field.onChange} />}
                />
              </div>
            </div>

            <button>Submit</button>

            <div className={`form__hint ${formHintClass}`}>
              {formHintMessage}
              {Object.entries(formErrors).map(([field, { message }]) => (
                <p key={field}>{message}</p>
              ))}
            </div>
          </form>
        </div>
        <div className="main__title">Lists</div>
        <div className="main__lists">List value: {listValue}</div>

        <div className="main__title">DatePicker</div>
        <div className="main__selects">
          <div className="form">
            <div className="form__item">
              <label htmlFor="car-name">Укажите дату</label>
              <div className="form__item-element">
                <input style={{ width: '100%' }} className="date-picker" type="date" />
              </div>

              <hr className="separator separator--empty" />

              <label htmlFor="car-name">Укажите дату</label>
              <div className="form__item-element">
                <DatePicker date={date} onChange={(date: string) => console.log(date)} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};
