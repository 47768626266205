import React, { useEffect, useMemo, useState } from 'react';
import { useAccessToken } from '../../hooks/useAccessToken';
import { useFetch } from '../../hooks/useFetch';
import {
  CascoDocumentEntity,
  DocumentEntity,
  DriverLicenseEntity,
  EptsDocumentEntity,
  OsagoDocumentEntity,
  OtherDocumentEntity,
  PtsDocumentEntity,
  StsDocumentEntity,
  documentControllerSearchDocs,
} from '../../clients/VgarageApi';
import { DocumentType, getDocumentTypeName } from '../../types/DocumentType';
import { renderIf } from '../../utils/renderIf';
import { FormModal } from '../Modal/FormModal';
import { DocumentForm, onCloseDocumentFormOptions } from '../forms/DocumentForm';
import { RemindForm } from '../forms/RemindForm';
import { MessageDialogModal } from '../Modal/MessageDialogModal';

interface DocumentListProps {
  carId?: number | null;
}

export const DocumentList = ({ carId = null }: DocumentListProps) => {
  const { accessToken } = useAccessToken({ required: false });

  const [fetchDocuments, isFetchingDocuments, documentsError, documents] = useFetch<
    DocumentEntity[] | null
  >(async () => {
    const result = await documentControllerSearchDocs({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      query: {
        carId,
      },
    });

    console.log(result.data);

    return result.data?.data || [];
  });

  useEffect(() => {
    if (accessToken) {
      fetchDocuments();
    }
  }, [accessToken, carId]);

  // ---

  const [documentForUpdate, setDocumentForUpdate] = useState<DocumentEntity | null>(null);
  const [documentFormModalIsShowing, setDocumentFormModalIsShowing] = useState(false);

  const onCloseDocumentForm = (options?: onCloseDocumentFormOptions) => {
    setDocumentFormModalIsShowing(false);
    setDocumentForUpdate(null);
    fetchDocuments();

    if (options?.text) {
      setMessageDialogModalText(options.text);
      setMessageDialogModalIsShowing(true);
    }

    if (options?.createRemind && options?.document) {
      setDocumentForUpdate(options?.document);
      setRemindFormModalIsShowing(true);
    }
  };

  const documentFormDefaultValues = useMemo<{ type?: DocumentType; carId: number | undefined }>(
    () => ({ carId: carId || undefined }),
    [carId],
  );

  //
  const remindFormDefaultValues = useMemo<{ documentId: number | undefined }>(
    () => ({ documentId: documentForUpdate?.id }),
    [documentForUpdate],
  );

  // ---

  const [remindFormModalIsShowing, setRemindFormModalIsShowing] = useState(false);

  const onCloseRemindForm = (text?: string) => {
    setRemindFormModalIsShowing(false);
    fetchDocuments();

    if (text) {
      setMessageDialogModalText(text);
      setMessageDialogModalIsShowing(true);
    }
  };
  // ---

  const [messageDialogModalIsShowing, setMessageDialogModalIsShowing] = useState(false);
  const [messageDialogModalText, setMessageDialogModalText] = useState('');

  return (
    <>
      <div className="list">
        <div className="list__menu">
          <button
            onClick={() => setDocumentFormModalIsShowing(true)}
            className="list__menu-button-add"
          >
            <svg
              className="list__menu-button-add-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <g data-name="Layer 2">
                <g data-name="plus-circle">
                  <rect width="24" height="24" opacity="0"></rect>
                  <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"></path>
                  <path d="M15 11h-2V9a1 1 0 0 0-2 0v2H9a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2z"></path>
                </g>
              </g>
            </svg>
            Добавить
            <br /> документ
          </button>
          <button className="list__menu-button-show-filters">
            <svg
              className="list__menu-button-show-filters-icon"
              xmlns="http://www.w3.org/2000/svg"
              data-name="Camada 1"
              viewBox="0 0 100 100"
            >
              <path d="M71.884 59.41667A14.59662 14.59662 0 0 0 58.18359 69H0V79H58.18359A14.58413 14.58413 0 0 0 85.5835 79H100V69H85.5835A14.59554 14.59554 0 0 0 71.884 59.41667zm0 19.16666A4.58333 4.58333 0 1 1 76.46729 74 4.58069 4.58069 0 0 1 71.884 78.58333zM41.81722 21a14.58412 14.58412 0 0 0-27.3999 0H0V31H14.41732a14.58412 14.58412 0 0 0 27.3999 0H100V21zM28.11686 30.58333A4.58333 4.58333 0 1 1 32.7002 26 4.58069 4.58069 0 0 1 28.11686 30.58333z"></path>
            </svg>
          </button>
        </div>

        {(!documents || documents?.length === 0) && (
          <div style={{ minHeight: '200px', display: 'flex', alignItems: 'center' }}>
            Документы еще не добавлены
          </div>
        )}

        {documents?.map((document, idx) => (
          <div key={idx} className="list__item list__item--adaptive list__item--bg-interactive">
            <div className="list__item-content">
              <div className="list__item-title list__item-title--white">
                <div className="list__item-title-text">
                  {document.type === DocumentType.OTHER &&
                  (document as OtherDocumentEntity).name !== null
                    ? (document as OtherDocumentEntity).name
                    : getDocumentTypeName(document.type as DocumentType)}
                </div>
                <div className="list__item-title-postfix">
                  {renderIf(
                    () => [(document as DriverLicenseEntity).number],
                    document.type === DocumentType.DRIVER_LICENSE,
                  )}

                  {renderIf(
                    () => [(document as CascoDocumentEntity).number],
                    document.type === DocumentType.CASCO,
                  )}
                  {renderIf(
                    () => [(document as PtsDocumentEntity).number],
                    document.type === DocumentType.PTS,
                  )}
                  {renderIf(
                    () => [(document as EptsDocumentEntity).number],
                    document.type === DocumentType.EPTS,
                  )}
                  {renderIf(
                    () => [(document as StsDocumentEntity).number],
                    document.type === DocumentType.STS,
                  )}
                  {renderIf(
                    () => [(document as OsagoDocumentEntity).number],
                    document.type === DocumentType.OSAGO,
                  )}
                  {renderIf(
                    () => [(document as OtherDocumentEntity).number],
                    document.type === DocumentType.OTHER,
                  )}
                </div>
              </div>
              <div className="list__item-text list__item-text--gray">
                {renderIf(
                  () => [
                    `до ${(document as DriverLicenseEntity).expiryDate?.split('T')[0]}`,
                    <br />,
                  ],
                  document.type === DocumentType.DRIVER_LICENSE &&
                    (document as DriverLicenseEntity).expiryDate != undefined,
                )}

                {renderIf(
                  () => [(document as CascoDocumentEntity).companyName, <br />],
                  document.type === DocumentType.CASCO,
                )}
                {renderIf(
                  () => [
                    (document as CascoDocumentEntity).car.brand.name +
                      ' ' +
                      (document as CascoDocumentEntity).car.model.name,
                    <br />,
                  ],
                  document.type === DocumentType.CASCO,
                )}
                {renderIf(
                  () => [
                    `до ${(document as CascoDocumentEntity).expiryDate?.split('T')[0]}`,
                    <br />,
                  ],
                  document.type === DocumentType.CASCO &&
                    (document as CascoDocumentEntity).expiryDate !== null,
                )}
                {renderIf(
                  () => [
                    (document as PtsDocumentEntity).car.brand.name +
                      ' ' +
                      (document as PtsDocumentEntity).car.model.name,
                  ],
                  document.type === DocumentType.PTS,
                )}
                {renderIf(
                  () => [
                    (document as EptsDocumentEntity).car.brand.name +
                      ' ' +
                      (document as EptsDocumentEntity).car.model.name,
                    <br />,
                  ],
                  document.type === DocumentType.EPTS,
                )}
                {renderIf(
                  () => [
                    (document as StsDocumentEntity).car.brand.name +
                      ' ' +
                      (document as StsDocumentEntity).car.model.name,
                    <br />,
                  ],
                  document.type === DocumentType.STS,
                )}
                {renderIf(
                  () => [(document as OsagoDocumentEntity).companyName, <br />],
                  document.type === DocumentType.OSAGO,
                )}
                {renderIf(
                  () => [
                    (document as OsagoDocumentEntity).car.brand.name +
                      ' ' +
                      (document as OsagoDocumentEntity).car.model.name,
                    <br />,
                  ],
                  document.type === DocumentType.OSAGO,
                )}
                {renderIf(
                  () => [
                    `до ${(document as OsagoDocumentEntity).expiryDate?.split('T')[0]}`,
                    <br />,
                  ],
                  document.type === DocumentType.OSAGO &&
                    (document as OsagoDocumentEntity).expiryDate !== null,
                )}
                {renderIf(
                  () => [
                    (document as OtherDocumentEntity).car.brand.name +
                      ' ' +
                      (document as OtherDocumentEntity).car.model.name,
                    <br />,
                  ],
                  document.type === DocumentType.OTHER,
                )}
              </div>
              <button
                onClick={() => {
                  setDocumentForUpdate(document);
                  setDocumentFormModalIsShowing(true);
                }}
                className="list__item-button-goto"
              >
                <svg
                  className="list__item-button-goto-icon"
                  viewBox="0 0 64 64"
                  xmlns="http://www.w3.org/2000/svg"
                  width="64"
                  height="64"
                >
                  <path d="M28.373 13.546a2 2 0 0 0-2.746 2.908L42.087 32l-16.46 15.546a2 2 0 0 0 2.746 2.908l18-17a2.001 2.001 0 0 0 0-2.908l-18-17z"></path>
                  <path d="M32 0C23.453 0 15.417 3.329 9.374 9.373 3.329 15.417 0 23.453 0 32s3.33 16.583 9.374 22.626C15.417 60.671 23.453 64 32 64s16.583-3.329 22.626-9.373C60.671 48.583 64 40.547 64 32s-3.33-16.583-9.374-22.626C48.583 3.329 40.547 0 32 0zm19.797 51.798C46.509 57.087 39.479 60 32 60s-14.509-2.913-19.798-8.202C6.913 46.51 4 39.479 4 32s2.913-14.51 8.203-19.798C17.491 6.913 24.521 4 32 4s14.509 2.913 19.798 8.202C57.087 17.49 60 24.521 60 32s-2.913 14.51-8.203 19.798z"></path>
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>

      <FormModal
        isShowing={documentFormModalIsShowing}
        onClose={() => onCloseDocumentForm()}
        authTextFor="Чтобы добавить документ"
      >
        <DocumentForm
          defaultValues={documentFormDefaultValues}
          document={documentForUpdate}
          onClose={onCloseDocumentForm}
          closeButtonText={
            <>
              Вернуться ко
              <br />
              всем документам
            </>
          }
        />
      </FormModal>

      <FormModal isShowing={remindFormModalIsShowing}>
        <RemindForm
          defaultValues={remindFormDefaultValues}
          onClose={onCloseRemindForm}
          closeButtonText={
            <>
              Вернуться ко
              <br />
              всем документам
            </>
          }
        />
      </FormModal>

      <MessageDialogModal
        isShowing={messageDialogModalIsShowing}
        text={messageDialogModalText}
        onClose={() => {
          setMessageDialogModalText('');
          setMessageDialogModalIsShowing(false);
          fetchDocuments();
        }}
      />
    </>
  );
};
