import {
  IsArray,
  IsBoolean,
  IsDateString,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  Max,
  Min,
} from 'class-validator';

export class CascoFormSchema {
  @IsNotEmpty({ message: 'Выберите авто' })
  @IsNumber({}, { message: 'Выберите авто' })
  @Max(2147483647)
  @Min(0, { message: 'Выберите авто' })
  carId: number;

  @IsOptional()
  @IsNotEmpty()
  number: string | null;

  @IsOptional()
  @IsNotEmpty()
  companyName: string | null;

  @IsOptional()
  @IsDateString({}, { message: 'Укажите дату в формате 2024-12-23' })
  date: string;

  @IsDateString({}, { message: 'Укажите срок действия в формате 2024-12-23' })
  expiryDate: string;

  @IsOptional()
  @IsNotEmpty()
  phoneAccident: string | null;

  @IsOptional()
  @IsArray()
  fileUrls: string[];

  @IsOptional()
  @IsBoolean()
  createRemind: boolean | null;
}
