import React, { useEffect, useMemo, useState } from 'react';
import { useAccessToken } from '../../hooks/useAccessToken';
import { useFetch } from '../../hooks/useFetch';
import {
  CarWashEntity,
  OtherConsumableEntity,
  consumablesControllerSearchConsum,
} from '../../clients/VgarageApi';
import { renderIf } from '../../utils/renderIf';
import { FormModal } from '../Modal/FormModal';
import { MessageDialogModal } from '../Modal/MessageDialogModal';
import {
  FinesPaymentEntity,
  ParkPaymentEntity,
  RefuelEntity,
  MaintenanceEntity,
} from '../../clients/VgarageApi/types.gen';
import { ConsumableType, getConsumableTypeName } from '../../types/ConsumableType';
import { ConsumableForm, onCloseConsumableFormOptions } from '../forms/ConsumableForm';
import { cleanObjectByKeys } from '../../utils/cleanObject';

interface ConsumableListProps {
  carId?: number | null;
  onUpdate?: () => void;
}

export type ConsumableEntity =
  | CarWashEntity
  | FinesPaymentEntity
  | OtherConsumableEntity
  | ParkPaymentEntity
  | RefuelEntity
  | MaintenanceEntity;

export const ConsumableList = ({ carId = null, onUpdate }: ConsumableListProps) => {
  const { accessToken } = useAccessToken({ required: false });

  const [fetchConsumables, isFetchingConsumables, consumablesError, consumables] = useFetch<
    ConsumableEntity[] | null
  >(async () => {
    const result = await consumablesControllerSearchConsum({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result.data?.data || [];
  });

  useEffect(() => {
    if (accessToken) {
      fetchConsumables();
    }
  }, [accessToken]);

  // ---

  const [consumableForUpdate, setConsumableForUpdate] = useState<ConsumableEntity | null>(null);
  const [consumableFormModalIsShowing, setConsumableFormModalIsShowing] = useState(false);

  const onCloseConsumableForm = (options?: onCloseConsumableFormOptions) => {
    setConsumableFormModalIsShowing(false);
    setConsumableForUpdate(null);
    fetchConsumables();
    if (onUpdate) onUpdate();

    if (options?.text) {
      setMessageDialogModalText(options.text);
      setMessageDialogModalIsShowing(true);
    }
  };

  const onCopyConsumable = (consumable: ConsumableEntity) => {
    let cleanConsumable = cleanObjectByKeys(consumable, ['id']);
    setConsumableForUpdate(cleanConsumable as ConsumableEntity);
  };

  const consumableFormDefaultValues = useMemo<{ type?: ConsumableType; carId: number | undefined }>(
    () => ({ carId: carId || undefined }),
    [carId],
  );

  //

  const [messageDialogModalIsShowing, setMessageDialogModalIsShowing] = useState(false);
  const [messageDialogModalText, setMessageDialogModalText] = useState('');

  return (
    <>
      <div className="list">
        <div className="list__menu">
          <button
            onClick={() => setConsumableFormModalIsShowing(true)}
            className="list__menu-button-add"
          >
            <svg
              className="list__menu-button-add-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <g data-name="Layer 2">
                <g data-name="plus-circle">
                  <rect width="24" height="24" opacity="0"></rect>
                  <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"></path>
                  <path d="M15 11h-2V9a1 1 0 0 0-2 0v2H9a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2z"></path>
                </g>
              </g>
            </svg>
            Добавить
            <br /> авторасходы
          </button>
          <button className="list__menu-button-show-filters">
            <svg
              className="list__menu-button-show-filters-icon"
              xmlns="http://www.w3.org/2000/svg"
              data-name="Camada 1"
              viewBox="0 0 100 100"
            >
              <path d="M71.884 59.41667A14.59662 14.59662 0 0 0 58.18359 69H0V79H58.18359A14.58413 14.58413 0 0 0 85.5835 79H100V69H85.5835A14.59554 14.59554 0 0 0 71.884 59.41667zm0 19.16666A4.58333 4.58333 0 1 1 76.46729 74 4.58069 4.58069 0 0 1 71.884 78.58333zM41.81722 21a14.58412 14.58412 0 0 0-27.3999 0H0V31H14.41732a14.58412 14.58412 0 0 0 27.3999 0H100V21zM28.11686 30.58333A4.58333 4.58333 0 1 1 32.7002 26 4.58069 4.58069 0 0 1 28.11686 30.58333z"></path>
            </svg>
          </button>
        </div>

        {(!consumables || consumables?.length === 0) && (
          <div style={{ minHeight: '200px', display: 'flex', alignItems: 'center' }}>
            Авторасходы еще не добавлены
          </div>
        )}

        {consumables?.map((consumable, idx) => (
          <div key={idx} className="list__item list__item--adaptive list__item--bg-interactive">
            <div className="list__item-content">
              <button
                onClick={() => {
                  onCopyConsumable(consumable);
                  setConsumableFormModalIsShowing(true);
                }}
                className="list__item-button-copy"
              >
                <svg
                  className="list__item-button-copy-icon"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  width="64"
                  height="64"
                >
                  <path d="M12 13h-1v-1c0-.6-.4-1-1-1s-1 .4-1 1v1H8c-.6 0-1 .4-1 1s.4 1 1 1h1v1c0 .6.4 1 1 1s1-.4 1-1v-1h1c.6 0 1-.4 1-1s-.4-1-1-1z"></path>
                  <path d="M17 3h-6C8.8 3 7 4.8 7 7c-2.2 0-4 1.8-4 4v6c0 2.2 1.8 4 4 4h6c2.2 0 4-1.8 4-4 2.2 0 4-1.8 4-4V7c0-2.2-1.8-4-4-4zm-2 13v1c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2v-6c0-1.1.9-2 2-2h6c1.1 0 2 .9 2 2v5zm4-3c0 1.1-.9 2-2 2v-4c0-2.2-1.8-4-4-4H9c0-1.1.9-2 2-2h6c1.1 0 2 .9 2 2v6z"></path>
                </svg>
              </button>
              <div className="list__item-title list__item-title--white">
                <div className="list__item-title-text">
                  {renderIf(
                    () => [(consumable as MaintenanceEntity).name],
                    consumable.type === ConsumableType.MAINTENANCE,
                  )}
                  {renderIf(
                    () => [getConsumableTypeName(consumable.type as ConsumableType)],
                    consumable.type !== ConsumableType.MAINTENANCE,
                  )}
                </div>

                <div className="list__item-title-postfix">
                  {renderIf(
                    () => [(consumable as CarWashEntity).cost || '0', ' Р'],
                    consumable.type === ConsumableType.CAR_WASH,
                  )}
                  {renderIf(
                    () => [(consumable as FinesPaymentEntity).cost || '0', ' Р'],
                    consumable.type === ConsumableType.FINES_PAYMENT,
                  )}
                  {renderIf(
                    () => [(consumable as MaintenanceEntity).cost || '0', ' Р'],
                    consumable.type === ConsumableType.MAINTENANCE,
                  )}
                  {renderIf(
                    () => [(consumable as OtherConsumableEntity).cost || '0', ' Р'],
                    consumable.type === ConsumableType.OTHER,
                  )}
                  {renderIf(
                    () => [(consumable as ParkPaymentEntity).cost || '0', ' Р'],
                    consumable.type === ConsumableType.PARK_PAYMENT,
                  )}
                  {renderIf(
                    () => [(consumable as RefuelEntity).cost || '0', ' Р'],
                    consumable.type === ConsumableType.REFUEL,
                  )}
                </div>
              </div>
              <div className="list__item-text list__item-text--gray">
                {consumable.car.brand.name} {consumable.car.model.name}
                <br />
                {renderIf(
                  () => [(consumable as CarWashEntity).date?.split('T')[0], <br />],
                  consumable.type === ConsumableType.CAR_WASH,
                )}
                {renderIf(
                  () => [(consumable as FinesPaymentEntity).date?.split('T')[0], <br />],
                  consumable.type === ConsumableType.FINES_PAYMENT,
                )}
                {renderIf(
                  () => [(consumable as MaintenanceEntity).maintenancedAt?.split('T')[0], <br />],
                  consumable.type === ConsumableType.MAINTENANCE,
                )}
                {renderIf(
                  () => [(consumable as OtherConsumableEntity).date?.split('T')[0], <br />],
                  consumable.type === ConsumableType.OTHER,
                )}
                {renderIf(
                  () => [(consumable as ParkPaymentEntity).date?.split('T')[0], <br />],
                  consumable.type === ConsumableType.PARK_PAYMENT,
                )}
                {renderIf(
                  () => [(consumable as RefuelEntity).date?.split('T')[0], <br />],
                  consumable.type === ConsumableType.REFUEL,
                )}
                {renderIf(() => [consumable.mileage, <> км</>], consumable.mileage !== null)}
              </div>

              <button
                onClick={() => {
                  setConsumableForUpdate(consumable);
                  setConsumableFormModalIsShowing(true);
                }}
                className="list__item-button-goto"
              >
                <svg
                  className="list__item-button-goto-icon"
                  viewBox="0 0 64 64"
                  xmlns="http://www.w3.org/2000/svg"
                  width="64"
                  height="64"
                >
                  <path d="M28.373 13.546a2 2 0 0 0-2.746 2.908L42.087 32l-16.46 15.546a2 2 0 0 0 2.746 2.908l18-17a2.001 2.001 0 0 0 0-2.908l-18-17z"></path>
                  <path d="M32 0C23.453 0 15.417 3.329 9.374 9.373 3.329 15.417 0 23.453 0 32s3.33 16.583 9.374 22.626C15.417 60.671 23.453 64 32 64s16.583-3.329 22.626-9.373C60.671 48.583 64 40.547 64 32s-3.33-16.583-9.374-22.626C48.583 3.329 40.547 0 32 0zm19.797 51.798C46.509 57.087 39.479 60 32 60s-14.509-2.913-19.798-8.202C6.913 46.51 4 39.479 4 32s2.913-14.51 8.203-19.798C17.491 6.913 24.521 4 32 4s14.509 2.913 19.798 8.202C57.087 17.49 60 24.521 60 32s-2.913 14.51-8.203 19.798z"></path>
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>

      <FormModal
        isShowing={consumableFormModalIsShowing}
        onClose={() => onCloseConsumableForm()}
        authTextFor="Чтобы добавить документ"
      >
        <ConsumableForm
          defaultValues={consumableFormDefaultValues}
          consumable={consumableForUpdate}
          onClose={onCloseConsumableForm}
          closeButtonText={
            <>
              Вернуться ко
              <br />
              всем документам
            </>
          }
        />
      </FormModal>

      <MessageDialogModal
        isShowing={messageDialogModalIsShowing}
        text={messageDialogModalText}
        onClose={() => {
          setMessageDialogModalText('');
          setMessageDialogModalIsShowing(false);
          fetchConsumables();
        }}
      />
    </>
  );
};
