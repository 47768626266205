export enum DocumentType {
  CASCO = 'CASCO',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  EPTS = 'EPTS',
  OSAGO = 'OSAGO',
  PTS = 'PTS',
  STS = 'STS',
  OTHER = 'OTHER',
}

export const DocumentTypeName = {
  [DocumentType.CASCO]: 'КАСКО',
  [DocumentType.DRIVER_LICENSE]: 'Водительское удостоверение',
  [DocumentType.EPTS]: 'ЕПТС',
  [DocumentType.OSAGO]: 'ОСАГО',
  [DocumentType.PTS]: 'ПТС',
  [DocumentType.STS]: 'СТС',
  [DocumentType.OTHER]: 'Другой документ',
} as const;

export function getDocumentTypeName(type: DocumentType): string {
  return DocumentTypeName[type];
}

export const getDocumentTypeSelectOptions = () => {
  return Object.values(DocumentType).map((value) => ({
    value: value as String,
    text: DocumentTypeName[value as DocumentType],
  }));
};
