import {
  IsArray,
  IsBoolean,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  Length,
  Max,
  Min,
} from 'class-validator';

export class StsFormSchema {
  @IsNotEmpty({ message: 'Выберите авто' })
  @IsNumber({}, { message: 'Выберите авто' })
  @Max(2147483647)
  @Min(0, { message: 'Выберите авто' })
  carId: number;

  @IsOptional()
  @Length(10, 10, { message: 'Номер должен состоят из 10 цифр' })
  number: string | null;

  @IsOptional()
  @IsNotEmpty()
  notes: string | null;

  @IsOptional()
  @IsArray()
  fileUrls: string[];

  @IsOptional()
  @IsBoolean()
  createRemind: boolean | null;
}
