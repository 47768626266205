import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';

import './Modal.scss';

interface ModalProps {
  isShowing: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const Modal = ({ isShowing, onClose, children }: ModalProps) => {
  const [showingClass, setShowingClass] = useState('');
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setShowingClass(isShowing ? 'modal--showing' : '');
    document.body.style.overflow = isShowing ? 'hidden' : 'unset';
  }, [isShowing]);

  useEffect(() => {
    if (!isShowing) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (
        contentRef.current &&
        event.target instanceof Node &&
        contentRef.current === event.target
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isShowing, onClose]);

  if (!isShowing) return null;

  return createPortal(
    <div className={`modal ${showingClass}`}>
      <div className="modal__content" ref={contentRef}>
        <div className="main__burger-menu box box--gray">
          <span className="box__header-buttons">
            <button onClick={onClose} className="button">
              <svg
                className="icon icon--30x30 icon--red"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
              </svg>
            </button>
          </span>
          {children}
        </div>
      </div>
    </div>,
    document.body,
  );
};
