import { useState, useEffect, Dispatch, SetStateAction } from 'react';

interface WindowDimensions {
  width: number;
  height: number;
}

const getWindowDimensions = (): WindowDimensions => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

type useWindowDimensionsType = [WindowDimensions, Dispatch<SetStateAction<WindowDimensions>>];

export const useWindowDimensions = (): useWindowDimensionsType => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return [windowDimensions, setWindowDimensions];
};
