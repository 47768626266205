import React from 'react';
import { Layout } from '../components/Layout/Layout';
import { RemindList } from '../components/RemindList/RemindList';

export const RemindListPage = () => {
  return (
    <Layout>
      <div className="car-info">
        <div className="car-info__title">
          <span className="car-info__title-text">Мои напоминания</span>
        </div>
      </div>

      <hr className="separator separator--empty" />

      <div style={{ margin: '0 auto' }} className="box car-info__content-item--wide">
        <RemindList />
      </div>
    </Layout>
  );
};
