export const cleanObjectByKeys = (
  object: { [key: string]: any },
  keysToRemove: string[],
): { [key: string]: any } => {
  const newObject = { ...object };
  for (const key in newObject) {
    if (keysToRemove.some((keyToRemove) => key.indexOf(keyToRemove) !== -1)) {
      delete newObject[key];
    }
  }
  return newObject;
};

export const cleanObjectByValues = (object: object, valuesToRemove: any[]) => {
  const newObject = Object.fromEntries(
    Object.entries(object).filter(([, value]) => !valuesToRemove.includes(value)),
  );

  return newObject;
};
