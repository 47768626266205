import React, { useEffect, useState } from 'react';
import { useAccessToken } from '../../hooks/useAccessToken';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { cleanObjectByValues } from '../../utils/cleanObject';
import {
  CarEntity,
  CreateRemindDto,
  DocumentEntity,
  EquipmentEntity,
  MaintenanceEntity,
  RemindEntity,
  RemindTypeEntity,
  UpdateRemindDto,
  UserEntity,
  carControllerSearchCar,
  documentControllerSearchDocs,
  equipmentControllerSearchEquipment,
  maintenanceControllerSearchMaintenance,
  remindControllerCreateRemind,
  remindControllerDeleteRemind,
  remindControllerUpdateRemind,
  remindTypeControllerReadRemindTypes,
  userControllerGetCurrentUser,
} from '../../clients/VgarageApi';
import { Select, SelectOption } from '../Select/Select';
import { useFetch } from '../../hooks/useFetch';
import { PartialRemindFormSchema, RemindFormSchema } from './schemas/RemindFormSchema';
import { DocumentType, getDocumentTypeName } from '../../types/DocumentType';
import { QuestionDialogModal, useQuestionDialog } from '../Modal/QuestionDialogModal';

interface RemindFormProps {
  remind?: RemindEntity | null;
  defaultValues?: PartialRemindFormSchema | undefined;
  closeButtonText?: React.ReactNode | undefined;
  onClose: (text?: string) => void;
}

export const RemindForm = ({
  remind = null,
  defaultValues = undefined,
  closeButtonText,
  onClose,
}: RemindFormProps) => {
  const { accessToken } = useAccessToken();

  const [fetchUser, isFetchingUser, userError, user] = useFetch<UserEntity | null>(async () => {
    if (accessToken) {
      const result = await userControllerGetCurrentUser({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return result.data || null;
    } else {
      return null;
    }
  });

  useEffect(() => {
    fetchUser();
  }, [accessToken]);

  // ---

  const [formHintMessage, setFormHintMessage] = useState('');
  const [formHintClass, setFormHintClass] = useState('');

  const formResolver = classValidatorResolver(RemindFormSchema);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty: formIsDirty },
  } = useForm<RemindFormSchema>({
    resolver: formResolver,
    defaultValues: remind
      ? {
          ...remind,
          date: remind.date?.split('T')[0],
          typeId: remind.type.id,
          carId: remind.car?.id || undefined,
          equipmentId: remind.equipment?.id,
          maintenanceId: remind.maintenance?.id,
          documentId: remind.document?.id || undefined,
          ...(defaultValues || {}),
        }
      : defaultValues,
  });

  const onValid: SubmitHandler<RemindFormSchema> = async (data) => {
    console.log(data);

    let cleanedData = cleanObjectByValues(data, [undefined]);

    console.log(cleanedData);

    if (remind === null) {
      const result = await remindControllerCreateRemind({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: cleanedData as CreateRemindDto,
      });

      console.log('Ответ от сервера:', result);

      if (result.response.status === 201) {
        setFormHintClass('form__hint--green');
        onClose('Напоминание успешно добавлено');
      } else {
        setFormHintClass('form__hint--red');
        setFormHintMessage((result.error as any).message.join(', '));
      }
    } else {
      const result = await remindControllerUpdateRemind({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        path: {
          id: remind.id,
        },
        body: cleanedData as UpdateRemindDto,
      });

      console.log('Ответ от сервера:', result);

      if (result.response.status === 200) {
        setFormHintClass('form__hint--green');
        onClose('Напоминание успешно обновлено');
      } else {
        setFormHintClass('form__hint--red');
        setFormHintMessage((result.error as any).message.join(', '));
      }
    }
  };

  const onInvalid: SubmitErrorHandler<RemindFormSchema> = (error) => {
    console.log(error);

    setFormHintClass('form__hint--red');
  };

  const onSubmit = (event?: React.SyntheticEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    handleSubmit(onValid, onInvalid)();
  };

  const onRemove = async () => {
    const result = await remindControllerDeleteRemind({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      path: {
        id: remind!.id,
      },
    });

    console.log('Ответ от сервера:', result);

    if (result.response.status === 204) {
      setFormHintClass('form__hint--green');
      onClose('Напоминание удалено');
    } else {
      setFormHintClass('form__hint--red');
      setFormHintMessage((result.error as any).message.join(', '));
    }
  };

  const onClickClose = () => {
    {
      if (formIsDirty) {
        showDialog({
          text: 'Изменения не сохранены',
          yesButtonText: (
            <>
              сохранить и<br />
              перейти
            </>
          ),
          noButtonText: (
            <>
              перейти без
              <br />
              сохранения
            </>
          ),
          onClickYes: () => {
            hideDialog();
            onSubmit();
          },
          onClickNo: () => {
            hideDialog();
            onClose();
          },
        });
      } else {
        onClose();
      }
    }
  };

  // ---

  const [fetchCars, isFetchingCars, carsError, cars] = useFetch<CarEntity[] | null>(async () => {
    if (user) {
      const result = await carControllerSearchCar({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        query: {
          userId: user.id,
        },
      });

      console.log(result.data);

      return result.data!;
    }

    return null;
  });

  const [fetchRemindTypes, isFetchingRemindTypes, remindTypesError, remindTypes] = useFetch<
    RemindTypeEntity[] | null
  >(async () => {
    const result = await remindTypeControllerReadRemindTypes({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    console.log(result.data);

    return result.data!;
  });

  const [fetchEquipments, isFetchingEquipments, equipmentsError, equipments] = useFetch<
    EquipmentEntity[] | null
  >(async () => {
    const result = await equipmentControllerSearchEquipment({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    console.log(result.data);

    const equipments = result.data;

    return equipments ? equipments.sort((a, b) => b.id - a.id) : null;
  });

  const [fetchMaintenances, isFetchingMaintenances, maintenancesErrors, maintenances] = useFetch<
    MaintenanceEntity[] | null
  >(async () => {
    const result = await maintenanceControllerSearchMaintenance({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    console.log(result.data);

    if (result.data && Array.isArray(result.data) && result.data.every((item) => 'id' in item)) {
      return result.data.sort((a, b) => b.id - a.id);
    } else {
      return [];
    }
  });

  const [fetchDocuments, isFetchingDocuments, documentsError, documents] = useFetch<
    DocumentEntity[] | null
  >(async () => {
    const result = await documentControllerSearchDocs({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    console.log(result.data!.data);

    if (
      result.data!.data &&
      Array.isArray(result.data!.data) &&
      result.data!.data.every((item) => 'id' in item)
    ) {
      return result.data!.data.sort((a, b) => b.id - a.id);
    } else {
      return [];
    }
  });

  useEffect(() => {
    fetchCars();
    fetchRemindTypes();
    fetchEquipments();
    fetchMaintenances();
    fetchDocuments();
  }, [accessToken, user]);

  // ---

  const [remindTypesSelectOptions, setRemindTypesSelectOptions] = useState<
    null | SelectOption<number>[]
  >(null);

  useEffect(() => {
    const typesSelectOptions = remindTypes?.map((remindType) => ({
      value: remindType.id,
      text: remindType.name,
    }));

    setRemindTypesSelectOptions(typesSelectOptions || null);
  }, [remindTypes]);

  const [carsSelectOptions, setCarsSelectOptions] = useState<null | SelectOption<number>[]>(null);

  useEffect(() => {
    const carsSelectOptions = cars?.map((car) => ({
      value: car.id,
      text: car.brand.name + ' ' + car.model.name,
    }));

    setCarsSelectOptions(carsSelectOptions || null);
  }, [cars]);

  const [equipmentsSelectOptions, setEquipmentsSelectOptions] = useState<
    null | SelectOption<number>[]
  >(null);

  useEffect(() => {
    const selectOptions = equipments?.map((equipment) => ({
      value: equipment.id,
      text: equipment.name,
    }));

    setEquipmentsSelectOptions(selectOptions || null);
  }, [equipments]);

  const [maintenancesSelectOptions, setMaintenancesSelectOptions] = useState<
    null | SelectOption<number>[]
  >(null);

  useEffect(() => {
    const selectOptions = maintenances?.map((maintenance) => ({
      value: maintenance.id,
      text: maintenance.name,
    }));

    setMaintenancesSelectOptions(selectOptions || null);
  }, [maintenances]);

  const [documentsSelectOptions, setDocumentsSelectOptions] = useState<
    null | SelectOption<number>[]
  >(null);

  useEffect(() => {
    const selectOptions = documents?.map((document) => ({
      value: document.id,
      text: getDocumentTypeName(document.type as DocumentType),
    }));

    setDocumentsSelectOptions(selectOptions || null);
  }, [documents]);

  // ---

  const {
    isShowing,
    text,
    yesButtonText,
    noButtonText,
    onClickYes,
    onClickNo,
    showDialog,
    hideDialog,
  } = useQuestionDialog();

  return (
    <>
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div className="form__header">
          <span className="form__header-title">
            {remind ? remind.name : 'Добавить напоминание'}
          </span>
          <span className="form__header-button button-go-back" onClick={() => onClickClose()}>
            <svg
              className="button-go-back-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z"></path>
            </svg>
            <span>{closeButtonText}</span>
          </span>
        </div>

        <div className="form__item form__item--required">
          <label htmlFor="car-name">Выберите категорию</label>
          <div className="form__item-element">
            <Controller
              name="typeId"
              control={control}
              render={({ field }) => (
                <Select
                  options={remindTypesSelectOptions}
                  defaultValue={field.value}
                  onChange={field.onChange}
                  notAllowed={remind !== null}
                />
              )}
            />
          </div>
        </div>

        {/*
        показывваем если передано напоминание
        или если есть defaultValues.carId (создание напоминания при переходе из другой модалки)
        или если не переданы ни remind ни defaultValues (создание нового напоминания в разделе Напоминания)
      */}
        {((remind && remind.car) || defaultValues?.carId || (!remind && !defaultValues)) && (
          <div className="form__item form__item--required">
            <label htmlFor="car-name">Выберите авто</label>
            <div className="form__item-element">
              <Controller
                name="carId"
                control={control}
                render={({ field }) => (
                  <Select
                    options={carsSelectOptions}
                    defaultValue={field.value}
                    onChange={field.onChange}
                    notAllowed={remind !== null}
                  />
                )}
              />
            </div>
          </div>
        )}

        {/*
        показывваем если передано напоминание и есть запчасть в напоминании
        или если есть defaultValues.equipmentId (создание напоминания при переходе из другой модалки)
        или если не переданы ни remind ни defaultValues (создание нового напоминания в разделе Напоминания) - НЕ показываем
      */}
        {((remind && remind.equipment) || defaultValues?.equipmentId) && (
          <div className="form__item">
            <label htmlFor="car-name">Выберите запчасть</label>
            <div className="form__item-element">
              <Controller
                name="equipmentId"
                control={control}
                render={({ field }) => (
                  <Select
                    options={equipmentsSelectOptions}
                    defaultValue={field.value}
                    onChange={field.onChange}
                    notAllowed={remind !== null}
                  />
                )}
              />
            </div>
          </div>
        )}

        {/*
        показывваем если передано напоминание и есть ТО в напоминании
        или если есть defaultValues.maintenanceId (создание напоминания при переходе из другой модалки)
        или если не переданы ни remind ни defaultValues (создание нового напоминания в разделе Напоминания) - НЕ показываем
      */}
        {((remind && remind.maintenance) || defaultValues?.maintenanceId) && (
          <div className="form__item">
            <label htmlFor="car-name">Выберите ТО / Сервисную работу</label>
            <div className="form__item-element">
              <Controller
                name="maintenanceId"
                control={control}
                render={({ field }) => (
                  <Select
                    options={maintenancesSelectOptions}
                    defaultValue={field.value}
                    onChange={field.onChange}
                    notAllowed={remind !== null}
                  />
                )}
              />
            </div>
          </div>
        )}

        {/*
        показывваем если передано напоминание и есть Документ в напоминании
        или если есть defaultValues.documentId (создание напоминания при переходе из другой модалки)
        или если не переданы ни remind ни defaultValues (создание нового напоминания в разделе Напоминания) - НЕ показываем
      */}
        {((remind && remind.document) || defaultValues?.documentId) && (
          <div className="form__item">
            <label htmlFor="car-name">Выберите документ</label>
            <div className="form__item-element">
              <Controller
                name="documentId"
                control={control}
                render={({ field }) => (
                  <Select
                    options={documentsSelectOptions}
                    defaultValue={field.value}
                    onChange={field.onChange}
                    notAllowed={remind !== null}
                  />
                )}
              />
            </div>
          </div>
        )}

        <div className="form__item form__item--required">
          <label htmlFor="car-name">Название напоминания</label>
          <input {...register('name')} />
        </div>

        <div className="form__item">
          <label htmlFor="car-name">Пробег, когда напомнить, км</label>
          <input
            type="number"
            {...register('mileage', {
              setValueAs: (value) => parseInt(value) || null,
            })}
          />
        </div>

        <div className="form__item">
          <label htmlFor="car-name">Дата, когда напомнить</label>
          <input
            style={{ width: '100%' }}
            type="date"
            {...register('date', {
              setValueAs: (value) => value || null,
            })}
          />
        </div>

        <div className="form__item">
          <label htmlFor="car-name">Заметки</label>
          <textarea
            {...register('notes', {
              setValueAs: (value) => value || null,
            })}
          />
        </div>

        <hr className="separator separator--empty" />

        <button type="submit" className="button--primary">
          Сохранить
        </button>

        <div className={`form__hint ${formHintClass}`}>
          {formHintMessage}
          {Object.entries(formErrors).map(([field, { message }]) => (
            <p key={field}>{message}</p>
          ))}
        </div>

        {remind && (
          <button
            onClick={() => {
              showDialog({
                text: 'Удалить напоминание?',
                yesButtonText: 'ДА',
                noButtonText: 'НЕТ',
                onClickYes: () => onRemove(),
                onClickNo: () => hideDialog(),
              });
            }}
            className="button button--light"
            type="button"
          >
            <svg className="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
            </svg>
            удалить
          </button>
        )}
      </form>

      <QuestionDialogModal
        isShowing={isShowing}
        text={text}
        yesButtonText={yesButtonText}
        noButtonText={noButtonText}
        onClickYes={onClickYes}
        onClickNo={onClickNo}
      />
    </>
  );
};
