import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useAccessToken } from '../../hooks/useAccessToken';
import { AuthRequiredMessage } from '../Message/AuthRequiredMessage';

import './Modal.scss';

interface FormModalProps {
  isShowing: boolean;
  title?: string;
  closeButtonText?: React.ReactNode;
  onClose?: () => void;
  children: React.ReactNode;
  authTextFor?: string;
}

export const FormModal = ({ isShowing, children, onClose, authTextFor }: FormModalProps) => {
  const { accessToken } = useAccessToken({ required: false });

  const [showingClass, setShowingClass] = useState('');

  useEffect(() => {
    setShowingClass(isShowing ? 'modal--showing' : '');
    document.body.style.overflow = isShowing ? 'hidden' : 'unset';
  }, [isShowing]);

  if (!isShowing) return null;

  return createPortal(
    <div className={`modal ${showingClass}`}>
      <div className="modal__content">
        <div className={`main__car-part-parameters car-part-parameters box box--gray`}>
          {accessToken ? (
            children
          ) : (
            <>
              <span className="box__header-buttons">
                <button onClick={onClose} className="button" type="button">
                  <svg
                    className="icon icon--30x30 icon--red"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
                  </svg>
                </button>
              </span>
              <AuthRequiredMessage textFor={authTextFor} />
            </>
          )}
        </div>
      </div>
    </div>,
    document.body,
  );
};
