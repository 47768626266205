import React, { useContext, useEffect, useState } from 'react';
import { Layout } from '../components/Layout/Layout';
import { useParams } from 'react-router-dom';
import { Spinner } from '../components/Spinner/Spinner';
import { useAccessToken } from '../hooks/useAccessToken';
import { useFetch } from '../hooks/useFetch';
import {
  GuideCategoryEntity,
  GuideEntity,
  guideCategoryControllerFindAll,
  guideControllerFindOne,
} from '../clients/VgarageApi';
import { Modal } from '../components/Modal/Modal';
import { CarList } from '../components/CarList/CarList';
import { Guide } from '../components/Guide/Guide';
import { GuideCategoriesList } from '../components/Guide/GuideCategoriesList';

export const GuidePage = () => {
  //const { accessToken } = useAccessToken();

  const { id: guideId } = useParams();

  const [fetchGuide, isFetchingGuide, guideError, guide] = useFetch<GuideEntity | null>(
    async () => {
      if (guideId) {
        const result = await guideControllerFindOne({
          baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
          path: {
            id: parseInt(guideId),
          },
        });

        return result.data!;
      }

      return null;
    },
  );

  const [fetchGuideCategories, isFetchingGuideCategories, guieCategoriesError, guideCategories] =
    useFetch<GuideCategoryEntity[] | null>(async () => {
      const result = await guideCategoryControllerFindAll({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      });
      if (result.data && Array.isArray(result.data) && result.data.every((item) => 'id' in item)) {
        return result.data.sort((a, b) => a.id - b.id);
      } else {
        return [];
      }
    });

  useEffect(() => {
    fetchGuide();
  }, [guideId]);

  useEffect(() => {
    fetchGuideCategories();
  }, []);

  // ---

  return (
    <>
      <Layout showMenu={false}>
        <div className="offer">
          <span className="logo"></span>
          <h1 className="offer__title offer__title--with-logo">
            Подробная инструкция
            <br />
            по сайту
          </h1>
        </div>
        <main className="main">
          {isFetchingGuide && <Spinner />}

          {guide && (
            <div className="car-info">
              <div className="guide--breadcrumbs">Выберите раздел</div>
              <GuideCategoriesList selectCategory={guide.category.id}></GuideCategoriesList>
              <Guide guideId={guide.id}></Guide>
            </div>
          )}
        </main>
      </Layout>
    </>
  );
};
