import { Layout } from '../components/Layout/Layout';

export const IdeasPage = () => {
  return (
    <Layout>
      <main>
        <ul className="breadcrumbs">
          <li>Ваши идеи</li>
        </ul>
        <div className="car-info">
          <div className="car-info__title">
            <span className="car-info__title-text">ВАШИ ИДЕИ</span>
          </div>
        </div>
      </main>
      <div style={{ margin: '0 auto' }} className="box car-info__content-item--wide">
        <div className="list__item list__item--adaptive">
          <div className="list__item-content">
            <div className="list__item-title list__item-title--white">
              <div className="list__item-title-text">
                Мы сами автовладельцы - и делаем продукт для автовладельцев!
              </div>
            </div>
            <div className="list__item-text list__item-text--gray">
              <div>
                Базовый функционал электронной сервисной книжки определен и выполнен благодаря вашей
                обратной связи. Дальнейшее развитие и новый функционал - полностью в руках
                пользователей.
                <br />
                <br />
                Поэтому мы просим вас - пользователей и автовладельцев - пишите свои идеи по
                улучшению и добавлению функционала, примеры хороших решений других проектов.
                <br />
                <br />
                Мы создали специальную группу в Телеграм для ранних пользователей приложения VGarage
                -{' '}
                <a href="https://t.me/vgarage_online_testing" target="_blank">
                  t.me/vgarage_online_testing
                </a>
                . Здесь аккумулируется вся обратная связь от вас - правки, предложения.
                Присоединяйтесь к сообществу автовладельцев и будьте активны! Мы будем вам очень
                признательны!
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
