import React, { useEffect, useState } from 'react';

import './Modal.scss';
import { createPortal } from 'react-dom';

interface MessageDialogModalProps {
  isShowing: boolean;
  showIcon?: boolean;
  onClose: () => void;
  text: string | React.ReactNode;
}

export const MessageDialogModal = ({
  isShowing,
  onClose,
  text,
  showIcon = true,
}: MessageDialogModalProps) => {
  const [showingClass, setShowingClass] = useState('');

  useEffect(() => {
    setShowingClass(isShowing ? 'modal--showing' : '');
    document.body.style.overflow = isShowing ? 'hidden' : 'unset';
  }, [isShowing]);

  // ---

  if (!isShowing) return null;
  console.log('showIcon: ', showIcon);

  return createPortal(
    <div className={`modal ${showingClass}`}>
      <div className="modal__content">
        <div className="dialog box box--dialog">
          <span onClick={onClose} className="dialog__close">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
            </svg>
          </span>

          <div className="dialog__text">
            {showIcon == true && (
              <svg
                style={{ marginRight: '5px' }}
                className="icon icon--20x20 icon--green"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"></path>
              </svg>
            )}
            {text}
          </div>
        </div>
      </div>
    </div>,
    document.body,
  );
};
