import React, { useState } from 'react';
import { Modal } from '../Modal/Modal';
import { BurgerMenu } from './BurgerMenu';

import './Burger.scss';

interface BurgerProps {
  showTitle?: boolean;
}

export const Burger = ({ showTitle = true }: BurgerProps) => {
  const [modalBurgerMenuIsShowing, setModalBurgerMenuIsShowing] = useState(false);

  return (
    <>
      <span onClick={() => setModalBurgerMenuIsShowing(true)} className="burger">
        <svg
          className="burger__icon icon icon--blue"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <g data-name="Layer 2">
            <g data-name="menu">
              <rect width="18" height="2" x="3" y="11" rx=".95" ry=".95"></rect>
              <rect width="18" height="2" x="3" y="16" rx=".95" ry=".95"></rect>
              <rect width="18" height="2" x="3" y="6" rx=".95" ry=".95"></rect>
            </g>
          </g>
        </svg>

        {showTitle && <span>Меню</span>}
      </span>
      <Modal
        isShowing={modalBurgerMenuIsShowing}
        onClose={() => setModalBurgerMenuIsShowing(false)}
      >
        <BurgerMenu onClickButton={() => setModalBurgerMenuIsShowing(false)} />
      </Modal>
    </>
  );
};
