import React, { useEffect, useState } from 'react';
import { Modal } from '../Modal/Modal';

import { guideControllerFindOne, GuideEntity } from '../../clients/VgarageApi';
import { useFetch } from '../../hooks/useFetch';
import { useAccessToken } from '../../hooks/useAccessToken';
import './Guide.scss';
import { useNavigate } from 'react-router-dom';

interface GuideProps {
  guideId?: number | null;
}

export const Guide = ({ guideId = null }: GuideProps) => {
  const navigate = useNavigate();

  //const { accessToken } = useAccessToken();

  const [fetchGuide, isFetchingGuide, guideError, guide] = useFetch<GuideEntity | null>(
    async () => {
      if (guideId) {
        const result = await guideControllerFindOne({
          baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
          path: {
            id: guideId,
          },
        });

        return result.data!;
      }
      return null;
    },
  );

  useEffect(() => {
    fetchGuide();
  }, [guideId]);

  return (
    <>
      {guide !== undefined && (
        <div className="guide__box box car-info__content-item--wide">
          <div className="list__item list__item--adaptive">
            <div className="list__item-content">
              <div className="guide-title">{guide?.title || ''}</div>
              <span
                className="form__header-button button-go-back"
                onClick={() => navigate(`/guide-categories/${guide?.category.id}`)}
              >
                <svg
                  className="button-go-back-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z"></path>
                </svg>
                <span>Назад</span>
              </span>
              <div className="list__item-text list__item-text--gray">
                <div dangerouslySetInnerHTML={{ __html: guide?.text || '' }}></div>
              </div>
              {guide?.nextPost && (
                <div>
                  <div className="guide__next-topic-title-box">
                    <span className="guide__next-topic-text">Следующая тема</span>
                    <span className="guide__next-topic-title">{guide?.nextPost.title}</span>
                  </div>
                  <span
                    className="guide__footer-button guide__button-go-forward"
                    onClick={() => navigate(`/guide/${guide?.nextPost.id}`)}
                  >
                    <span>Вперёд</span>
                    <svg
                      className="guide__button-go-forward-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22.707,12.707a1,1,0,0,0,0-1.414l-6-6a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-4.293,4.293a1,1,0,0,0,1.414,1.414Z"></path>
                    </svg>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
