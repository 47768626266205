import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetch } from '../../hooks/useFetch';
import { Spinner } from '../Spinner/Spinner';
import { useAccessToken } from '../../hooks/useAccessToken';
import { CarEntity, carControllerSearchCar } from '../../clients/VgarageApi';
import { CarForm } from '../forms/CarForm';
import { FormModal } from '../Modal/FormModal';

import '../../styles/icon.scss';
import '../../styles/form.scss';
import '../../styles/button.scss';
import '../../styles/car-list.scss';

interface CarListProps {
  onClose?: (() => void) | undefined;
}

export const CarList = ({ onClose }: CarListProps) => {
  const { accessToken } = useAccessToken({ required: false });

  const [cars, setCars] = useState<any[]>([]);
  const [fetchCars, isFetchingCars, carsError, carsData] = useFetch<CarEntity[]>(async () => {
    const response = await carControllerSearchCar({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (
      response.data &&
      Array.isArray(response.data) &&
      response.data.every((item) => 'id' in item)
    ) {
      return response.data.sort((a, b) => b.id - a.id);
    } else {
      return [];
    }
  });

  useEffect(() => {
    if (accessToken) {
      fetchCars();
    }
  }, [accessToken]);

  useEffect(() => {
    setCars(carsData || []);
  }, [carsData]);

  const navigate = useNavigate();

  const [modalCarFormIsShowing, setModalCarFormIsShowing] = useState(false);

  return (
    <>
      <div className="car-list__content">
        <div className="car-list__items">
          <div className="car-list__items-header">Мои авто</div>
          {isFetchingCars && <Spinner />}

          {(!cars || cars?.length === 0) && (
            <div
              style={{
                minHeight: '200px',
                display: 'flex',
                alignItems: 'center',
                margin: '0 auto',
              }}
            >
              Авто еще не добавлены
            </div>
          )}

          {cars.map((car) => (
            <button
              onClick={() => {
                onClose && onClose();
                navigate(`/my-auto/${car.id}`);
              }}
              key={car.id}
              className="button button--tile button--hover-active button--tile--hover-active"
            >
              {car.brand.name} {car.model.name}
            </button>
          ))}

          <button
            onClick={() => setModalCarFormIsShowing(true)}
            className="car-list__items-button-add button button--tile button--tile--hover-active"
          >
            <svg
              className="car-list__items-button-add-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <g data-name="Layer 2">
                <g data-name="plus-circle">
                  <rect width="24" height="24" opacity="0"></rect>
                  <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"></path>
                  <path d="M15 11h-2V9a1 1 0 0 0-2 0v2H9a1 1 0 0 0 0 2h2v2a1 1 0 0 0 2 0v-2h2a1 1 0 0 0 0-2z"></path>
                </g>
              </g>
            </svg>
            добавить авто
          </button>
        </div>

        <div className="car-list__items">
          <div className="car-list__items-header car-list__items-header--gray">Мои бывшие</div>

          <div className="car-list__items-hint">
            сюда вы сможете перенести авто из раздела "Мои авто"
          </div>
        </div>
      </div>

      <FormModal
        isShowing={modalCarFormIsShowing}
        onClose={() => setModalCarFormIsShowing(false)}
        authTextFor="Чтобы добавить авто"
      >
        <CarForm
          onClose={(options) => {
            setModalCarFormIsShowing(false);
            if (options && options.car) {
              onClose && onClose();
              navigate(`/my-auto/${options.car.id}?messageText=${options.text}`);
            }
          }}
          closeButtonText={
            <>
              Вернуться
              <br />
              ко всем авто
            </>
          }
        />
      </FormModal>
    </>
  );
};
