import React from 'react';
import { useNavigate } from 'react-router-dom';

interface AuthRequiredMessageProps {
  textFor?: string;
}

export const AuthRequiredMessage = ({ textFor = '' }: AuthRequiredMessageProps) => {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      {textFor}
      <br />
      необходимо{' '}
      <span
        onClick={() => navigate('/login')}
        style={{ color: 'rgb(30, 136, 229)', cursor: 'pointer' }}
      >
        войти или зарегистрироваться
      </span>
      <br />
    </div>
  );
};
