import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Router } from '../Router/Router';

import './App.scss';

export const guideCategoriesScrollPositionContext = React.createContext({
  guideCategoriesScrollPosition: 0,
  setGuideCategoriesScrollPosition: (value: number) => {},
});

export const App = () => {
  const [guideCategoriesScrollPosition, setGuideCategoriesScrollPosition] = useState(0);
  return (
    <guideCategoriesScrollPositionContext.Provider
      value={{ guideCategoriesScrollPosition, setGuideCategoriesScrollPosition }}
    >
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </guideCategoriesScrollPositionContext.Provider>
  );
};
