import React from 'react';
import { Layout } from '../components/Layout/Layout';
import { DocumentList } from '../components/DocumentList/DocumentList';

export const DocumentListPage = () => {
  return (
    <Layout>
      <div className="car-info">
        <div className="car-info__title">
          <span className="car-info__title-text">Мои документы</span>
        </div>
      </div>

      <hr className="separator separator--empty" />

      <div style={{ margin: '0 auto' }} className="box car-info__content-item--wide">
        <DocumentList />
      </div>
    </Layout>
  );
};
