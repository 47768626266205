import { IsArray, IsBoolean, IsDateString, IsNotEmpty, IsOptional, Length } from 'class-validator';

export class DriverLicenseFormSchema {
  @IsNotEmpty({ message: 'Укажите номер документа' })
  @Length(10, 999, {
    message: 'Номер документа должен быть 10 или более символов',
  })
  number: string;

  @IsOptional()
  @IsDateString({}, { message: 'Укажите дату в формате 2024-12-23' })
  date: string;

  @IsDateString({}, { message: 'Укажите срок действия в формате 2024-12-23' })
  expiryDate: string;

  @IsOptional()
  @IsArray()
  fileUrls: string[];

  @IsOptional()
  @IsBoolean()
  createRemind: boolean | null;
}
