import React, { useEffect, useState } from 'react';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { EquipmentFormSchema } from './schemas/EquipmentFormSchema';
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { cleanObjectByKeys, cleanObjectByValues } from '../../utils/cleanObject';
import { useAccessToken } from '../../hooks/useAccessToken';
import { MessageDialogModal } from '../Modal/MessageDialogModal';
import { useFetch } from '../../hooks/useFetch';
import { Select, SelectOption } from '../Select/Select';
import { FormModal } from '../Modal/FormModal';
import { RemindForm } from './RemindForm';
import { Hint } from '../Hint/Hint';
import { QuestionDialogModal, useQuestionDialog } from '../Modal/QuestionDialogModal';
import {
  CarEntity,
  CreateEquipmentDto,
  EquipmentEntity,
  RemindEntity,
  UpdateEquipmentDto,
  carControllerSearchCar,
  carControllerUpdateCar,
  equipmentControllerCreateEquipment,
  equipmentControllerDeleteEquipment,
  equipmentControllerUpdateEquipment,
} from '../../clients/VgarageApi';

export interface onCloseEquipmentFormOptions {
  equipment?: EquipmentEntity;
  text?: string;
  createRemind?: boolean;
}

export interface EquipmentFormProps {
  equipment?: EquipmentEntity | null;
  defaultValues?: Partial<EquipmentFormSchema> | undefined;
  closeButtonText?: React.ReactNode | undefined;
  onClose: (options?: onCloseEquipmentFormOptions) => void;
  isShowCar?: boolean;
  isShowingCreateRemind?: boolean;
  isShowingUpdateMileage?: boolean;
}

export const EquipmentForm = ({
  equipment = null,
  defaultValues = undefined,
  closeButtonText,
  onClose,
  isShowCar = false,
  isShowingCreateRemind = true,
  isShowingUpdateMileage = true,
}: EquipmentFormProps) => {
  console.log('eq', equipment);
  console.log('df', defaultValues);
  console.log('show', isShowingCreateRemind);

  const { accessToken } = useAccessToken();

  const [fetchCars, isFetchingCars, carsError, cars] = useFetch<CarEntity[] | null>(async () => {
    if (accessToken) {
      const result = await carControllerSearchCar({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return result.data || null;
    }

    return null;
  });

  useEffect(() => {
    fetchCars();
  }, [accessToken]);

  const [carsSelectOptions, setCarsSelectOptions] = useState<null | SelectOption<number>[]>(null);

  useEffect(() => {
    const carsSelectOptions = cars?.map((car) => ({
      value: car.id,
      text: car.brand.name + ' ' + car.model.name,
    }));

    setCarsSelectOptions(carsSelectOptions || null);
  }, [cars]);

  // ---

  const [formHintMessage, setFormHintMessage] = useState('');
  const [formHintClass, setFormHintClass] = useState('');

  const formResolver = classValidatorResolver(EquipmentFormSchema);

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty: formIsDirty },
  } = useForm<EquipmentFormSchema>({
    resolver: formResolver,
    defaultValues: equipment
      ? {
          ...equipment,
          date: equipment.date?.split('T')[0] || null,
          ...(defaultValues || {}),
          carId: equipment.car.id,
        }
      : defaultValues,
  });

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
      return;
    }
    if (equipment) {
      reset({
        ...equipment,
        carId: equipment.car?.id,
      });
    } else {
      reset();
    }
  }, [equipment, defaultValues, reset]);

  const onValid: SubmitHandler<EquipmentFormSchema> = async (data) => {
    console.log(data);

    const createRemind = data.createRemind === true;

    let cleanedData = cleanObjectByValues(data, [undefined]);
    cleanedData = cleanObjectByKeys(data, ['createRemind', 'updateMileage']);

    console.log(cleanedData);

    if (!equipment?.id) {
      const result = await equipmentControllerCreateEquipment({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: cleanedData as CreateEquipmentDto,
      });

      console.log('Ответ от сервера:', result);

      if (result.response.status === 201) {
        setFormHintClass('form__hint--green');
        onClose({
          text: 'Запчасть успешно добавлена',
          equipment: result.data!,
          createRemind,
        });
      } else {
        setFormHintClass('form__hint--red');
        setFormHintMessage((result.error as any).message.join(', '));
      }
    } else {
      const result = await equipmentControllerUpdateEquipment({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        path: {
          id: equipment!.id,
        },
        body: cleanedData as UpdateEquipmentDto,
      });

      console.log('Ответ от сервера:', result);

      if (result.response.status === 200) {
        setFormHintClass('form__hint--green');
        onClose({
          text: 'Запчасть успешно обновлена',
          equipment: result.data!,
          createRemind,
        });
      } else {
        setFormHintClass('form__hint--red');
        setFormHintMessage((result.error as any).message.join(', '));
      }
    }

    // ---

    if (data.updateMileage) {
      const result = await carControllerUpdateCar({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        path: {
          id: cleanedData.carId,
        },
        body: {
          mileage: data.mileage!,
        },
      });

      console.log('Обновление пробега:', result);
    }
  };

  const onInvalid: SubmitErrorHandler<EquipmentFormSchema> = (error) => {
    console.log(error);

    setFormHintClass('form__hint--red');
  };

  const onSubmit = (event?: React.SyntheticEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    handleSubmit(onValid, onInvalid)();
  };

  const onRemove = async () => {
    const result = await equipmentControllerDeleteEquipment({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      path: {
        id: equipment!.id,
      },
    });

    console.log('Ответ от сервера:', result);

    if (result.response.status === 204) {
      setFormHintClass('form__hint--green');
      onClose({
        text: 'Запчасть удалена',
      });
    } else {
      setFormHintClass('form__hint--red');
      setFormHintMessage((result.error as any).message.join(', '));
    }
  };

  const onClickClose = () => {
    {
      if (formIsDirty) {
        showDialog({
          text: 'Изменения не сохранены',
          yesButtonText: (
            <>
              сохранить и<br />
              перейти
            </>
          ),
          noButtonText: (
            <>
              перейти без
              <br />
              сохранения
            </>
          ),
          onClickYes: () => {
            hideDialog();
            onSubmit();
          },
          onClickNo: () => {
            hideDialog();
            onClose();
          },
        });
      } else {
        onClose();
      }
    }
  };

  // ---

  const [remindForUpdate, setRemindForUpdate] = useState<RemindEntity | null>(null);
  const [remindFormModalIsShowing, setRemindFormModalIsShowing] = useState(false);

  const onCloseRemindForm = (text?: string) => {
    setRemindFormModalIsShowing(false);
    setRemindForUpdate(null);

    if (text) {
      setMessageDialogModalText(text);
      setMessageDialogModalIsShowing(true);
    }
  };

  // ---

  const [messageDialogModalIsShowing, setMessageDialogModalIsShowing] = useState(false);
  const [messageDialogModalText, setMessageDialogModalText] = useState('');

  const {
    isShowing,
    text,
    yesButtonText,
    noButtonText,
    onClickYes,
    onClickNo,
    showDialog,
    hideDialog,
  } = useQuestionDialog();

  return (
    <>
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div className="form__header">
          <span className="form__header-title">
            {equipment ? equipment.name : 'Добавить запчасть'}
          </span>
          <span className="form__header-button button-go-back" onClick={() => onClickClose()}>
            <svg
              className="button-go-back-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z"></path>
            </svg>
            <span>{closeButtonText}</span>
          </span>
        </div>

        <div
          style={!isShowCar && defaultValues?.carId ? { display: 'none' } : {}}
          className="form__item form__item--required"
        >
          <label htmlFor="car-name">Выберите авто</label>
          <div className="form__item-element">
            <Controller
              name="carId"
              control={control}
              render={({ field }) => (
                <Select
                  options={carsSelectOptions}
                  defaultValue={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
        </div>

        <div className="form__item form__item--required">
          <label htmlFor="car-name">Название запчасти</label>
          <input {...register('name')} />
        </div>

        <div className="form__item">
          <label htmlFor="car-number">Параметры</label>
          <input
            {...register('parameters', {
              setValueAs: (value) => value || null,
            })}
          />
        </div>

        <div className="form__item">
          <label htmlFor="car-name">Стоимость, руб</label>
          <input
            type="number"
            step="0.01"
            {...register('cost', {
              setValueAs: (value) => (value ? parseFloat(value) : null),
            })}
          />
        </div>

        <div className="form__item">
          <label htmlFor="car-number">Дата</label>
          <input
            style={{ width: '100%' }}
            type="date"
            {...register('date', {
              setValueAs: (value) => value || null,
            })}
          />
        </div>

        <div className="form__item">
          <label htmlFor="car-mileage">Пробег, км</label>
          <input
            type="number"
            {...register('mileage', {
              setValueAs: (value) => (value ? parseInt(value) : null),
            })}
          />
        </div>

        {isShowingUpdateMileage && (
          <div className="form__item form__item--row">
            <label className="checkbox checkbox__box--light">
              <input type="checkbox" {...register('updateMileage')} />
              <span className="checkbox__box"></span>
              <span className="checkbox__text">Обновить пробег</span>
            </label>
            <Hint
              text={`
                После сохранения у выбранного автомобиля обновится пробег на указанное значение
                `}
            />
          </div>
        )}

        <div className="form__item">
          <label htmlFor="car-mileage">Артикул</label>
          <input
            {...register('article', {
              setValueAs: (value) => value || null,
            })}
          />
        </div>

        <div className="form__item">
          <label htmlFor="car-number">Место</label>
          <input
            {...register('place', {
              setValueAs: (value) => value || null,
            })}
          />
        </div>

        <div className="form__item">
          <label htmlFor="notes">Заметки</label>
          <textarea
            {...register('notes', {
              setValueAs: (value) => value || undefined,
            })}
          ></textarea>
        </div>

        {isShowingCreateRemind && (equipment === null || equipment.reminds.length === 0) ? (
          <div className="form__item form__item--row">
            <label className="checkbox checkbox__box--light">
              <input type="checkbox" {...register('createRemind')} />
              <span className="checkbox__box"></span>
              <span className="checkbox__text">Создать напоминание</span>
            </label>
            <Hint
              text={`
                  После сохранения запчасти вы будете перенаправлены на дозаполнение напоминания
                `}
            />
          </div>
        ) : (
          isShowingCreateRemind && (
            <div>
              Напоминание создано.{' '}
              <span
                onClick={() => {
                  setRemindForUpdate(equipment!.reminds[0]);
                  setRemindFormModalIsShowing(true);
                }}
                style={{
                  color: 'rgba(30, 136, 229, 1)',
                  fontWeight: 400,
                  cursor: 'pointer',
                }}
              >
                Посмотреть
              </span>
            </div>
          )
        )}

        <hr className="separator separator--empty" />

        <button type="submit" className="button--primary">
          Сохранить
        </button>

        <div className={`form__hint ${formHintClass}`}>
          {formHintMessage}
          {Object.entries(formErrors).map(([field, { message }]) => (
            <p key={field}>{message}</p>
          ))}
        </div>

        {equipment && (
          <button
            onClick={() => {
              showDialog({
                text: 'Удалить запчасть?',
                yesButtonText: 'ДА',
                noButtonText: 'НЕТ',
                onClickYes: () => onRemove(),
                onClickNo: () => hideDialog(),
              });
            }}
            className="button button--light"
            type="button"
          >
            <svg className="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
            </svg>
            удалить
          </button>
        )}
      </form>

      <FormModal isShowing={remindFormModalIsShowing}>
        <RemindForm
          remind={remindForUpdate}
          onClose={onCloseRemindForm}
          closeButtonText={
            <>
              Вернуться к
              <br />
              параметрам запчасти
            </>
          }
        />
      </FormModal>

      <MessageDialogModal
        isShowing={messageDialogModalIsShowing}
        text={messageDialogModalText}
        onClose={() => {
          setMessageDialogModalText('');
          setMessageDialogModalIsShowing(false);
        }}
      />

      <QuestionDialogModal
        isShowing={isShowing}
        text={text}
        yesButtonText={yesButtonText}
        noButtonText={noButtonText}
        onClickYes={onClickYes}
        onClickNo={onClickNo}
      />
    </>
  );
};
