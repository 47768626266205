import React, { useEffect, useState } from 'react';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { cleanObjectByKeys, cleanObjectByValues } from '../../utils/cleanObject';
import { useAccessToken } from '../../hooks/useAccessToken';
import { MaintenanceFormSchema } from './schemas/MaintenanceFormSchema';
import { Select, SelectOption } from '../Select/Select';
import { useFetch } from '../../hooks/useFetch';
import { EquipmentQuickList } from '../EquipmentQuickList/EquipmentQuickList';
import { FileLoader } from '../FileLoader/FileLoader';
import { MessageDialogModal } from '../Modal/MessageDialogModal';
import { FormModal } from '../Modal/FormModal';
import { RemindForm } from './RemindForm';
import { Hint } from '../Hint/Hint';
import { QuestionDialogModal, useQuestionDialog } from '../Modal/QuestionDialogModal';
import {
  CarEntity,
  CreateMaintenanceDto,
  MaintenanceCategoryEntity,
  MaintenanceEntity,
  RemindEntity,
  UpdateMaintenanceDto,
  UserEntity,
} from '../../clients/VgarageApi/types.gen';

import {
  carControllerSearchCar,
  carControllerUpdateCar,
  maintenanceCategoryControllerReadMaintenanceCategories,
  maintenanceControllerCreateMaintenance,
  maintenanceControllerDeleteMaintenance,
  maintenanceControllerUpdateMaintenance,
  userControllerGetCurrentUser,
} from '../../clients/VgarageApi/sdk.gen';
import { ConsumableType } from '../../types/ConsumableType';
import { renderIf } from '../../utils/renderIf';

export interface onCloseMaintenanceFormOptions {
  maintenance?: MaintenanceEntity;
  text?: string;
  createRemind?: boolean;
}

export interface MaintenanceFormProps {
  maintenance?: MaintenanceEntity | null;
  defaultValues?: Partial<MaintenanceFormSchema> | undefined;
  closeButtonText?: React.ReactNode | undefined;
  selectedConsumableType?: ConsumableType | null;
  setSelectedConsumableType?: React.Dispatch<ConsumableType>;
  consumableTypeSelectOptions?: SelectOption<string>[];
  onClose: (options?: onCloseMaintenanceFormOptions) => void;
  setSelectedCarId?: React.Dispatch<number>;
}

export const MaintenanceForm = ({
  maintenance = null,
  defaultValues = undefined,
  closeButtonText,
  selectedConsumableType,
  setSelectedConsumableType,
  consumableTypeSelectOptions,
  onClose,
  setSelectedCarId,
}: MaintenanceFormProps) => {
  const { accessToken } = useAccessToken();

  const [fetchUser, isFetchingUser, userError, user] = useFetch<UserEntity | null>(async () => {
    if (accessToken) {
      const result = await userControllerGetCurrentUser({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return result.data || null;
    } else {
      return null;
    }
  });

  useEffect(() => {
    fetchUser();
  }, [accessToken]);

  const [fetchCars, isFetchingCars, carsError, cars] = useFetch<CarEntity[] | null>(async () => {
    if (user) {
      const result = await carControllerSearchCar({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        query: {
          userId: user.id,
        },
      });

      return result.data!;
    }

    return null;
  });

  useEffect(() => {
    fetchCars();
  }, [accessToken, user]);

  const [carsSelectOptions, setCarsSelectOptions] = useState<null | SelectOption<number>[]>(null);

  useEffect(() => {
    const selectOptions = cars?.map((car) => ({
      value: car.id,
      text: car.brand.name + ' ' + car.model.name,
    }));

    setCarsSelectOptions(selectOptions || null);
  }, [cars]);

  const [
    fetchMaintenaceCategories,
    isFetchingMaintenanceCategories,
    maintenanceCategoriesErrors,
    maintenanceCategories,
  ] = useFetch<MaintenanceCategoryEntity[] | null>(async () => {
    const result = await maintenanceCategoryControllerReadMaintenanceCategories({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result.data!;
  });

  useEffect(() => {
    fetchMaintenaceCategories();
  }, []);

  const [maintenanceCategoriesSelectOptions, setMaintenanceCategoriesSelectOptions] = useState<
    null | SelectOption<number>[]
  >(null);

  useEffect(() => {
    const selectOptions = maintenanceCategories?.map((category) => ({
      value: category.id,
      text: category.name,
    }));

    setMaintenanceCategoriesSelectOptions(selectOptions || null);
  }, [maintenanceCategories]);

  // ---

  const [formHintMessage, setFormHintMessage] = useState('');
  const [formHintClass, setFormHintClass] = useState('');

  const formResolver = classValidatorResolver(MaintenanceFormSchema);

  const {
    getValues,
    control,
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty: formIsDirty, dirtyFields },
  } = useForm<MaintenanceFormSchema>({
    resolver: formResolver,
    defaultValues: maintenance
      ? {
          ...maintenance,
          categoryId: maintenance.category.id || undefined,
          carId: maintenance.car?.id || undefined,
          fileUrls: maintenance.fileUrls || undefined,
          maintenancedAt: maintenance.maintenancedAt?.split('T')[0] || undefined,
          equipmentIds: maintenance.equipments?.map((equipment) => equipment.id) || [],
          ...(defaultValues || {}),
        }
      : {
          equipmentIds: [],
          ...defaultValues,
        },
  });

  const onValid: SubmitHandler<MaintenanceFormSchema> = async (data) => {
    const createRemind = data.createRemind === true;
    let cleanedData = cleanObjectByValues(data, [undefined]);
    cleanedData = cleanObjectByKeys(cleanedData, ['updateMileage', 'createRemind']);

    console.log(cleanedData);

    if (!maintenance?.id) {
      const result = await maintenanceControllerCreateMaintenance({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: cleanedData as CreateMaintenanceDto,
      });

      console.log('Ответ от сервера:', result);

      if (result.response.status === 201) {
        setFormHintClass('form__hint--green');
        onClose({
          maintenance: result.data!,
          text: 'ТО/Сервисная работа успешно добавлена',
          createRemind,
        });
      } else {
        setFormHintClass('form__hint--red');
        const errorObj = result.error as any;
        const errorMessage =
          errorObj.statusCode === 500 ? errorObj.message : errorObj.message?.join(', ');
        setFormHintMessage(errorMessage);
      }
    } else {
      const result = await maintenanceControllerUpdateMaintenance({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        path: {
          id: maintenance?.id,
        },
        body: cleanedData as UpdateMaintenanceDto,
      });

      console.log('Ответ от сервера:', result);

      if (result.response.status === 200) {
        setFormHintClass('form__hint--green');
        onClose({
          maintenance: result.data!,
          text: 'ТО/Сервисная работа успешно обновлена',
          createRemind,
        });
      } else {
        setFormHintClass('form__hint--red');
        const errorObj = result.error as any;
        const errorMessage =
          errorObj.statusCode === 500 ? errorObj.message : errorObj.message?.join(', ');
        setFormHintMessage(errorMessage);
      }
    }

    // ---

    if (data.updateMileage) {
      const result = await carControllerUpdateCar({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        path: {
          id: cleanedData.carId,
        },
        body: {
          mileage: data.mileage!,
        },
      });

      console.log('Обновление пробега:', result);
    }
  };

  const onInvalid: SubmitErrorHandler<MaintenanceFormSchema> = (error) => {
    console.log(error);

    setFormHintClass('form__hint--red');
  };

  const onSubmit = (event?: React.SyntheticEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    handleSubmit(onValid, onInvalid)();
  };

  const onRemove = async () => {
    const result = await maintenanceControllerDeleteMaintenance({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      path: {
        id: maintenance!.id,
      },
    });

    console.log('Ответ от сервера:', result);

    if (result.response.status === 204) {
      setFormHintClass('form__hint--green');
      onClose({ text: 'ТО/Сервисная работа удалена' });
    } else {
      setFormHintClass('form__hint--red');
      setFormHintMessage((result.error as any).message.join(', '));
    }
  };

  const onClickClose = () => {
    {
      if (formIsDirty && Object.keys(dirtyFields).length) {
        showDialog({
          text: 'Изменения не сохранены',
          yesButtonText: (
            <>
              сохранить и<br />
              перейти
            </>
          ),
          noButtonText: (
            <>
              перейти без
              <br />
              сохранения
            </>
          ),
          onClickYes: () => {
            hideDialog();
            onSubmit();
          },
          onClickNo: () => {
            hideDialog();
            onClose();
          },
        });
      } else {
        onClose();
      }
    }
  };

  // ---

  const [remindForUpdate, setRemindForUpdate] = useState<RemindEntity | null>(null);
  const [remindFormModalIsShowing, setRemindFormModalIsShowing] = useState(false);

  const onCloseRemindForm = (text?: string) => {
    setRemindFormModalIsShowing(false);
    setRemindForUpdate(null);

    if (text) {
      setMessageDialogModalText(text);
      setMessageDialogModalIsShowing(true);
    }
  };

  // ---

  const [messageDialogModalIsShowing, setMessageDialogModalIsShowing] = useState(false);
  const [messageDialogModalText, setMessageDialogModalText] = useState('');

  const {
    isShowing,
    text,
    yesButtonText,
    noButtonText,
    onClickYes,
    onClickNo,
    showDialog,
    hideDialog,
  } = useQuestionDialog();

  return (
    <>
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div className="form__header">
          <span className="form__header-title">
            {maintenance ? maintenance.name : 'Добавить ТО/Сервис'}
          </span>
          <span className="form__header-button button-go-back" onClick={() => onClickClose()}>
            <svg
              className="button-go-back-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z"></path>
            </svg>
            <span>{closeButtonText}</span>
          </span>
        </div>

        <div className="form__item form__item--required">
          <label htmlFor="car-name">Выберите авто</label>
          <div className="form__item-element">
            <Controller
              name="carId"
              control={control}
              render={({ field }) => (
                <Select
                  options={carsSelectOptions}
                  defaultValue={field.value}
                  onChange={(value) => {
                    field.onChange();
                    if (setSelectedCarId) setSelectedCarId(value as number);
                  }}
                  notAllowed={maintenance?.id !== undefined}
                />
              )}
            />
          </div>
        </div>

        {renderIf(
          () => [
            <div className="form__item form__item--required">
              <label htmlFor="car-name">Выберите категорию расходника</label>
              <div className="form__item-element">
                <Select
                  options={consumableTypeSelectOptions!}
                  defaultValue={selectedConsumableType}
                  onChange={(value) => {
                    console.log(value);
                    setSelectedConsumableType!(value as ConsumableType);
                  }}
                />
              </div>
            </div>,
          ],
          consumableTypeSelectOptions !== undefined &&
            selectedConsumableType !== undefined &&
            setSelectedConsumableType !== undefined,
        )}

        <div className="form__item form__item--required">
          <label htmlFor="car-name">Выберите категорию</label>
          <div className="form__item-element">
            <Controller
              name="categoryId"
              control={control}
              render={({ field }) => (
                <Select
                  options={maintenanceCategoriesSelectOptions}
                  defaultValue={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
        </div>

        <div className="form__item form__item--required">
          <label htmlFor="car-name">Название работ</label>
          <input {...register('name')} />
        </div>

        <div className="form__item">
          <label htmlFor="car-mileage">Пробег, км</label>
          <input
            type="number"
            {...register('mileage', {
              setValueAs: (value) => (value ? parseInt(value) : null),
            })}
          />
        </div>

        <div className="form__item form__item--row">
          <label className="checkbox checkbox__box--light">
            <input type="checkbox" {...register('updateMileage')} />
            <span className="checkbox__box"></span>
            <span className="checkbox__text">Обновить пробег</span>
          </label>
          <Hint
            text={`
                После сохранения у выбранного автомобиля обновится пробег на указанное значение
                `}
          />
        </div>

        <div className="form__item form__item--required">
          <label htmlFor="car-name">Дата</label>
          <input style={{ width: '100%' }} type="date" {...register('maintenancedAt')} />
        </div>

        <div className="form__item">
          <label htmlFor="car-name">Место</label>
          <input
            {...register('place', {
              setValueAs: (value) => value || null,
            })}
          />
        </div>

        <div className="form__item">
          <label htmlFor="car-name">Стоимость, руб</label>
          <input
            type="number"
            step="0.01"
            {...register('cost', {
              setValueAs: (value) => (value ? parseFloat(value) : null),
            })}
          />
        </div>

        <div className="form__item">
          <label htmlFor="notes">Заметки</label>
          <textarea
            {...register('notes', {
              setValueAs: (value) => value || null,
            })}
          ></textarea>
        </div>

        <div className="form__item">
          <label htmlFor="car-name">Запчасти / расходники</label>
          <div className="form__item-element">
            <Controller
              name="equipmentIds"
              control={control}
              render={({ field }) => (
                <EquipmentQuickList
                  carId={getValues('carId')}
                  defaultValues={field.value}
                  onChange={(value) => {
                    console.log('value', value);
                    field.onChange(value || undefined);
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className="form__item">
          <label htmlFor="car-name">Документы</label>
          <div className="form__item-element">
            <Controller
              name="fileUrls"
              control={control}
              render={({ field }) => <FileLoader images={field.value} onChange={field.onChange} />}
            />
          </div>
        </div>

        {maintenance === null || maintenance?.reminds?.length === 0 ? (
          <div className="form__item form__item--row">
            <label className="checkbox checkbox__box--light">
              <input type="checkbox" {...register('createRemind')} />
              <span className="checkbox__box"></span>
              <span className="checkbox__text">Создать напоминание</span>
            </label>
            <Hint
              text={`
                  После сохранения ТО/Сервиса вы будете перенаправлены на дозаполнение напоминания
                `}
            />
          </div>
        ) : (
          <div>
            Напоминание создано.{' '}
            <span
              onClick={() => {
                setRemindForUpdate(maintenance?.reminds![0]!);
                setRemindFormModalIsShowing(true);
              }}
              style={{ color: 'rgba(30, 136, 229, 1)', fontWeight: 400, cursor: 'pointer' }}
            >
              Посмотреть
            </span>
          </div>
        )}

        <hr className="separator separator--empty" />

        <button type="submit" className="button--primary">
          Сохранить
        </button>

        <div className={`form__hint ${formHintClass}`}>
          {formHintMessage}
          {Object.entries(formErrors).map(([field, { message }]) => (
            <p key={field}>{message}</p>
          ))}
        </div>

        {maintenance && (
          <button
            onClick={() => {
              showDialog({
                text: 'Удалить ТО/Сервисную работу?',
                yesButtonText: 'ДА',
                noButtonText: 'НЕТ',
                onClickYes: () => onRemove(),
                onClickNo: () => hideDialog(),
              });
            }}
            className="button button--light"
            type="button"
          >
            <svg className="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
            </svg>
            удалить
          </button>
        )}
      </form>

      <FormModal
        title={remindForUpdate?.name || 'Добавить напоминание'}
        closeButtonText={
          <>
            Вернуться ко
            <br />
            всем напоминаниям
          </>
        }
        isShowing={remindFormModalIsShowing}
        onClose={() => {
          setRemindFormModalIsShowing(false);
        }}
      >
        <RemindForm
          remind={remindForUpdate}
          onClose={onCloseRemindForm}
          closeButtonText={
            <>
              Вернуться к
              <br />
              параметрам ТО\Сервиса
            </>
          }
        />
      </FormModal>

      <MessageDialogModal
        isShowing={messageDialogModalIsShowing}
        text={messageDialogModalText}
        onClose={() => {
          setMessageDialogModalText('');
          setMessageDialogModalIsShowing(false);
        }}
      />

      <QuestionDialogModal
        isShowing={isShowing}
        text={text}
        yesButtonText={yesButtonText}
        noButtonText={noButtonText}
        onClickYes={onClickYes}
        onClickNo={onClickNo}
      />
    </>
  );
};
