import { IsEmail, IsNotEmpty, MinLength } from 'class-validator';

export class UserResetPasswordGetHashSchema {
  @IsNotEmpty({
    message: 'Укажите email аккаунта для которого требуется восстановиться пароль',
  })
  @IsEmail(
    {},
    {
      message: 'Укажите email аккаунта для которого требуется восстановиться пароль',
    },
  )
  @MinLength(6, {
    message: 'Укажите email аккаунта для которого требуется восстановиться пароль',
  })
  email: string;
}
