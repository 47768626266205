export enum ConsumableType {
  REFUEL  = 'REFUEL',
  CAR_WASH = 'CAR_WASH',
  FINES_PAYMENT = 'FINES_PAYMENT',
  PARK_PAYMENT = 'PARK_PAYMENT',
  OTHER = 'OTHER',
  MAINTENANCE = 'MAINTENANCE'
}

export const ConsumableTypeName = {
  [ConsumableType.REFUEL]: 'Заправка',
  [ConsumableType.CAR_WASH]: 'Мойка',
  [ConsumableType.FINES_PAYMENT]: 'Оплата штрафов',
  [ConsumableType.PARK_PAYMENT]: 'Оплата парковки',
  [ConsumableType.OTHER]: 'Другое',
  [ConsumableType.MAINTENANCE]: 'ТО и сервисные работы',
} as const;

export function getConsumableTypeName(type: ConsumableType): string {
  return ConsumableTypeName[type];
}

export const getConsumableTypeSelectOptions = () => {
  return Object.values(ConsumableType).map((value) => ({
    value: value as String,
    text: ConsumableTypeName[value as ConsumableType],
  }));
};
