import { useState, useEffect } from 'react';
import { useAccessToken } from './useAccessToken';
import { useFetch } from './useFetch';
import { UserEntity, userControllerGetCurrentUser } from '../clients/VgarageApi';

interface UseUserIdType {
  userId: number | null;
  setUserId: (userId: number | null) => void;
}

interface UseUserIdOptions {
  required?: boolean;
}

export const useUserId = (options: UseUserIdOptions = { required: true }): UseUserIdType => {
  const [userId, setUserId] = useState<number | null>(
    parseInt(localStorage.getItem('userId') || '') || null,
  );
  const { accessToken } = useAccessToken();

  const [fetchUser, isFetchingUser, userError, user] = useFetch<UserEntity | null>(async () => {
    const result = await userControllerGetCurrentUser({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return result.data as UserEntity;
  });

  useEffect(() => {
    if (options.required) {
      fetchUser();
    }
  }, [accessToken, options.required]);

  useEffect(() => {
    if (userId !== null) {
      localStorage.setItem('userId', userId.toString());
    } else {
      localStorage.removeItem('accessToken');
    }
  }, [userId]);

  return { userId, setUserId };
};
