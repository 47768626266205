import {
  IsEmail,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsPhoneNumber,
  Length,
  Max,
  Min,
  MinLength,
} from 'class-validator';

export class UserFormSchema {
  @IsNotEmpty()
  @MinLength(1)
  name: string;

  @IsOptional()
  @IsNotEmpty()
  @MinLength(8, { message: 'Пароль должен содержать минимум 8 символов' })
  password: string;

  @IsOptional()
  @IsPhoneNumber('RU', {
    message: 'Номер телефона должен быть в формате +79234445566',
  })
  @MinLength(12, {
    message: 'Номер телефона должен быть в формате +79234445566',
  })
  phone: string | null;

  @IsEmail()
  @MinLength(6)
  email: string;

  @IsOptional()
  @IsNumber()
  @Max(2147483647)
  @Min(0)
  cityId: number | null;

  @IsOptional()
  @IsNumber()
  @Max(2147483647)
  @Min(0)
  mainCarId: number | null;
}
