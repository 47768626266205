import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { guideCategoryControllerFindAll, GuideCategoryEntity } from '../../clients/VgarageApi';
import { useFetch } from '../../hooks/useFetch';
import './Guide.scss';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { guideCategoriesScrollPositionContext } from '../App/App';

interface GuideCategoriesListProps {
  selectCategory?: number | null;
}

export const GuideCategoriesList = ({ selectCategory = null }: GuideCategoriesListProps) => {
  const navigate = useNavigate();

  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(selectCategory);

  const { guideCategoriesScrollPosition, setGuideCategoriesScrollPosition } = useContext(
    guideCategoriesScrollPositionContext,
  );

  const [fetchGuideCategories, isFetchingGuideCategories, guieCategoriesError, guideCategories] =
    useFetch<GuideCategoryEntity[] | null>(async () => {
      const result = await guideCategoryControllerFindAll({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      });
      if (result.data && Array.isArray(result.data) && result.data.every((item) => 'id' in item)) {
        result.data = result.data.sort((a, b) => a.id - b.id);
        setSelectedCategoryId(result.data[0].id);
        result.data.forEach((category) => {
          if (selectCategory) {
            if (category.id == selectCategory) {
              setSelectedCategoryId(selectCategory);
            }
          }
        });

        return result.data;
      } else {
        return [];
      }
    });

  useEffect(() => {
    fetchGuideCategories();
  }, [selectCategory]);

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTo({
        left: guideCategoriesScrollPosition,
      });
    }
  }, [guideCategories]);

  const handleScroll = (e: React.UIEvent) => {
    if (divRef.current) {
      setGuideCategoriesScrollPosition(divRef.current.scrollLeft);
    }
  };

  return (
    <div className="guide--categories_list" ref={divRef} onScroll={handleScroll}>
      {guideCategories?.map((guideCategory) => {
        if (selectedCategoryId == guideCategory.id) {
          return (
            <button
              onClick={() => navigate(`/guide-categories/${guideCategory.id}`)}
              className="guide__categories-button guide__categories-button--active"
              key={guideCategory.id}
            >
              {guideCategory.name}
            </button>
          );
        } else {
          return (
            <button
              onClick={() => navigate(`/guide-categories/${guideCategory.id}`)}
              className="guide__categories-button"
              key={guideCategory.id}
            >
              {guideCategory.name}
            </button>
          );
        }
      })}
    </div>
  );
};
