import {
  IsDateString,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  Max,
  Min,
  ValidateIf,
} from 'class-validator';

export type PartialRemindFormSchema = Partial<RemindFormSchema>;

export class RemindFormSchema {
  @IsNotEmpty({ message: 'Укажите название' })
  name: string;

  @IsOptional()
  @IsNotEmpty({ message: 'Укажите пробег' })
  @IsNumber()
  @Max(2147483647)
  @Min(0)
  mileage: number | null;

  @IsOptional()
  @IsDateString({}, { message: 'Укажите дату в формате 2024-12-23' })
  date: string | null;

  @IsOptional()
  @IsNotEmpty()
  notes: string | null;

  @ValidateIf(
    (obj) =>
      obj.carId ||
      (obj.maintenanceId === undefined &&
        obj.equipmentId === undefined &&
        obj.documentId === undefined),
  )
  @IsNumber()
  @Max(2147483647)
  @Min(0, { message: 'Выберите авто' })
  carId: number | null;

  @IsOptional()
  @IsNumber()
  @Max(2147483647)
  @Min(0, { message: 'Выберите ТО/Сервисную работу' })
  maintenanceId: number | null;

  @IsOptional()
  @IsNumber()
  @Max(2147483647)
  @Min(0, { message: 'Выберите запчасть' })
  equipmentId: number | null;

  @IsOptional()
  @IsNumber()
  @Max(2147483647)
  @Min(0, { message: 'Выберите документ' })
  documentId: number | null;

  @IsNotEmpty({ message: 'Укажите категорию' })
  @IsNumber()
  @Max(2147483647)
  @Min(0, { message: 'Укажите категорию' })
  typeId: number;
}
