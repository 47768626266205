import React, { useEffect, useState } from 'react';
import { useAccessToken } from '../../hooks/useAccessToken';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { cleanObjectByValues } from '../../utils/cleanObject';
import { Select, SelectOption } from '../Select/Select';
import { useFetch } from '../../hooks/useFetch';
import { PtsFormSchema } from './schemas/PtsFormSchema';
import { FileLoader } from '../FileLoader/FileLoader';
import { MessageDialogModal } from '../Modal/MessageDialogModal';
import { FormModal } from '../Modal/FormModal';
import { RemindForm } from './RemindForm';
import { Hint } from '../Hint/Hint';
import { DocumentType, getDocumentTypeName } from '../../types/DocumentType';
import { QuestionDialogModal, useQuestionDialog } from '../Modal/QuestionDialogModal';
import {
  CarEntity,
  CreatePtsDocumentDto,
  PtsDocumentEntity,
  RemindEntity,
  UpdatePtsDocumentDto,
  UserEntity,
  carControllerSearchCar,
  ptsControllerCreatePtsDoc,
  ptsControllerDeletePtsDoc,
  ptsControllerUpdatePtsDoc,
  userControllerGetCurrentUser,
} from '../../clients/VgarageApi';

interface PtsFormProps {
  ptsDocument?: PtsDocumentEntity | null;
  defaultValues?: Partial<PtsFormSchema> | undefined;
  onClose: (options?: {
    ptsDocument?: PtsDocumentEntity;
    text?: string;
    createRemind?: boolean;
  }) => void;
  closeButtonText?: React.ReactNode | undefined;
}

export const PtsForm = ({
  ptsDocument = null,
  defaultValues = undefined,
  onClose,
  closeButtonText,
}: PtsFormProps) => {
  const { accessToken } = useAccessToken();

  const [fetchUser, isFetchingUser, userError, user] = useFetch<UserEntity | null>(async () => {
    if (accessToken) {
      const result = await userControllerGetCurrentUser({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return result.data || null;
    } else {
      return null;
    }
  });

  useEffect(() => {
    fetchUser();
  }, [accessToken]);

  const [fetchCars, isFetchingCars, carsError, cars] = useFetch<CarEntity[] | null>(async () => {
    if (user) {
      const result = await carControllerSearchCar({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        query: {
          userId: user.id,
        },
      });

      return result.data!;
    }

    return null;
  });

  useEffect(() => {
    fetchCars();
  }, [accessToken, user]);

  const [carsSelectOptions, setCarsSelectOptions] = useState<null | SelectOption<number>[]>(null);

  useEffect(() => {
    const carsSelectOptions = cars?.map((car) => ({
      value: car.id,
      text: car.brand.name + ' ' + car.model.name,
    }));

    setCarsSelectOptions(carsSelectOptions || null);
  }, [cars]);

  // ---

  const [formHintMessage, setFormHintMessage] = useState('');
  const [formHintClass, setFormHintClass] = useState('');

  const formResolver = classValidatorResolver(PtsFormSchema);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors: formErrors, isDirty: formIsDirty, dirtyFields },
  } = useForm<PtsFormSchema>({
    resolver: formResolver,
    defaultValues: ptsDocument
      ? {
          ...ptsDocument,
          carId: ptsDocument.car.id,
          fileUrls: ptsDocument.fileUrls || undefined,
          ...(defaultValues || {}),
        }
      : defaultValues,
  });

  const onValid: SubmitHandler<PtsFormSchema> = async (data) => {
    console.log(data);
    const createRemind = data.createRemind === true;
    let cleanedData = cleanObjectByValues(data, [undefined]);

    console.log(cleanedData);

    if (ptsDocument === null) {
      const result = await ptsControllerCreatePtsDoc({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: cleanedData as CreatePtsDocumentDto,
      });

      console.log('Ответ от сервера:', result);

      if (result.response.status === 201) {
        setFormHintClass('form__hint--green');
        onClose({ text: 'Документ успешно добавлен', ptsDocument: result.data!, createRemind });
      } else {
        setFormHintClass('form__hint--red');
        setFormHintMessage((result.error as any).message.join(', '));
      }
    } else {
      const result = await ptsControllerUpdatePtsDoc({
        baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        path: {
          id: ptsDocument.id,
        },
        body: cleanedData as UpdatePtsDocumentDto,
      });

      console.log('Ответ от сервера:', result);

      if (result.response.status === 200) {
        setFormHintClass('form__hint--green');
        onClose({ text: 'Документ успешно обновлен', ptsDocument: result.data!, createRemind });
      } else {
        setFormHintClass('form__hint--red');
        setFormHintMessage((result.error as any).message.join(', '));
      }
    }
  };

  const onInvalid: SubmitErrorHandler<PtsFormSchema> = (error) => {
    console.log(error);

    setFormHintClass('form__hint--red');
  };

  const onSubmit = (event?: React.SyntheticEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    handleSubmit(onValid, onInvalid)();
  };

  const onRemove = async () => {
    const result = await ptsControllerDeletePtsDoc({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      path: {
        id: ptsDocument!.id,
      },
    });

    console.log('Ответ от сервера:', result);

    if (result.response.status === 204) {
      setFormHintClass('form__hint--green');
      onClose({ text: 'Документ удален' });
    } else {
      setFormHintClass('form__hint--red');
      setFormHintMessage((result.error as any).message.join(', '));
    }
  };

  const onClickClose = () => {
    {
      if (formIsDirty && Object.keys(dirtyFields).length) {
        showDialog({
          text: 'Изменения не сохранены',
          yesButtonText: (
            <>
              сохранить и<br />
              перейти
            </>
          ),
          noButtonText: (
            <>
              перейти без
              <br />
              сохранения
            </>
          ),
          onClickYes: () => {
            hideDialog();
            onSubmit();
          },
          onClickNo: () => {
            hideDialog();
            onClose();
          },
        });
      } else {
        onClose();
      }
    }
  };

  // ---

  const [remindForUpdate, setRemindForUpdate] = useState<RemindEntity | null>(null);
  const [remindFormModalIsShowing, setRemindFormModalIsShowing] = useState(false);

  const onCloseRemindForm = (text?: string) => {
    setRemindFormModalIsShowing(false);
    setRemindForUpdate(null);

    if (text) {
      setMessageDialogModalText(text);
      setMessageDialogModalIsShowing(true);
    }
  };

  // ---

  const [messageDialogModalIsShowing, setMessageDialogModalIsShowing] = useState(false);
  const [messageDialogModalText, setMessageDialogModalText] = useState('');

  const {
    isShowing,
    text,
    yesButtonText,
    noButtonText,
    onClickYes,
    onClickNo,
    showDialog,
    hideDialog,
  } = useQuestionDialog();

  return (
    <>
      <form className="form" onSubmit={handleSubmit(onValid, onInvalid)}>
        <div style={{ position: 'absolute', top: '50px', width: '91%' }} className="form__header">
          <span className="form__header-title">{getDocumentTypeName(DocumentType.PTS)}</span>
          <span className="form__header-button button-go-back" onClick={() => onClickClose()}>
            <svg
              className="button-go-back-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M22.707,12.707a1,1,0,0,0,0-1.414l-5-5a1,1,0,0,0-1.414,1.414L19.586,11H2a1,1,0,0,0,0,2H19.586l-3.293,3.293a1,1,0,0,0,1.414,1.414Z"></path>
            </svg>
            <span>{closeButtonText}</span>
          </span>
        </div>

        <div className="form__item form__item--required">
          <label htmlFor="car-name">Выберите авто</label>
          <div className="form__item-element">
            <Controller
              name="carId"
              control={control}
              render={({ field }) => (
                <Select
                  options={carsSelectOptions}
                  defaultValue={field.value}
                  onChange={field.onChange}
                  notAllowed={ptsDocument !== null}
                />
              )}
            />
          </div>
        </div>

        <div className="form__item ">
          <label htmlFor="car-name">Номер</label>
          <input
            {...register('number', {
              setValueAs: (value) => value || null,
            })}
          />
        </div>

        <div className="form__item">
          <label htmlFor="car-name">Заметки</label>
          <textarea
            {...register('notes', {
              setValueAs: (value) => value || null,
            })}
          />
        </div>

        <div className="form__item">
          <label htmlFor="car-name">Файлы</label>
          <div className="form__item-element">
            <Controller
              name="fileUrls"
              control={control}
              render={({ field }) => <FileLoader images={field.value} onChange={field.onChange} />}
            />
          </div>
        </div>

        {ptsDocument === null || ptsDocument?.reminds?.length === 0 ? (
          <div className="form__item form__item--row">
            <label className="checkbox checkbox__box--light">
              <input type="checkbox" {...register('createRemind')} />
              <span className="checkbox__box"></span>
              <span className="checkbox__text">Создать напоминание</span>
            </label>
            <Hint
              text={`
                  После сохранения документа вы будете перенаправлены на дозаполнение напоминания
                `}
            />
          </div>
        ) : (
          <div>
            Напоминание создано.{' '}
            <span
              onClick={() => {
                setRemindForUpdate(ptsDocument?.reminds[0]!);
                setRemindFormModalIsShowing(true);
              }}
              style={{
                color: 'rgba(30, 136, 229, 1)',
                fontWeight: 400,
                cursor: 'pointer',
              }}
            >
              Посмотреть
            </span>
          </div>
        )}

        <hr className="separator separator--empty" />

        <button type="submit" className="button--primary">
          Сохранить
        </button>

        <div className={`form__hint ${formHintClass}`}>
          {formHintMessage}
          {Object.entries(formErrors).map(([field, { message }]) => (
            <p key={field}>{message}</p>
          ))}
        </div>

        {ptsDocument && (
          <button
            onClick={() => {
              showDialog({
                text: 'Удалить документ?',
                yesButtonText: 'ДА',
                noButtonText: 'НЕТ',
                onClickYes: () => onRemove(),
                onClickNo: () => hideDialog(),
              });
            }}
            className="button button--light"
            type="button"
          >
            <svg className="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path>
            </svg>
            удалить
          </button>
        )}
      </form>

      <FormModal isShowing={remindFormModalIsShowing}>
        <RemindForm
          remind={remindForUpdate}
          onClose={onCloseRemindForm}
          closeButtonText={
            <>
              Вернуться к
              <br />
              параметрам документа
            </>
          }
        />
      </FormModal>

      <MessageDialogModal
        isShowing={messageDialogModalIsShowing}
        text={messageDialogModalText}
        onClose={() => {
          setMessageDialogModalText('');
          setMessageDialogModalIsShowing(false);
        }}
      />

      <QuestionDialogModal
        isShowing={isShowing}
        text={text}
        yesButtonText={yesButtonText}
        noButtonText={noButtonText}
        onClickYes={onClickYes}
        onClickNo={onClickNo}
      />
    </>
  );
};
