import React, { useEffect, useState } from 'react';
import { DocumentType, getDocumentTypeSelectOptions } from '../../types/DocumentType';
import { Select, SelectOption } from '../Select/Select';
import { DriverLicenseForm } from './DriverLicenseForm';
import { renderIf } from '../../utils/renderIf';
import { CascoForm } from './CascoForm';
import { PtsForm } from './PtsForm';
import { EptsForm } from './EptsForm';
import { StsForm } from './StsForm';
import { OsagoForm } from './OsagoForm';
import { OtherDocumentForm } from './OtherDocumentForm';
import {
  CascoDocumentEntity,
  DocumentEntity,
  DriverLicenseEntity,
  EptsDocumentEntity,
  OsagoDocumentEntity,
  OtherDocumentEntity,
  PtsDocumentEntity,
  StsDocumentEntity,
} from '../../clients/VgarageApi';

export interface onCloseDocumentFormOptions {
  document?: DocumentEntity;
  text?: string;
  createRemind?: boolean;
}

export interface DocumenFormProps {
  defaultValues?: { type?: DocumentType; carId: number | undefined } | undefined;
  document?: DocumentEntity | null;
  onClose: (options?: onCloseDocumentFormOptions) => void;
  closeButtonText?: React.ReactNode | undefined;
}

export const DocumentForm = ({
  document = null,
  defaultValues = undefined,
  closeButtonText,
  onClose,
}: DocumenFormProps) => {
  const [documentType, setDocumentType] = useState<DocumentType | null>(
    ((document?.type || defaultValues) as DocumentType) || DocumentType.OSAGO,
  );

  useEffect(() => {
    setDocumentType(
      ((document?.type || defaultValues?.type) as DocumentType) || DocumentType.OSAGO,
    );
  }, [document, defaultValues]);

  const documentTypeSelectOptions = getDocumentTypeSelectOptions() as SelectOption<string>[];

  return (
    <>
      <div style={{ marginTop: '50px' }} className="form">
        <div className="form__item form__item--required">
          <label htmlFor="car-name">Выберите тип документа</label>
          <div className="form__item-element">
            <Select
              options={documentTypeSelectOptions}
              defaultValue={documentType}
              onChange={(value) => setDocumentType(value as DocumentType)}
              notAllowed={document?.type !== undefined || defaultValues?.type !== undefined}
              deselectOnDubleClick={false}
            />
          </div>
        </div>
      </div>

      <hr className="separator separator--empty separator--light"></hr>

      {renderIf(
        () => [
          <DriverLicenseForm
            driverLicenseDocument={document as DriverLicenseEntity}
            onClose={(options) =>
              onClose({ ...options, document: options?.driverLicenseDocument || undefined })
            }
            closeButtonText={closeButtonText}
          />,
        ],
        documentType === DocumentType.DRIVER_LICENSE,
      )}

      {renderIf(
        () => [
          <OsagoForm
            defaultValues={defaultValues?.carId ? { carId: defaultValues?.carId } : undefined}
            osagoDocument={document as OsagoDocumentEntity}
            onClose={(options) =>
              onClose({ ...options, document: options?.osagoDocument || undefined })
            }
            closeButtonText={closeButtonText}
          />,
        ],
        documentType === DocumentType.OSAGO,
      )}

      {renderIf(
        () => [
          <CascoForm
            defaultValues={defaultValues?.carId ? { carId: defaultValues?.carId } : undefined}
            cascoDocument={document as CascoDocumentEntity}
            onClose={(options) =>
              onClose({ ...options, document: options?.cascoDocument || undefined })
            }
            closeButtonText={closeButtonText}
          />,
        ],
        documentType === DocumentType.CASCO,
      )}

      {renderIf(
        () => [
          <EptsForm
            defaultValues={defaultValues?.carId ? { carId: defaultValues?.carId } : undefined}
            eptsDocument={document as EptsDocumentEntity}
            onClose={(options) =>
              onClose({ ...options, document: options?.eptsDocument || undefined })
            }
            closeButtonText={closeButtonText}
          />,
        ],
        documentType === DocumentType.EPTS,
      )}

      {renderIf(
        () => [
          <PtsForm
            defaultValues={defaultValues?.carId ? { carId: defaultValues?.carId } : undefined}
            ptsDocument={document as PtsDocumentEntity}
            onClose={(options) =>
              onClose({ ...options, document: options?.ptsDocument || undefined })
            }
            closeButtonText={closeButtonText}
          />,
        ],
        documentType === DocumentType.PTS,
      )}

      {renderIf(
        () => [
          <StsForm
            defaultValues={defaultValues?.carId ? { carId: defaultValues?.carId } : undefined}
            stsDocument={document as StsDocumentEntity}
            onClose={(options) =>
              onClose({ ...options, document: options?.stsDocument || undefined })
            }
            closeButtonText={closeButtonText}
          />,
        ],
        documentType === DocumentType.STS,
      )}

      {renderIf(
        () => [
          <OtherDocumentForm
            defaultValues={defaultValues?.carId ? { carId: defaultValues?.carId } : undefined}
            otherDocument={document as OtherDocumentEntity}
            onClose={(options) =>
              onClose({ ...options, document: options?.otherDocument || undefined })
            }
            closeButtonText={closeButtonText}
          />,
        ],
        documentType === DocumentType.OTHER,
      )}
    </>
  );
};
