import { useEffect, useState } from 'react';
import { useWindowDimensions } from './useWindowDimensions';

type UseTabsType = [boolean, boolean, () => void];

export const useTabs = (): UseTabsType => {
  const [carPartsIsShowing, setCarPartsIsShowing] = useState(false);
  const [showAllTabs, setShowAllTabs] = useState(true);
  const [{ width: vpWidth }] = useWindowDimensions();

  useEffect(() => {
    if (vpWidth < 1200) {
      setShowAllTabs(false);
    } else {
      setShowAllTabs(true);
    }
  }, [vpWidth]);

  const toggleTabs = () => setCarPartsIsShowing(!carPartsIsShowing);

  return [showAllTabs, carPartsIsShowing, toggleTabs];
};
