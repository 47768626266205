import React, { useEffect, useState } from 'react';
import { Layout } from '../components/Layout/Layout';
import { Spinner } from '../components/Spinner/Spinner';
import { useAccessToken } from '../hooks/useAccessToken';
import { useFetch } from '../hooks/useFetch';
import {
  CarEntity,
  carControllerSearchCar,
  consumablesControllerGetCosumablesCost,
} from '../clients/VgarageApi';
import { Modal } from '../components/Modal/Modal';
import { CarList } from '../components/CarList/CarList';
import { QuestionDialogModal, useQuestionDialog } from '../components/Modal/QuestionDialogModal';
import '../styles/Consumables.scss';
import { renderIf } from '../utils/renderIf';
import { ConsumableList } from '../components/ConnsumablesList/ConsumablesList';
import { MessageDialogModal } from '../components/Modal/MessageDialogModal';

export const ConsumablesListPage = () => {
  const { accessToken } = useAccessToken();

  let [fetchCars, isFetchingCars, carsError, cars] = useFetch<
    (CarEntity & { consumableCost?: string })[] | null
  >(async () => {
    const result = await carControllerSearchCar({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return result.data!;
  });

  const [fetchCarsConsumCost, isFetchingCarsConsumCost, carsConsumCostError, carsConsumCost] =
    useFetch<(CarEntity & { consumableCost?: string })[] | null>(async () => {
      if (cars) {
        const result = await cars.map(async (car) => {
          var date = new Date();
          date.setDate(1);
          date.setHours(0, 0, 0, 0);
          if (switchInTheYearState) {
            date.setMonth(0);
          }

          const result = await consumablesControllerGetCosumablesCost({
            baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            query: {
              carId: car.id,
              date: date.toISOString(),
            },
          });
          if (result.data) {
            car.consumableCost = (Math.round(result.data * 100) / 100).toString();
          }
          return car;
        });
        return Promise.all(result);
      }

      return null;
    });

  const {
    isShowing,
    text,
    yesButtonText,
    noButtonText,
    onClickYes,
    onClickNo,
    showDialog,
    hideDialog,
  } = useQuestionDialog();

  const [switchInTheYearState, setSwitchInTheYearState] = useState(true);

  const [modalCarListIsShowing, setModalCarListIsShowing] = useState(false);

  useEffect(() => {
    fetchCars();
  }, [accessToken]);

  useEffect(() => {
    fetchCarsConsumCost();
  }, [accessToken, cars, switchInTheYearState, isShowing]);

  // ---

  const [messageDialogModalIsShowing, setMessageDialogModalIsShowing] = useState(false);
  const [messageDialogModalText, setMessageDialogModalText] = useState<React.ReactNode>(
    (
      <>
        <a style={{ color: 'white', fontSize: '16px' }}>Раздел уже в разработке!</a>
      </>
    ) || '',
  );

  return (
    <>
      <Layout>
        <main className="main">
          {isFetchingCars && <Spinner />}

          {cars && (
            <div className="car-info">
              <div className="car-info__title">
                <span className="car-info__title-text">АВТОРАСХОДЫ</span>
              </div>

              <div className="consumables_analytics-switch">
                <button className="consumables_analytics-switch_button consumables_analytics-switch_button--active">
                  <svg
                    className="consumables_analytics-switch_button--icon--expenses"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 64 64"
                  >
                    <path d="M32,58A29,29,0,1,1,61,29,29.03339,29.03339,0,0,1,32,58ZM32,2A27,27,0,1,0,59,29,27.03077,27.03077,0,0,0,32,2Z"></path>
                    <path d="M32,55A26,26,0,1,1,58,29,26.02885,26.02885,0,0,1,32,55ZM32,5A24,24,0,1,0,56,29,24.0275,24.0275,0,0,0,32,5Z"></path>
                    <path d="M11 30a.99974.99974 0 01-1-1A22.02489 22.02489 0 0132 7a1 1 0 010 2A20.0226 20.0226 0 0012 29 .99974.99974 0 0111 30zM32 51a1 1 0 010-2A20.02229 20.02229 0 0052 29a1 1 0 012 0A22.02489 22.02489 0 0132 51z"></path>
                    <path d="M32,64A29.03339,29.03339,0,0,1,3,35a1,1,0,0,1,2,0,27,27,0,0,0,54,0,1,1,0,0,1,2,0A29.03339,29.03339,0,0,1,32,64Z"></path>
                    <path d="M4 36a.99943.99943 0 01-1-1V29a1 1 0 012 0v6A.99943.99943 0 014 36zM60 36a.99943.99943 0 01-1-1V29a1 1 0 012 0v6A.99943.99943 0 0160 36zM27 44a.99943.99943 0 01-1-1V16a1 1 0 012 0V43A.99943.99943 0 0127 44zM35 33a1 1 0 010-2 7 7 0 000-14 1 1 0 010-2 9 9 0 010 18z"></path>
                    <path d="M35 33H24a1 1 0 010-2H35a1 1 0 010 2zM35 17H27a1 1 0 010-2h8a1 1 0 010 2zM39 38H24a1 1 0 010-2H39a1 1 0 010 2zM32 64a.99943.99943 0 01-1-1V57a1 1 0 012 0v6A.99943.99943 0 0132 64zM43 61.75684a.99943.99943 0 01-1-1v-6a1 1 0 012 0v6A.99943.99943 0 0143 61.75684zM53 54.51953a.99942.99942 0 01-1-1v-6.001a1 1 0 012 0v6.001A.99942.99942 0 0153 54.51953zM21 61.75684a.99943.99943 0 01-1-1v-6a1 1 0 012 0v6A.99943.99943 0 0121 61.75684zM11 54.51953a.99942.99942 0 01-1-1v-6.001a1 1 0 012 0v6.001A.99942.99942 0 0111 54.51953z"></path>
                  </svg>
                  Расходы
                </button>
                <button
                  onClick={() => setMessageDialogModalIsShowing(true)}
                  className="consumables_analytics-switch_button"
                >
                  <svg
                    className="consumables_analytics-switch_button--icon--analytics"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M336,286c0,60.654-49.346,110-110,110c-32.571,0-63.285-14.325-84.266-39.302l0,0C125.14,336.941,116,311.833,116,286   c0-60.654,49.346-110,110-110h15V60h-15c-59.989,0-116.639,23.612-159.514,66.487C23.612,169.361,0,226.011,0,286   c0,53.044,18.777,104.636,52.874,145.27C95.924,482.575,159.026,512,226,512c59.989,0,116.639-23.612,159.514-66.487   C428.388,402.639,452,345.989,452,286v-15H336V286z M30,286c0-51.976,20.491-101.092,57.7-138.3   c33.608-33.609,76.929-53.579,123.3-57.128v56.226C140.828,154.304,86,213.869,86,286c0,27.845,8.347,55.028,23.734,77.978   l-43.069,36.139C42.936,366.961,30,326.984,30,286z M421.428,301c-3.549,46.371-23.519,89.691-57.128,123.3   C327.092,461.508,277.976,482,226,482c-53.055,0-103.299-21.302-140.057-58.897l43.077-36.146C154.988,411.927,189.578,426,226,426   c72.131,0,131.696-54.828,139.202-125H421.428z"></path>
                    <path d="M494.791,139.516c-11.419-27.568-27.9-52.237-48.985-73.322s-45.754-37.566-73.321-48.985C344.916,5.79,315.818,0,286,0   h-15v116h15c60.654,0,110,49.346,110,110v15h116v-15C512,196.181,506.21,167.084,494.791,139.516z M425.202,211   C418.224,145.756,366.244,93.777,301,86.798v-56.23c20.617,1.568,40.74,6.378,60.004,14.357   c23.909,9.904,45.304,24.197,63.589,42.481c18.285,18.285,32.578,39.68,42.482,63.59c7.979,19.263,12.789,39.386,14.356,60.003   H425.202z"></path>
                  </svg>
                  Аналитика
                </button>
              </div>

              <div className="consumables_car_info">
                <div>
                  {carsConsumCost &&
                    carsConsumCost.map((car) => {
                      return (
                        <div key={car.id}>
                          <span style={{ paddingRight: '40px', fontSize: '14px' }}>
                            {car.brand.name} {car.model.name}
                          </span>
                          <span style={{ fontSize: '14px', color: 'rgba(15, 247, 81, 1)  ' }}>
                            {car.consumableCost!} р
                          </span>
                        </div>
                      );
                    })}
                </div>

                {renderIf(
                  () => [
                    <div
                      className="consumables_button-switch"
                      onClick={() => {
                        setSwitchInTheYearState(false);
                      }}
                    >
                      <button className="consumables_button-switch__item consumables_button-switch__item--active">
                        С начала года
                      </button>

                      <button className="consumables_button-switch__item">За месяц</button>
                    </div>,
                  ],
                  switchInTheYearState,
                )}
                {renderIf(
                  () => [
                    <div
                      className="consumables_car_info-element consumables_button-switch"
                      onClick={() => {
                        setSwitchInTheYearState(true);
                      }}
                    >
                      <button className="consumables_button-switch__item">С начала года</button>

                      <button className="consumables_button-switch__item consumables_button-switch__item--active">
                        За месяц
                      </button>
                    </div>,
                  ],
                  !switchInTheYearState,
                )}
              </div>

              <div className="car-info__content">
                <div className="car-info__content-item car-info__content-item--wide car-maintenance-list list box">
                  <ConsumableList onUpdate={fetchCarsConsumCost}></ConsumableList>
                </div>
              </div>
            </div>
          )}
        </main>
      </Layout>

      <Modal isShowing={modalCarListIsShowing} onClose={() => setModalCarListIsShowing(false)}>
        <CarList onClose={() => setModalCarListIsShowing(false)} />
      </Modal>

      <QuestionDialogModal
        isShowing={isShowing}
        text={text}
        yesButtonText={yesButtonText}
        noButtonText={noButtonText}
        onClickYes={onClickYes}
        onClickNo={onClickNo}
      />

      <MessageDialogModal
        isShowing={messageDialogModalIsShowing}
        onClose={() => setMessageDialogModalIsShowing(false)}
        text={messageDialogModalText}
        showIcon={false}
      />
    </>
  );
};
