import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface UseAccessTokenType {
  accessToken: string | null;
  setAccessToken: (token: string | null) => void;
}

interface UseAccessTokenOptions {
  required?: boolean;
}

export const useAccessToken = (
  options: UseAccessTokenOptions = { required: true },
): UseAccessTokenType => {
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState<string | null>(
    localStorage.getItem('accessToken'),
  );

  useEffect(() => {
    if (options.required && accessToken === null) {
      navigate('/login');
    }
  }, [accessToken, options.required]);

  useEffect(() => {
    if (accessToken !== null) {
      localStorage.setItem('accessToken', accessToken);
    } else {
      localStorage.removeItem('accessToken');
    }
  }, [accessToken]);

  return { accessToken, setAccessToken };
};
