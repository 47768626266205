import React from 'react';
import { Layout } from '../components/Layout/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import { UserResetPasswordGetHashForm } from '../components/forms/UserResetPasswordGetHashForm';
import { UserResetPasswordForm } from '../components/forms/UserResetPasswordForm';

export const UserResetPasswordPage = () => {
  const navigate = useNavigate();

  const { hash: passwordResetHash } = useParams();

  return (
    <Layout>
      <main className="main main--gaps-static">
        <div className="main__user-auth-switch button-switch">
          <button className="button button-switch__item button-switch__item--active">
            Восстановление пароля
          </button>
        </div>

        <div className="main__user-registration user-registration box box--padded">
          {!passwordResetHash ? (
            <UserResetPasswordGetHashForm />
          ) : (
            <UserResetPasswordForm passwordResetHash={passwordResetHash} />
          )}
        </div>
      </main>
    </Layout>
  );
};
