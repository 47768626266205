import React, { useState } from 'react';
import { Layout } from '../components/Layout/Layout';
import { useNavigate } from 'react-router-dom';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { LoginFormSchema } from '../components/forms/schemas/LoginFormSchema';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useAccessToken } from '../hooks/useAccessToken';
import { authControllerLogin } from '../clients/VgarageApi/sdk.gen';

import '../styles/main.scss';
import '../styles/box.scss';
import '../styles/form.scss';
import '../styles/icon.scss';
import '../styles/button.scss';
import '../styles/checkbox.scss';
import { useUserId } from '../hooks/useUserId';
import { parseJwt } from '../utils/parseJwt';

export const LoginPage = () => {
  const navigate = useNavigate();

  const { setAccessToken } = useAccessToken({ required: false });
  const { setUserId } = useUserId({ required: false });

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const [formHintMessage, setFormHintMessage] = useState('');
  const [formHintClass, setFormHintClass] = useState('');

  const formResolver = classValidatorResolver(LoginFormSchema);
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<LoginFormSchema>({ resolver: formResolver });

  const onValid: SubmitHandler<LoginFormSchema> = async (data) => {
    console.log(data);

    const loginResult = await authControllerLogin({
      baseUrl: process.env.REACT_APP_WEBSITE_API_URL,
      body: {
        email: data.email,
        password: data.password,
      },
    });

    if (loginResult.response.status === 201) {
      const accessToken = loginResult.data?.accessToken;

      setAccessToken(accessToken!);

      setFormHintClass('form__hint--green');
      setFormHintMessage('Успешный вход');

      const userId = parseJwt(accessToken!).sub;

      setUserId(userId);

      setTimeout(() => {
        navigate('/');
      }, 1500);
    } else {
      setFormHintClass('form__hint--red');
      setFormHintMessage('Неверный логин или пароль');
    }
  };

  const onInvalid: SubmitErrorHandler<LoginFormSchema> = (error) => {
    console.log(error);

    setFormHintClass('form__hint--red');
  };

  return (
    <Layout>
      <main className="main main--gaps-static">
        <div className="main__user-auth-switch button-switch">
          <button className="button button-switch__item  button-switch__item--active">Вход</button>
          <button className="button button-switch__item" onClick={() => navigate('/registration')}>
            Регистрация
          </button>
        </div>

        <div className="main__user-login user-login box">
          <form className="form" onSubmit={handleSubmit(onValid, onInvalid)}>
            <div className="form__item form__item--wide">
              <div className="form__item-label form__item-label--wide">
                <label htmlFor="car-name">Электронная почта:</label>
              </div>
              <div className="form__item-element form__item-element--wide">
                <input {...register('email')} />
              </div>
            </div>

            <div className="form__item form__item--wide">
              <div className="form__item-label form__item-label--wide">
                <label htmlFor="car-name">Пароль:</label>
              </div>
              <div className="form__item-element form__item-element--wide">
                <input type={passwordShown ? 'text' : 'password'} {...register('password')} />
                <button
                  type="button"
                  className="form__item-button"
                  onClick={togglePasswordVisiblity}
                >
                  <svg
                    className="form__item-button-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    width="512"
                    height="512"
                    viewBox="0 0 512 512"
                  >
                    <g id="XMLID_625_">
                      <g id="XMLID_567_">
                        <path
                          id="XMLID_105_"
                          d="M256,406c-30.6,0-62.2-7.6-94-22.5c-24.9-11.7-50-27.9-74.6-48.2C45.9,301,20.3,267,19.2,265.6
                                c-4.3-5.7-4.3-13.5,0-19.2c1.1-1.4,26.7-35.4,68.2-69.7c24.6-20.3,49.7-36.5,74.6-48.2c31.8-14.9,63.4-22.5,94-22.5
                                s62.2,7.6,94,22.5c24.9,11.7,50,27.9,74.6,48.2c41.5,34.3,67.2,68.3,68.2,69.7c4.3,5.7,4.3,13.5,0,19.2
                                c-1.1,1.4-26.7,35.4-68.2,69.7c-24.6,20.3-49.7,36.5-74.6,48.2C318.2,398.4,286.6,406,256,406z M52.6,256
                                c25.1,29.7,108,118,203.4,118c95.6,0,178.3-88.3,203.4-118c-25.1-29.7-108-118-203.4-118C160.4,138,77.7,226.3,52.6,256z"
                        ></path>
                      </g>
                      <g id="XMLID_568_">
                        <path
                          id="XMLID_102_"
                          d="M256,328c-39.7,0-72-32.3-72-72s32.3-72,72-72s72,32.3,72,72S295.7,328,256,328z M256,216
                                c-22.1,0-40,17.9-40,40s17.9,40,40,40s40-17.9,40-40S278.1,216,256,216z"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
            </div>

            <div className="form__item form__item--wide">
              <div className="form__item-label form__item-label--wide"></div>
              <div className="form__item-element form__item-element--wide">
                <button type="submit" className="form__button button button--fit button--primary">
                  Войти
                </button>
              </div>
            </div>

            <div className="form__buttons">
              <button
                onClick={() => navigate('/user-reset-password')}
                type="button"
                className="button button--light-red"
              >
                Забыли пароль?
              </button>
            </div>

            <div className={`form__hint ${formHintClass}`}>
              {formHintMessage}
              {Object.entries(formErrors).map(([field, { message }]) => (
                <p key={field}>{message}</p>
              ))}
            </div>
          </form>
        </div>
      </main>
    </Layout>
  );
};
